import {
  Button,
  Checkbox,
  DraggableModal,
  Form,
  FormItem,
  FormLayout,
  Input,
  Popconfirm,
  Select,
  Space,
  Tabs,
  Typography,
} from '@ui';
import { useConnectionListQuery, useDeleteSourceMutation, useSaveSourceMutation } from '@modules/source/duck/sourceApi';
import { Source } from '@modules/source/SourceTypes';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import { IFormValue } from 'react-auto-form';
import { SourceModalsSaveConnection } from './SourceModalsSaveConnection';

const SourceModalsSaveSourceContent = ({ data, onClose, isEdit, t }: SourceModalsSaveSourceContentProps) => {
  const [form] = Form.useForm();
  const connectionListQuery = useConnectionListQuery();
  const [deleteSource, deleteSourceQuery] = useDeleteSourceMutation();
  const [saveSource, saveSourceQuery] = useSaveSourceMutation();
  const innerRef = useRef<any>(null);
  const typeOptions = connectionListQuery?.data?.map((item) => ({ label: item.name, value: item.id }));

  const errorMessage = [deleteSourceQuery, saveSourceQuery]
    .map(
      (result) =>
        result.isError && result.error && ('data' in result.error ? result.error.data.userMsg : result.error?.message),
    )
    .filter(Boolean)
    .join(';');

  const onSubmit = async (values: SourceSaveFieldFormValues) => {
    try {
      if (getConnectionConfig(values['connection_id'])) {
        await innerRef.current.submitForm();
        if (innerRef.current.isValid) {
          const connection_properties = innerRef.current.values;
          await saveSource({
            ...values,
            connection_properties,
            id: data?.id,
          }).unwrap();
          onClose();
        }
      } else {
        await saveSource({
          ...values,
          connection_properties: {},
          id: data?.id,
        }).unwrap();
        onClose();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onDeleteSource = async () => {
    try {
      await deleteSource(data.id!).unwrap();
      onClose();
    } catch (e) {
      console.error(e);
    }
  };

  const getConnectionConfig = (connectionId: number) => {
    const selectedConnection = connectionListQuery?.data?.find(
      // eslint-disable-next-line eqeqeq
      (item) => item.id == connectionId,
    );

    if (!Object.keys(selectedConnection?.configuration || {}).length) {
      return null;
    }

    return selectedConnection?.configuration;
  };

  const initValues = (isEdit &&
    data && {
      name: data.name,
      connection_name: data.connection_name,
      connection_id: data.connection_id,
      connection_properties: data.connection_properties,
      active: data.active,
    }) || {
    connection_id: (connectionListQuery?.data || [])[0]?.id,
    active: true,
  };

  return (
    <FormLayout
      form={form}
      onCancel={onClose}
      onSubmit={onSubmit}
      okText={t('save')}
      initialValues={initValues}
      extraActions={
        isEdit && (
          <Space>
            <Popconfirm
              title={t('confirmation.title')}
              description={t('confirmation.description')}
              okText={t('yes')}
              cancelText={t('no')}
              onConfirm={onDeleteSource}
            >
              <Button danger loading={deleteSourceQuery.isLoading}>
                {t('delete')}
              </Button>
            </Popconfirm>
          </Space>
        )
      }
    >
      <FormItem name="name" label={t('saveForm.name')} rules={[{ required: true }]}>
        <Input />
      </FormItem>
      <FormItem name="connection_id" label={t('saveForm.connectionType')} rules={[{ required: true }]}>
        <Select options={typeOptions} placeholder={t('select')} loading={connectionListQuery.isLoading} />
      </FormItem>
      <FormItem dependencies={['connection_id']} wrapperCol={{ span: 24 }} noStyle>
        {() => {
          const selectedConnectionConfig = getConnectionConfig(form.getFieldValue('connection_id'));
          return (
            selectedConnectionConfig && (
              <FormItem name="connection_name" label={t('saveForm.connectionName')} rules={[{ required: true }]}>
                <Input />
              </FormItem>
            )
          );
        }}
      </FormItem>
      <FormItem name="active" label={t('saveForm.active')} valuePropName="checked">
        <Checkbox />
      </FormItem>
      <FormItem dependencies={['connection_id']} wrapperCol={{ span: 24 }} noStyle>
        {() => {
          const selectedConnectionConfig = getConnectionConfig(form.getFieldValue('connection_id'));

          return (
            selectedConnectionConfig && (
              <FormItem wrapperCol={{ span: 24 }}>
                <Tabs
                  defaultActiveKey="connection"
                  type="card"
                  size="small"
                  items={[
                    {
                      key: 'connection',
                      label: t('saveForm.connection'),
                      children: (
                        <SourceModalsSaveConnection
                          definition={selectedConnectionConfig}
                          initValues={form.getFieldValue('connection_properties')}
                          onSave={(values) => form.setFieldValue('connection_properties', values)}
                          innerRef={innerRef}
                        />
                      ),
                    },
                  ]}
                />
              </FormItem>
            )
          );
        }}
      </FormItem>
      {errorMessage && <Typography.Text type="danger">{errorMessage}</Typography.Text>}
    </FormLayout>
  );
};

export const SourceModalsSaveSource = ({ open, data, onClose }: SourceModalsSaveSourceProps) => {
  const { t } = useTranslation(['datasource']);
  const isEdit = !!(data as Source)?.id;

  return (
    <DraggableModal
      open={open}
      onCancel={onClose}
      title={isEdit ? t('saveForm.titleEdit') : t('saveForm.title')}
      footer={null}
      destroyOnClose
    >
      {open && <SourceModalsSaveSourceContent data={data} onClose={onClose} isEdit={isEdit} t={t} />}
    </DraggableModal>
  );
};

export interface SourceModalsSaveSourceProps {
  open: boolean;
  data: Partial<Source>;
  onClose: () => void;
}

interface SourceModalsSaveSourceContentProps extends Pick<SourceModalsSaveSourceProps, 'data' | 'onClose'> {
  t: TFunction;
  isEdit: boolean;
}

interface SourceSaveFieldFormValues {
  name: string;
  connection_name: string;
  connection_id: number;
  connection_properties: IFormValue;
  active: boolean;
}
