import { ActionButton, ContainerStage } from '@modules/modelEditor/components/builder/components';
import { ModelEditorStageActionType } from '@modules/modelEditor/ModelEditorTypes';
import { DEFAULT_STAGE_WIDTH } from '@modules/modelEditor/components/builder/constants';
import { selectModelEditorErrors } from '@modules/modelEditor/duck/modelEditorSelectors';
import { modelEditorActions } from '@modules/modelEditor/duck/modelEditorSlice';
import { modelActions } from '@modules/model/duck/modelSlice';
import { Badge, Space, Tooltip } from '@ui';
import { NodeProps } from 'reactflow';
import { EyeOutlined, SettingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { CSSObject } from '@emotion/react';
import { useTranslation } from 'react-i18next';

export const BaseStage = ({
  customStyle,
  showLabel = true,
  isDeletable = true,
  targetHandle = true,
  sourceHandle = true,
  disableDataViewer,
  ...props
}: BaseStageProps) => {
  const { t } = useTranslation('model');
  const dispatch = useDispatch();
  const { id, data, isConnectable, selected, actions = {} } = props;
  const { icon, background, name } = data;
  const { onSettings, customActions = [] } = actions;
  const errors = useSelector(selectModelEditorErrors)
    .filter((error) => error.node?.id === id)
    .map((error) => t(error.message));
  const uniqueErrors = Array.from(new Set(errors));
  const actionButtons = [];

  const onDelete = (nodeId: string) => dispatch(modelEditorActions.onDeleteNode(nodeId));

  const onViewData = () => dispatch(modelActions.setDataViewer({ tableKey: data.tableName, tableName: data.name }));

  if (!disableDataViewer) {
    actionButtons.push({ value: ModelEditorStageActionType.show, onClick: onViewData, icon: <EyeOutlined /> });
  }

  if (onSettings) {
    actionButtons.push({
      value: ModelEditorStageActionType.settings,
      onClick: onSettings,
      icon: <SettingOutlined />,
    });
  }

  return (
    <ContainerStage
      id={id}
      data={data}
      customStyle={[customStyle, cssError(errors.length > 0)]}
      isConnectable={isConnectable}
      selected={selected}
      background={background}
      actionButtons={[...actionButtons, ...customActions]}
      onDeleteStage={onDelete}
      isDeletable={isDeletable}
      targetHandle={targetHandle}
      sourceHandle={sourceHandle}
    >
      {errors.length > 0 && (
        <div css={cssBadge}>
          <Tooltip
            color="red"
            title={
              <ul>
                {uniqueErrors.map((error) => (
                  <li key={error}>{error}</li>
                ))}
              </ul>
            }
          >
            <Badge count={uniqueErrors.length} size="small" title="" />
          </Tooltip>
        </div>
      )}
      {icon && (
        <Space>
          <div>{icon}</div>
          {showLabel && <div>{name}</div>}
        </Space>
      )}
      {!icon && showLabel && (
        <div css={cssTitle} title={name}>
          {name}
        </div>
      )}
    </ContainerStage>
  );
};

const cssTitle = (): CSSObject => ({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  width: DEFAULT_STAGE_WIDTH - 20,
});

interface Actions {
  onView?: () => void;
  onSettings?: () => void;
  customActions?: ActionButton[];
}

interface BaseStageProps extends NodeProps {
  showLabel?: boolean;
  targetHandle?: boolean;
  sourceHandle?: boolean;
  isDeletable?: boolean;
  actions?: Actions;
  customStyle?: any;
  disableDataViewer?: boolean;
}

const cssError = (isError: boolean): CSSObject => ({
  border: isError ? '2px solid red' : '',
});

const cssBadge = (): CSSObject => ({
  position: 'absolute',
  top: 0,
  left: '100%',
  transform: 'translate(-50%, -50%)',
  cursor: 'help',
});
