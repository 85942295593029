import { useSnapshotListQuery } from '@modules/snapshot/duck/snapshotApi';
import { PageTemplateSimple, PropertiesView } from '@components';
import { Button, Space } from '@ui';
import { useLocationResolver } from '@routes/routesHooks';
import { StudyResolver } from '@routes/study/RoutesStudy';
import { useStoreAggregation } from '@shared/components/TableListSider/tableListSiderHooks';
import { dateToString } from '@shared/utils/Date';
import { butifyUsername } from '@shared/utils/common';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import { UndoOutlined } from '@ant-design/icons';
import { ISnapshot } from '../SnapshotTypes';
import { SnapshotTableList } from '../components/SnapshotTableList';

const defaultSnapshotTables: [] = [];

export const SnapshotViewPage = () => {
  const { t } = useTranslation(['snapshot']);
  const navigate = useNavigate();
  const {
    params: { snapshotId },
    state: preloadedSnapshot,
  } = useLocationResolver<StudyResolver['snapshots']['view']['params'], ISnapshot>();
  const snapshotListQuery = useSnapshotListQuery();
  const snapshot = snapshotListQuery.data?.find((item) => item.id === snapshotId) || preloadedSnapshot;
  const snapshotTables = snapshot?.tablesDetails || defaultSnapshotTables;
  const { treeData, externalTablesQuery, rccGroupsQuery, storeQuery } = useStoreAggregation();

  const onBack = () => navigate(-1);

  const data = useMemo(() => {
    if (snapshotTables.length) {
      const invertedSnapshotTables: Record<string, ISnapshot['tablesDetails'][0]> = snapshotTables.reduce(
        (acc, item) => ({ ...acc, [item.tableName]: item }),
        {},
      );

      const storeListResult: string[] = [];
      const result = treeData.reduce((acc, item) => {
        if (['external', 'rccGroups'].includes(item.groupType)) {
          item.children?.forEach((storeItem) => {
            const storeTableName = (storeItem as any).originalId.split('.')[1];
            if (invertedSnapshotTables[storeTableName]) {
              if (!storeListResult.includes(item.title as string)) {
                storeListResult.push(item.title as string);
              }
              acc.push({
                ...invertedSnapshotTables[storeTableName],
                store: item.title as string,
              });
            }
          });
        }
        return acc;
      }, [] as Array<ISnapshot['tablesDetails'][0] & { store: string }>);

      return {
        tables: result,
        storeList: storeListResult,
      };
    }
    return {
      tables: [],
      storeList: [],
    };
  }, [treeData, snapshotTables]);

  const detailsMap = [
    {
      key: t('createPage.name'),
      value: snapshot?.description,
    },
    {
      key: t('createPage.creationDate'),
      value: dateToString(snapshot?.createdAt),
    },
    {
      key: t('viewPage.createdBy'),
      value: butifyUsername(snapshot?.created_by),
    },
  ];

  const isLoading =
    externalTablesQuery.isFetching ||
    rccGroupsQuery.isFetching ||
    storeQuery.isFetching ||
    snapshotListQuery.isFetching;

  return (
    <PageTemplateSimple
      hideTitleSkeleton
      title={{
        children: t('viewPage.title'),
        extra: (
          <Space>
            <Button
              children={<UndoOutlined style={{ transform: 'rotate(0.25turn)' }} />}
              onClick={snapshotListQuery.refetch}
            />
            <Button children={t('back')} onClick={onBack} />
          </Space>
        ),
      }}
      content={{ isLoading: isLoading && !data.tables.length }}
    >
      {!!data.tables.length && (
        <>
          <PropertiesView data={detailsMap} />
          <SnapshotTableList data={data.tables} loading={snapshotListQuery.isFetching} storeList={data.storeList} />
        </>
      )}
    </PageTemplateSimple>
  );
};
