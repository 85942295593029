import { I18nLocales } from '@i18n';
import en from './shared_en.json';

export const sharedLocales: Record<I18nLocales, I18nSharedTranslations> = {
  en,
};

export interface I18nSharedTranslations {
  add: string;
  delete: string;
  copy: string;
  save: string;
  saveExit: string;
  cancel: string;
  close: string;
  download: string;
  refresh: string;
  export: string;
  filtered: string;
  select: string;
  yes: string;
  no: string;
  signOut: string;
  profile: string;
  table: {
    triggerAsc: string;
    cancelSort: string;
    filterReset: string;
    filterConfirm: string;
    emptyText: string;
    searchText: string;
    searchBy: string;
  };
  notification: {
    resourceUnavailable: string;
    unknownEvent: string;
    archive: string;
    details: string;
    notificationsNotFound: string;
    archivedNotFound: string;
    tabs: {
      notifications: string;
      archive: string;
    };
    footer: {
      selectAll: string;
      markRead: string;
      markUnread: string;
      archive: string;
    };
    model: {
      finished: string;
      unknownAction: string;
    };
    store: {
      fileProcessed: string;
      fileFailed: string;
      tableDeleted: string;
      unknownAction: string;
    };
  };
  fieldRequired: string;
}
