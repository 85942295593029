import { IBaseColumnProps } from '@shared/components/ObjectTable';

export enum SNAPSHOT_STATUS {
  'COMPLETED' = 'COMPLETED',
  'FAILED' = 'FAILED',
  'IN_PROGRESS' = 'IN_PROGRESS',
  'NEW' = 'NEW',
}

export interface ISnapshot {
  id: string;
  studyId: number;
  created_by: string;
  protocolId: string;
  description: string;
  createdAt: string;
  tablesDetails: Array<{
    tableName: string;
    status: SNAPSHOT_STATUS;
    lastUpdateDate: string;
    errorMessage?: string;
  }>;
}

export interface ISnapshotTable extends IBaseColumnProps {
  category: string;
}

export interface ISnapshotSaveQuery {
  description: string;
  tables?: string[];
}
