import { selectGlobalStudy } from '@app/duck/appSelectors';
import { useTablePaginationState } from '@components/ui/table/tableHooks';
import { PageTemplateSimple } from '@components';
import { initialPage } from '@shared/utils/common';
import { IAnalysisPackageDeployment } from '@modules/library/analysisPackage/AnalysisPackageTypes';
import { useDeploymentListQuery } from '@modules/library/analysisPackage/duck/analysisPackageApi';
import { useSupportedEnvsQuery } from '@app/duck/appApi';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  AnalysisPackageDeploymentList,
  IAnalysisPackageDeploymentListProps,
} from '../components/AnalysisPackageDeploymentList';
import { analysisPackageActions } from '../duck/analysisPackageSlice';
import { AnalysisPackageModalsController, EAnalysisPackageType } from '../modals';

export const AnalysisPackageDeployments = () => {
  const { t } = useTranslation(['analysisPackage']);
  const dispatch = useDispatch();
  const { paginationState, getPagination, onTableChange, preparedFilters, preparedSorter } =
    useTablePaginationState<IAnalysisPackageDeployment>(initialPage);
  const globalStudy = useSelector(selectGlobalStudy);
  const supportedEnvsQuery = useSupportedEnvsQuery();

  const deploymentQuery = useDeploymentListQuery({
    page: paginationState.current - 1,
    ...preparedFilters,
    ...preparedSorter,
  });

  const pagination = getPagination(deploymentQuery.data?.totalItems);

  const onOpenLog: IAnalysisPackageDeploymentListProps['onOpenLog'] = (data) => {
    dispatch(analysisPackageActions.pushModal({ type: EAnalysisPackageType.deploymentLog, data }));
  };

  const onOpenPackage: IAnalysisPackageDeploymentListProps['onOpenPackage'] = (data) =>
    dispatch(analysisPackageActions.pushModal({ type: EAnalysisPackageType.saveAnalysisPackage, data }));

  return (
    <PageTemplateSimple
      title={{
        children: t('deployments.title'),
      }}
      content={{
        isLoading: deploymentQuery.isLoading && !deploymentQuery.data,
        errorText: t('deployments.loadingError'),
        error: deploymentQuery.error,
      }}
    >
      {!deploymentQuery.isLoading && (
        <AnalysisPackageDeploymentList
          supportedEnvs={supportedEnvsQuery.data || {}}
          data={deploymentQuery.data}
          studyId={globalStudy?.id!}
          onChange={onTableChange}
          onOpenLog={onOpenLog}
          onOpenPackage={onOpenPackage}
          pagination={pagination}
          loading={deploymentQuery.isFetching}
          t={t}
        />
      )}
      <AnalysisPackageModalsController />
    </PageTemplateSimple>
  );
};
