import { ViewerGroupType } from '@modules/viewer/duck/viewerSlice';
import { useCallback } from 'react';
import { ExtendedNodeData, useCrossStoreAggregation, useTableListSider } from './tableListSiderHooks';
import { ITableListSiderProps } from './TableListSiderTypes';
import { TableListSiderTemplate, TableListSiderTreeItem } from './TableListSiderTemplate';

export const CrossTableListSider = ({
  selectedTable,
  defaultTableName,
  onSelectTable,
  refetch,
  draggable,
}: ITableListSiderProps) => {
  const { treeData, globalStudy, storeQuery, crossRTListQuery } = useCrossStoreAggregation(draggable);
  const { expandedGroups, selectedKeys, onSelect, onExpand, onDragStart } = useTableListSider({
    globalStudy,
    treeData,
    defaultTableName,
    selectedTable,
    onSelectTable,
  });

  const customTitle = useCallback(
    (node: ExtendedNodeData) => {
      const loadingMap: Partial<Record<ViewerGroupType, boolean>> = {
        crossStudyRT: crossRTListQuery.isLoading || storeQuery.isLoading,
      };

      return (
        <TableListSiderTreeItem
          loadingMap={loadingMap}
          node={node}
          refetch={refetch}
          onSelectTable={onSelectTable}
          selectedTable={selectedTable}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [storeQuery.isLoading, crossRTListQuery.isLoading, selectedTable, onSelectTable],
  );

  return (
    <TableListSiderTemplate
      treeData={treeData}
      selectedKeys={selectedKeys}
      expandedKeys={expandedGroups}
      draggable={draggable}
      defaultTableName={defaultTableName}
      selectedTable={selectedTable}
      titleRender={customTitle}
      onExpand={onExpand}
      onDragStart={onDragStart}
      onSelect={onSelect}
      refetch={refetch}
    />
  );
};
