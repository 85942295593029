import { DraggableModal, Form, FormItem, FormLayout, Select } from '@ui';
import { ModelEditorNodeResult } from '@modules/modelEditor/ModelEditorTypes';
import { useSourceTableInfoAnalyzer } from '@modules/modelEditor/duck/modelEditorSourceTableInfoAnalyzer';
import { listToOptions } from '@shared/utils/Form';
import { checkSpecialCharacters } from '@modules/modelEditor/components/builder/Utils';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Row, Col, Alert, Space } from 'antd';
import { useSaveStage } from './modelEditorModalsHooks';

const ModelEditorModalsResultSettingsContent = ({ data, onClose, t }: ModelEditorModalsResultSettingsContentProps) => {
  const [form] = Form.useForm();
  const { onSubmit } = useSaveStage(data.nodeId, onClose);
  const { loading, sourceColumns } = useSourceTableInfoAnalyzer(data.nodeId);

  const columns = listToOptions(sourceColumns[0]?.map((item) => item.name) || []);

  const nodeErrors = [];

  if (!loading) {
    if (checkSpecialCharacters(columns.map(({ value }) => value))) {
      nodeErrors.push(t('result.errors.specialCharacters'));
    }
  }

  const initValues = data.initData || {};

  return (
    <FormLayout
      form={form}
      layout="vertical"
      onCancel={onClose}
      onSubmit={onSubmit}
      okText={t('save')}
      initialValues={initValues}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      submitIsDisabled={!!nodeErrors.length || loading}
    >
      <Row gutter={8}>
        <Col span={12}>
          <FormItem
            name="primaryKeys"
            label={t('result.prKeys')}
            rules={[
              {
                validator: (rule, value: string[] = [], callback) => {
                  if (!loading && value.filter((v) => !columns.some((item) => item.value === v)).length) {
                    return Promise.reject(new Error(t('result.errors.wrongColumns')));
                  }
                  if (!value.length) {
                    return Promise.reject(new Error(t('result.errors.missingPrKeys')));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Select mode="multiple" placeholder={t('result.prKeys')} options={columns} loading={loading} />
          </FormItem>
        </Col>
      </Row>
      {!!nodeErrors.length && (
        <FormItem wrapperCol={{ span: 24 }}>
          <Alert message={<Space direction="vertical" children={nodeErrors} />} type="error" />
        </FormItem>
      )}
    </FormLayout>
  );
};

export const ModelEditorModalsResultSettings = ({ open, data, onClose }: ModelEditorModalsResultSettingsProps) => {
  const { t } = useTranslation(['model']);

  return (
    <DraggableModal open={open} onCancel={onClose} title={t('result.title')} footer={null} destroyOnClose>
      {open && <ModelEditorModalsResultSettingsContent data={data} onClose={onClose} t={t} />}
    </DraggableModal>
  );
};

interface ModelEditorModalsResultSettingsContentProps
  extends Pick<ModelEditorModalsResultSettingsProps, 'data' | 'onClose'> {
  t: TFunction;
}

export interface ModelEditorModalsResultSettingsProps {
  open: boolean;
  data: { nodeId: string; initData?: ModelEditorNodeResult };
  onClose: () => void;
}
