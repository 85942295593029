import { appApi } from '@config/appApi';
import { TagDescription } from '@reduxjs/toolkit/query/react';
import { AUDIT_TAG_DESCRIPTION } from './auditConstants';
import { AuditLogDiffResponse, AuditLogListQueryParams, AuditLogListResponse } from '../AuditTypes';

export const AuditApiRoutes = {
  logList: '/api/audit/logs',
  logDiff: (id: number) => `/api/audit/${id}/diff`,
};

export const AuditInvalidations: {
  LIST: TagDescription<AUDIT_TAG_DESCRIPTION.LIST>;
  DIFF: (id: number) => TagDescription<AUDIT_TAG_DESCRIPTION.DIFF>;
} = {
  LIST: { type: AUDIT_TAG_DESCRIPTION.LIST, id: 'LIST' },
  DIFF: (id: number) => ({ type: AUDIT_TAG_DESCRIPTION.DIFF, id }),
};

export const AuditApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    auditLogList: builder.query<AuditLogListResponse, AuditLogListQueryParams | void>({
      keepUnusedDataFor: 0,
      providesTags: [AuditInvalidations.LIST],
      query: (params) => ({
        params,
        url: AuditApiRoutes.logList,
      }),
    }),
    auditLogDiff: builder.query<AuditLogDiffResponse, number>({
      providesTags: (request, error, logId) => [AuditInvalidations.DIFF(logId)],
      query: (logId) => ({
        url: AuditApiRoutes.logDiff(logId),
      }),
    }),
  }),
});

export const { useAuditLogListQuery, useAuditLogDiffQuery } = AuditApi;
