import { Store } from '@modules/stores/StoreTypes';

export const SYSTEM_STORE_NAMES = {
  REFERENCE_TABLE: 'Reference Table',
};

export enum STORE_TAG_DESCRIPTION {
  'ID' = 'STORE_ID',
  'LIST' = 'STORE_LIST',
  'STUDY_USER_LIST' = 'SOURCE_STUDY_USER_LIST',
  'SEARCH_USERS' = 'SEARCH_USERS',
}

export enum EXTERNAL_STORES {
  'SRDM' = 'SRDM',
  'RDEs' = 'RDEs',
  'Operational' = 'Operational',
  'Metadata' = 'Metadata',
}

export const DEFAULT_STORES_CONNECTION = {
  value: -1,
  text: 'ClickHouse',
};

export const DEFAULT_EXTERNAL_STORES: Record<EXTERNAL_STORES, Pick<Store, 'id' | 'name' | 'tables'>> = {
  [EXTERNAL_STORES.Operational]: {
    id: -1,
    name: EXTERNAL_STORES.Operational,
    tables: [],
  },
  [EXTERNAL_STORES.Metadata]: {
    id: -2,
    name: EXTERNAL_STORES.Metadata,
    tables: [],
  },
  [EXTERNAL_STORES.SRDM]: {
    id: -3,
    name: EXTERNAL_STORES.SRDM,
    tables: [],
  },
  [EXTERNAL_STORES.RDEs]: {
    id: -4,
    name: EXTERNAL_STORES.RDEs,
    tables: [],
  },
};

export const CREATED_BY_SYSTEM = 'System';

export const DEFAULT_STORES: Array<Store> = [
  {
    ...DEFAULT_EXTERNAL_STORES.Operational,
    description: `${EXTERNAL_STORES.Operational} tables`,
    data_source_id: DEFAULT_STORES_CONNECTION.value,
    data_source_name: DEFAULT_STORES_CONNECTION.text,
    tables: [],
    created_at: 1694688734,
    created_by: CREATED_BY_SYSTEM,
    active: true,
    system: true,
  },
  {
    ...DEFAULT_EXTERNAL_STORES.Metadata,
    description: `${EXTERNAL_STORES.Metadata} tables`,
    data_source_id: DEFAULT_STORES_CONNECTION.value,
    data_source_name: DEFAULT_STORES_CONNECTION.text,
    tables: [],
    created_at: 1694688734,
    created_by: CREATED_BY_SYSTEM,
    active: true,
    system: true,
  },
  {
    ...DEFAULT_EXTERNAL_STORES.SRDM,
    description: `${EXTERNAL_STORES.SRDM} tables`,
    data_source_id: DEFAULT_STORES_CONNECTION.value,
    data_source_name: DEFAULT_STORES_CONNECTION.text,
    tables: [],
    created_at: 1694688734,
    created_by: CREATED_BY_SYSTEM,
    active: true,
    system: true,
  },
  {
    ...DEFAULT_EXTERNAL_STORES.RDEs,
    description: `${EXTERNAL_STORES.RDEs} views`,
    data_source_id: DEFAULT_STORES_CONNECTION.value,
    data_source_name: DEFAULT_STORES_CONNECTION.text,
    tables: [],
    created_at: 1694688734,
    created_by: CREATED_BY_SYSTEM,
    active: true,
    system: true,
  },
];
