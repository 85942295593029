import { Alert, Form, FormLayout, Tabs } from '@components/ui';
import { DiscoverTab } from '@modules/job/modals/components/upload/steps/components/DiscoverTab';
import { DiscoveryJobResponse } from '@modules/job/duck/jobApi';
import { MappingProps } from '@modules/job/JobTypes';
import { SKIP_COLUMN } from '@modules/job/duck/constants';
import { getStudyPathName } from '@routes/utils';
import { DiscoverTabManual } from '@modules/job/modals/components/upload/steps/components/DiscoverTabManual';
import { ProcessParamsProps, Progress, TRequest } from '@modules/job/modals/components';
import routes from '@routes';
import { Loader } from '@components';
import { TFunction } from 'i18next';
import { CheckCircleFilled, ExclamationCircleOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';

const initialMapping = {
  referenceTable: undefined,
  total_columns: 0,
  total_rows: 0,
  mapping: [],
  confirmed: false,
  isError: false,
};

export const ConfigureStep = ({
  onClose,
  onCancel,
  t,
  setCurrent,
  tabs,
  processJob,
  setProgress,
  processParams,
  referenceTables,
  refTableInfo,
  referenceTablesLoading,
  referenceTablesInfoLoading,
  increaseProgress,
  studyId,
  isLoading,
  registerRequest,
  isManualUpload,
}: ConfigureStepProps) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [mappingData, setMappingData] = useState<MappingProps[]>([]);

  useEffect(() => {
    setMappingData(
      tabs.map((el) => ({
        ...initialMapping,
        tableName: el.name,
        total_rows: el.total_rows,
        total_columns: el.total_columns,
        mapping: isManualUpload
          ? Object.keys(el.structure).map((key) => ({
              sourceColumn: key,
              targetColumn: key,
              type: el.structure[key],
              nullable: true,
              primaryKey: false,
            }))
          : initialMapping.mapping,
      })),
    );
  }, [tabs, isManualUpload]);

  useEffect(() => {
    setErrorMessage('');
  }, [mappingData]);

  const isValid = () => {
    const isNoMapping = mappingData.some((el) => !el.mapping.length);
    if (isNoMapping) {
      setErrorMessage(t('uploadRT.errors.emptyMapping'));
      return false;
    }

    const isAllTabConfirmed = mappingData.every((el) => el.confirmed);
    if (!isAllTabConfirmed) {
      setErrorMessage(t('uploadRT.errors.confirmMapping'));
      return false;
    }

    return true;
  };

  const onSubmitProcess = async () => {
    if (!isValid()) {
      return;
    }
    setCurrent(3);
    try {
      const updMappingData = mappingData.map((el) => ({
        ...el,
        mapping: el.mapping.map((col) =>
          col.sourceColumn === SKIP_COLUMN ? { ...col, sourceColumn: undefined } : col,
        ),
      }));

      const data = {
        ...processParams,
        data: updMappingData,
        callback: increaseProgress,
      };
      setProgress({ progress: 0, step: 1 });
      const processRequest = processJob(data);
      registerRequest(processRequest);
      const jobId = await processRequest.unwrap();

      if (jobId !== undefined) {
        navigate(
          routes[getStudyPathName(studyId)].jobs.view.resolver({
            jobId,
            studyId: studyId,
          }),
        );
      }

      setProgress(null);
      onClose();
    } catch (e: any) {
      if (e?.name === 'AbortError') {
        console.warn('Request aborted');
      } else {
        console.error(e);
      }
    }
  };

  const updateMappingData = (tab: string, val: Record<string, any>) => {
    setMappingData((prev) => prev.map((el) => (el.tableName === tab ? { ...el, ...val } : el)));
  };

  const renderStatusIcon = (tabName: string) => {
    const isTabConfirmed = mappingData.find((el) => el.tableName === tabName)?.confirmed;
    const tabWithError = mappingData.find((el) => el.tableName === tabName)?.isError;

    if (tabWithError) {
      return <ExclamationCircleOutlined style={{ color: 'red', marginRight: 0 }} />;
    }

    if (isTabConfirmed) {
      return <CheckCircleFilled style={{ color: 'green', marginRight: 0 }} />;
    }

    return '';
  };

  const handleTabConfirm = (tabName: string) => {
    const isPKNullable = mappingData
      .find((el) => el.tableName === tabName)
      ?.mapping.some((el) => el.nullable && el.primaryKey);

    if (isPKNullable) {
      setErrorMessage(t('uploadRT.errors.isNullablePrimaryKey'));
    } else {
      updateMappingData(tabName, { confirmed: true, isError: false });
    }
  };

  if (isLoading) {
    return <Loader mode="absolute" size={'large'} />;
  }

  const initValues = tabs.reduce(
    (acc, val) => ({
      ...acc,
      [val.name]: val,
    }),
    {},
  );

  return (
    <FormLayout
      customActionStyle={{ position: 'absolute', left: '-32px', bottom: '28px' }}
      form={form}
      initialValues={isManualUpload ? initValues : {}}
      onCancel={onCancel}
      onSubmit={onSubmitProcess}
      okText={t('Submit')}
      onFinishFailed={({ values }) => {
        if (!isEmpty(values)) {
          const errorFields = Object.keys(values).filter((el) => !values[el]);
          const tabs = [
            ...new Set(errorFields.map((el) => el.split('.').length > 1 && el.split('.')[0]).filter((el) => el)),
          ];

          if (tabs) {
            const updMapping = mappingData.map((el) => (tabs.includes(el.tableName) ? { ...el, isError: true } : el));
            setMappingData(updMapping);
          }
        }
      }}
    >
      <Row>
        <Col span={24}>
          <Tabs
            onChange={() => setErrorMessage('')}
            defaultActiveKey={tabs[0].name}
            type="card"
            size="small"
            items={tabs.map((tab) => ({
              key: tab.name,
              label: (
                <div>
                  {tab.name} {renderStatusIcon(tab.name)}
                </div>
              ),
              children: isManualUpload ? (
                <DiscoverTabManual
                  key={tab.name}
                  t={t}
                  tab={tab}
                  mappingData={mappingData}
                  updateMappingData={updateMappingData}
                  handleTabConfirm={handleTabConfirm}
                />
              ) : (
                <DiscoverTab
                  key={tab.name}
                  form={form}
                  t={t}
                  tab={tab}
                  mappingData={mappingData}
                  referenceTables={referenceTables}
                  refTableInfo={refTableInfo}
                  referenceTablesLoading={referenceTablesLoading}
                  referenceTablesInfoLoading={referenceTablesInfoLoading}
                  updateMappingData={updateMappingData}
                  handleTabConfirm={handleTabConfirm}
                />
              ),
            }))}
          />
        </Col>
      </Row>
      {errorMessage && <Alert style={{ width: '70%' }} message={errorMessage} type="error" showIcon />}
    </FormLayout>
  );
};

interface ConfigureStepProps {
  t: TFunction;
  onCancel: () => void;
  onClose: () => void;
  tabs: DiscoveryJobResponse[];
  setCurrent: (val: number) => void;
  setProgress: (val: Progress) => void;
  processParams: ProcessParamsProps;
  referenceTables: string[];
  processJob: any;
  refTableInfo: any;
  referenceTablesLoading: boolean;
  referenceTablesInfoLoading: boolean;
  increaseProgress: (val: number) => void;
  studyId: number;
  isLoading?: boolean;
  registerRequest: (request: TRequest) => void;
  isManualUpload?: boolean;
}
