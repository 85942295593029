import { DraggableModal, Steps } from '@ui';
import { SelectStep, ConfigureStep, UploadingStep } from '@modules/job/modals/components/upload/steps';
import { Job } from '@modules/job/JobTypes';
import { selectGlobalStudy } from '@app/duck/appSelectors';
import { useUploadFileMutation, useProcessJobMutation, useDiscoveryJobMutation } from '@modules/job/duck/jobApi';
import {
  useCrossStudyRTListQuery,
  useLazyReferenceTableInfoQuery,
  useStudyRTListQuery,
} from '@modules/viewer/duck/viewerApi';
import { isCrossStudy } from '@shared/utils/common';
import { INITIAL_PROGRESS } from '@modules/job/duck/constants';
import { selectStudyFallbackCHDB } from '@modules/study/duck/studySelectors';
import { applySchemaToTable } from '@shared/utils/Viewer';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { UploadFile } from 'antd/lib/upload';
import { CSSObject, Theme } from '@emotion/react';
import { useSelector } from 'react-redux';
import { StepProps } from 'antd';

const JobModalsUploadJobContent = ({ data, onClose, onCancel, cancelled, t }: JobModalsUploadJobContentProps) => {
  const fallbackCHDB = useSelector(selectStudyFallbackCHDB);
  const globalStudy = useSelector(selectGlobalStudy);
  const referenceTables = useCrossStudyRTListQuery();
  const referenceTablesStudy = useStudyRTListQuery();
  const [refTableInfo, refTableInfoResult] = useLazyReferenceTableInfoQuery();

  const [uploadFile, uploadFileResult] = useUploadFileMutation();
  const [discoveryJob, discoveryJobResult] = useDiscoveryJobMutation();
  const [processJob, processJobResult] = useProcessJobMutation();

  const [current, setCurrent] = useState(0);
  const [progressUpload, setProgressUpload] = useState<Progress>(INITIAL_PROGRESS);
  const [progress, setProgress] = useState<Progress>(INITIAL_PROGRESS);
  const [isSystemStore, setIsSystemStore] = useState(false);
  const [requests, setRequests] = useState<TRequest[]>([]);

  const [processParams, setProcessParams] = useState<ProcessParamsProps>({
    filename: '',
    store_id: 0,
    ignore_errors: false,
  });

  useEffect(() => {
    if (cancelled) {
      requests.forEach((request, index) => request.abort());
      onClose();
    }
  }, [cancelled, requests, onClose]);

  const registerRequest = (request: TRequest) => setRequests((prev) => [...prev, request]);

  const referenceTableData = useMemo(() => {
    if (isCrossStudy(globalStudy?.id!)) {
      return referenceTables?.data || [];
    }
    return referenceTablesStudy?.data?.map((table) => applySchemaToTable(table, fallbackCHDB)) || [];
  }, [fallbackCHDB, globalStudy?.id, referenceTables?.data, referenceTablesStudy?.data]);
  const referenceTableDataLoading = isCrossStudy(globalStudy?.id!)
    ? referenceTables?.isLoading
    : referenceTablesStudy?.isLoading;

  const isError =
    uploadFileResult.isError ||
    discoveryJobResult.isError ||
    processJobResult.isError ||
    referenceTables.isError ||
    refTableInfoResult.isError;

  const increaseProgress = (value: number = 0) => {
    const increment = Math.floor(Math.random() * 5 + 3);
    if (value) {
      setProgress((prev: any) => ({ ...prev, progress: value }));
    } else {
      setProgress(
        (prev: any) =>
          prev &&
          (prev.progress + increment >= 100
            ? { progress: 0, step: prev.step + 1 }
            : { ...prev, progress: prev.progress + increment }),
      );
    }
  };

  const steps = [
    {
      key: 'selectStep',
      title: t('uploadRT.selectStep'),
      content: (
        <SelectStep
          t={t}
          data={data}
          setCurrent={setCurrent}
          onClose={onClose}
          onCancel={onCancel}
          uploadFile={uploadFile}
          discoveryJob={discoveryJob}
          setProgress={setProgress}
          setProgressUpload={setProgressUpload}
          setProcessParams={setProcessParams}
          increaseProgress={increaseProgress}
          isSystemStore={isSystemStore}
          setIsSystemStore={setIsSystemStore}
          processJob={processJob}
          studyId={globalStudy?.id!}
          registerRequest={registerRequest}
        />
      ),
    },
    {
      key: 'uploadingStep',
      title: t('uploadRT.uploadingStep'),
      content: (
        <UploadingStep
          t={t}
          progress={progress}
          progressUpload={progressUpload}
          uploadFileResult={uploadFileResult}
          discoveryJobResult={discoveryJobResult}
          onCancel={onCancel}
        />
      ),
    },
    {
      key: 'сonfigureStep',
      title: t('uploadRT.configureStep'),
      content: (
        <ConfigureStep
          t={t}
          tabs={discoveryJobResult.data ?? []}
          onClose={onClose}
          onCancel={onCancel}
          setCurrent={setCurrent}
          processJob={processJob}
          setProgress={setProgress}
          processParams={processParams}
          increaseProgress={increaseProgress}
          studyId={globalStudy?.id!}
          registerRequest={registerRequest}
          isLoading={referenceTableDataLoading}
          referenceTables={referenceTableData ?? []}
          referenceTablesLoading={referenceTableDataLoading}
          refTableInfo={refTableInfo}
          referenceTablesInfoLoading={refTableInfoResult.isLoading}
          isManualUpload={!(isCrossStudy(globalStudy?.id!) || isSystemStore)}
        />
      ),
    },
    {
      key: 'processingStep',
      title: t('uploadRT.processingStep'),
      content: <UploadingStep t={t} progress={progress} processJobResult={processJobResult} onCancel={onCancel} />,
    },
  ].filter((item) => item) as CustomStepProps[];

  return (
    <div css={cssContentContainer}>
      <Steps
        style={{ flexDirection: 'row' }}
        current={current}
        items={steps}
        percent={progress?.progress || progressUpload?.progress}
        status={isError ? 'error' : undefined}
        direction="horizontal"
      />
      <div css={cssStepContent}>{steps[current].content}</div>
    </div>
  );
};

export const JobModalsUploadJobRT = ({ open, data, onClose }: JobModalsUploadJobProps) => {
  const { t } = useTranslation(['job']);
  const [cancelled, setCancelled] = useState(false);

  const handleCancel = () => setCancelled(true);

  const handleClose = () => {
    setCancelled(false);
    onClose();
  };

  return (
    <DraggableModal
      css={cssModal}
      width="60%"
      open={open}
      onCancel={handleCancel}
      title={t('uploadModal.title')}
      footer={null}
      destroyOnClose
      bodyStyle={{ flex: 1 }}
    >
      {open && (
        <JobModalsUploadJobContent
          data={data}
          onClose={handleClose}
          onCancel={handleCancel}
          cancelled={cancelled}
          t={t}
        />
      )}
    </DraggableModal>
  );
};

const cssModal = (): CSSObject => ({
  maxHeight: `calc(100vh - 40px)`,
  height: '600px',

  '& .ant-modal-content': {
    display: 'flex',
    flexDirection: 'column',
    height: 600,
    minWidth: '800px',
  },
  '& .ant-form': {
    height: '100%',
  },
});

const cssContentContainer = (): CSSObject => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const cssStepContent = (theme: Theme): CSSObject => ({
  borderRadius: theme.borderRadiusLG,
  border: `1px dashed ${theme.colorBorder}`,
  marginTop: 16,
  padding: 8,
  flex: 1,
});

export type TRequest = ReturnType<MutationTrigger<any>>;

interface JobModalsUploadJobProps {
  open: boolean;
  data: Partial<Job>;
  onClose: () => void;
}

export interface ProcessParamsProps {
  store_id: number;
  ignore_errors: boolean;
  filename: string;
}

export type Progress = Record<string, any> | null;
export interface JobUploadFormValues {
  skipBlankRows: boolean;
  skipHashOfRows: number;
  ignoreErrors: boolean;
  importToStaging: boolean;
  // timeFormat: 'basic' | 'best_effort';
  separator: string;
  store_id: number;
  file: UploadFile[];
}

export interface JobModalsUploadJobContentProps extends Pick<JobModalsUploadJobProps, 'data' | 'onClose'> {
  onCancel: () => void;
  cancelled: boolean;
  t: TFunction;
}

interface CustomStepProps extends StepProps {
  content: React.ReactNode;
}
