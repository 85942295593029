import { ESiderType } from '@modules/study/StudyTypes';
import { MenuType, studyInternalMenu } from '@routes/appMenu';
import { selectGlobalStudy } from '@app/duck/appSelectors';
import { useSelector } from 'react-redux';
import { StudySiderTemplate } from './StudySiderTemplate';

export const StudySider = ({ kind }: IStudySiderProps) => {
  const globalStudy = useSelector(selectGlobalStudy);
  let currentMenu: MenuType = [];

  if (kind === ESiderType.AnalysisObject) {
    const { studyAnalysisObjectsInternalMenu } = require('@routes/library/libraryMenu');
    currentMenu = studyAnalysisObjectsInternalMenu(globalStudy?.id!);
  } else if (kind === ESiderType.Study) {
    currentMenu = studyInternalMenu(globalStudy?.id!);
    if (process.env.APP_MODE === 'GL') {
      if (globalStudy) {
        const { appStudyAnalysisPackageMenu } = require('@routes/library/libraryMenu');
        currentMenu.push(...appStudyAnalysisPackageMenu(globalStudy?.id!));
      }
    }
  }

  return <StudySiderTemplate menu={currentMenu} />;
};
interface IStudySiderProps {
  kind: ESiderType;
}
