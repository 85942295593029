import { AxiosError } from 'axios';
import i18n from 'i18next';

enum RccServerErrorTypes {
  RpcEntityNotFoundException = 'RpcEntityNotFoundException',
}

export type QueryErrorType = {
  status?: number;
  data: {
    userMsg?: string;
    devMsg?: string;
    rawData?: any;
  };
};

export const isAbortError = (error?: any) => error?.name === 'AbortError';

const handleResponseError = ({ response }: any, message: any) => {
  if (response?.data) {
    let userMsg =
      response.data.message ||
      (typeof response.data === 'string' && response.data) ||
      message ||
      i18n.t('errors.smthWrong');
    let devMsg = response.data?.error;

    if (response?.data?.type === RccServerErrorTypes.RpcEntityNotFoundException) {
      userMsg = 'Resource not found';
    }
    return {
      status: response?.status,
      data: { userMsg, devMsg, rawData: response.data },
    };
  }
};

export const handleQueryError = (queryError: any, message?: any): QueryErrorType => {
  if (typeof queryError === 'string' || queryError instanceof Error) {
    if (queryError instanceof AxiosError) {
      const responseError = handleResponseError(queryError, message);

      if (responseError) {
        return responseError;
      }
    }
    return {
      data: {
        userMsg: queryError.toString(),
      },
    };
  }
  if (typeof queryError === 'object') {
    if (queryError.response !== undefined) {
      const responseError = handleResponseError(queryError, message);
      if (responseError) {
        return responseError;
      }
    }
    if (queryError.error !== undefined) {
      return {
        data: queryError.error,
      };
    }
  }
  return {
    data: {
      userMsg: message || 'Something went wrong...',
    },
  };
};
