import { ViewerGroupType } from '@modules/viewer/duck/viewerSlice';
import { Button, DirectoryTree, Space, Spin } from '@ui';
import { InternalSider } from '@app/components';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { LoadingOutlined, RedoOutlined } from '@ant-design/icons';
import { CSSObject } from '@emotion/react';
import { css, Theme } from '@emotion/react';
import Scrollbars from 'react-custom-scrollbars-2';
import { TreeProps } from 'antd';
import { ExtendedNodeData } from './tableListSiderHooks';

export const TableListSiderTreeItem = ({
  node,
  selectedTable,
  loadingMap,
  onSelectTable,
  refetch,
}: ITableListSiderTreeItemProps) => {
  const isSelected = selectedTable
    ? // eslint-disable-next-line eqeqeq
      (selectedTable as string).split(':').at(0) == node.key || node.key === selectedTable
    : false;

  if (node.isLeaf) {
    return (
      <Space block justify="space-between" css={isSelected ? cssTreeSelectedItem : undefined}>
        <div css={cssTreeLabel}>{node.title as string}</div>
        {isSelected && (
          <Button
            css={cssTreeRefetchBtn}
            icon={<RedoOutlined />}
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              onSelectTable(node.key as string, node.title as string);
              selectedTable && refetch();
            }}
          />
        )}
      </Space>
    );
  }
  return (
    <Space block justify="space-between" className={isSelected ? 'DataViewer-active-leaf' : ''}>
      <div css={cssTreeLabel}>{node.title as string}</div>
      {loadingMap[node.groupType] && <Spin css={cssSpinner} indicator={<LoadingOutlined />} size={'small'} />}
    </Space>
  );
};

export const TableListSiderTemplate = (props: ITableListSiderTemplateProps) => {
  return (
    <InternalSider>
      <Scrollbars autoHide={false}>
        <DirectoryTree
          css={cssTree}
          onSelect={props.onSelect}
          treeData={props.treeData}
          titleRender={props.titleRender}
          selectedKeys={props.selectedKeys}
          expandedKeys={props.expandedKeys}
          onExpand={props.onExpand}
          onDragStart={props.onDragStart}
          draggable={props.draggable}
        />
      </Scrollbars>
    </InternalSider>
  );
};

interface ITableListSiderTreeItemProps {
  node: ExtendedNodeData;
  loadingMap: Partial<Record<ViewerGroupType, boolean>>;
  onSelectTable: (key: string, title: string) => void;
  refetch: () => void;
  selectedTable: string | null | undefined;
}

export interface ITableListSiderTemplateProps {
  onSelect: (selectedKeys: React.Key[], info: any) => void;
  treeData: ExtendedNodeData[];
  titleRender: (node: ExtendedNodeData) => ReactJSXElement;
  selectedKeys: string[];
  expandedKeys: React.Key[];
  onExpand: TreeProps['onExpand'];
  onDragStart: TreeProps['onDragStart'];
  selectedTable?: string | null;
  defaultTableName?: string | null;
  draggable?: boolean;
  refetch: () => void | null;
}

const cssTreeRefetchBtn = css({
  display: 'none',
  background: 'transparent',
  border: 'none',
});

const cssTreeSelectedItem = css({
  [`&:hover .css-${cssTreeRefetchBtn.name}`]: {
    display: 'inline-block',
  },
});

const cssTree = (theme: Theme): CSSObject => ({
  '&&.ant-tree': {
    background: 'transparent',
    borderRadius: 0,
    '&& .ant-space-item:first-of-type': {
      overflow: 'hidden',
    },
    '&& .ant-tree-node-content-wrapper': {
      display: 'flex',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    '&& .ant-tree-title': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
    },
    '&& .ant-tree-draggable-icon': {
      display: 'none',
    },
    '&& .ant-tree-switcher': {
      display: 'none',
    },
  },
  '&&.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected': {
    color: 'initial',
  },
  '&&.ant-tree .ant-tree-treenode:has(.DataViewer-active-leaf)': {
    backgroundColor: theme['primary-4'],
  },
});

const cssTreeLabel = css({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
});

const cssSpinner = css({
  paddingLeft: '0.25rem',
});
