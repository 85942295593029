export enum VIEWER_TAG_DESCRIPTION {
  'DATA' = 'VIEWER_DATA',
  'TABLE_INFO' = 'VIEWER_TABLE_INFO',
  'TRACED_INFO' = 'TRACED_TABLE_INFO',
  'EXPORT' = 'VIEWER_EXPORT',
  'EXPORT_RESULT' = 'VIEWER_EXPORT_RESULT',
  'EXTERNAL_TABLES' = 'EXTERNAL_TABLES',
  'GROUPS' = 'GROUPS',
  'TABLES_EXIST' = 'TABLES_EXIST',
  'TABLES_IN_SQL_EXIST' = 'TABLES_IN_SQL_EXIST',
  'CROSS_STUDY_RT_LIST' = 'CROSS_STUDY_RT_LIST',
  'STUDY_RT_LIST' = 'STUDY_RT_LIST',
  'TABLE_INFO_RT' = 'TABLE_INFO_RT',
  'TABLES_LIST' = 'TABLES_LIST',
}
