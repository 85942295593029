import { useSourceListQuery } from '@modules/source/duck/sourceApi';
import { MANUAL_UPLOAD_SOURCE_ID } from '@modules/source/duck/sourceConstants';
import { useMemo } from 'react';
import { StoreListResponse, useStoreListQuery } from './storeApi';

export const useManualUploadStores = (
  defaultDataStoreId?: string | number,
  options?: { onlySystemStores?: boolean; onlyUserStores?: boolean },
) => {
  const dataStoreListQuery = useStoreListQuery();
  const dataSourceListQuery = useSourceListQuery();

  const manualUploadStores = useMemo(() => {
    return (
      dataStoreListQuery.data?.reduce((acc, store) => {
        if (store.data_source_id === defaultDataStoreId) {
          return [...acc, store];
        }
        if (
          store.active &&
          dataSourceListQuery?.data?.some(
            (source) =>
              store.data_source_id === source.id &&
              source.connection_id === MANUAL_UPLOAD_SOURCE_ID &&
              (options?.onlySystemStores ? store.system : options?.onlyUserStores ? !store.system : true),
          )
        ) {
          return [...acc, store];
        }
        return acc;
      }, [] as StoreListResponse['items']) || []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSourceListQuery?.data, dataStoreListQuery.data, defaultDataStoreId]);

  return {
    stores: dataStoreListQuery.data || [],
    sources: dataSourceListQuery.data || [],
    manualUploadStores,
    loading: dataStoreListQuery.isLoading || dataSourceListQuery.isLoading,
  };
};
