import { appAxios } from '@config/AppConfig';
import { RootState, store } from '@store';
import { isEqual } from 'lodash';
import { INotification, NotificationsApiRoutes } from './notificationsApi';
import { selectNotificationsArchived, selectNotificationsLatest } from './notificationsSelector';
import { notificationsActions } from './notificationsSlice';
import { isDatastageEvent } from './notificationsUtils';

export const fetchNotifications = async () => {
  try {
    const rootState: RootState = store.getState();

    if (!rootState.app.ready || rootState.app.isUnauthorized) {
      return;
    }

    const notifications = await appAxios.get<INotification[]>(NotificationsApiRoutes.notifications, {
      params: { study_id: undefined, library_id: undefined },
    });

    const notificationMap: NotificationMap = {
      latest: [],
      archived: [],
    };

    for (let item of notifications.data) {
      if (isDatastageEvent(item.extra)) {
        if (!item.isArchived) {
          notificationMap.latest.push(item);
        } else {
          notificationMap.archived.push(item);
        }
      }
    }

    if (!isEqual(selectNotificationsLatest(rootState), notificationMap.latest)) {
      store.dispatch(notificationsActions.setLatest(notificationMap.latest));
    }

    if (!isEqual(selectNotificationsArchived(rootState), notificationMap.archived)) {
      store.dispatch(notificationsActions.setArchived(notificationMap.archived));
    }
  } catch (e) {}
};

const refNotificationState: { intervalId: null | NodeJS.Timer; lastFetch: number | null } = {
  intervalId: null,
  lastFetch: null,
};

export const startNotificationListener = () => {
  const currentFetchNotifications = () => {
    refNotificationState.lastFetch = new Date().valueOf();
    fetchNotifications();
  };

  const runNotificationLoop = () => {
    if (!refNotificationState.intervalId) {
      refNotificationState.intervalId = setInterval(currentFetchNotifications, 10000);
      if (!refNotificationState.lastFetch || new Date().valueOf() - refNotificationState.lastFetch > 10000) {
        currentFetchNotifications();
      }
    }
  };

  const stopNotificationLoop = () => {
    if (refNotificationState.intervalId) {
      clearInterval(refNotificationState.intervalId);
      refNotificationState.intervalId = null;
    }
  };

  const onVisibilityChange = () => {
    switch (document.visibilityState) {
      case 'visible':
        runNotificationLoop();
        break;
      case 'hidden':
        stopNotificationLoop();
        break;
    }
  };

  document.addEventListener('visibilitychange', onVisibilityChange);

  runNotificationLoop();

  return () => {
    stopNotificationLoop();
    document.removeEventListener('visibilitychange', onVisibilityChange);
  };
};

type NotificationMap = Record<'latest' | 'archived', INotification[]>;
