import { appApi } from '@config/appApi';
import {
  IImportToModelQuery,
  ILibraryModelListQueryParams,
  ILibraryModelQuery,
  ILibraryModelResponse,
  ILibraryModelValidateQuery,
} from '@modules/library/model/LibraryModelTypes';
import { LIBRARY_MODEL_TAG_DESCRIPTION } from '@modules/library/model/duck/libraryModelConstants';
import { ModelInvalidations } from '@modules/model/duck/modelApi';
import { SourceInvalidations } from '@modules/source/duck/sourceApi';
import { StoreInvalidations } from '@modules/stores/duck/storeApi';
import { TagDescription } from '@reduxjs/toolkit/query/react';

export const LibraryModelApiRoutes = {
  list: `/api/library/models`,
  model: (modelId: number) => `/api/library/models/${modelId}`,
  importToModel: `/api/library/models/import`,
  validate: `/api/library/models/validate`,
};

const LibraryModelInvalidations: {
  LIST: TagDescription<LIBRARY_MODEL_TAG_DESCRIPTION.LIST>;
  ID: (modelId: number) => TagDescription<LIBRARY_MODEL_TAG_DESCRIPTION.ID>;
} = {
  LIST: { type: LIBRARY_MODEL_TAG_DESCRIPTION.LIST, id: 'LIST' },
  ID: (modelId: number) => ({ type: LIBRARY_MODEL_TAG_DESCRIPTION.ID, modelId }),
};

export const LibraryModelApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    libraryModelList: builder.query<ILibraryModelResponse, ILibraryModelListQueryParams | void>({
      providesTags: [LibraryModelInvalidations.LIST],
      query: (params) => ({
        params,
        url: LibraryModelApiRoutes.list,
      }),
    }),
    libraryDeleteModel: builder.mutation<void, number>({
      invalidatesTags: (request, error, modelId) => [
        LibraryModelInvalidations.LIST,
        LibraryModelInvalidations.ID(modelId),
      ],
      query: (modelId) => ({
        method: 'DELETE',
        url: LibraryModelApiRoutes.model(modelId),
      }),
    }),
    libraryCopyModel: builder.mutation<void, ILibraryModelQuery>({
      invalidatesTags: [LibraryModelInvalidations.LIST],
      query: ({ data, overwrite, cascade_update }) => ({
        data: {
          data,
          cascade_update,
        },
        params: { overwrite },
        method: 'POST',
        url: LibraryModelApiRoutes.list,
      }),
    }),
    importToModel: builder.mutation<void, IImportToModelQuery>({
      invalidatesTags: [ModelInvalidations.LIST, SourceInvalidations.LIST, StoreInvalidations.LIST],
      query: ({ data, study_id }) => ({
        data,
        params: { study_id },
        method: 'POST',
        url: LibraryModelApiRoutes.importToModel,
      }),
    }),
    usedModels: builder.mutation<void, ILibraryModelValidateQuery>({
      query: (data) => ({
        data,
        method: 'POST',
        url: LibraryModelApiRoutes.validate,
      }),
    }),
  }),
});

export const {
  useLibraryModelListQuery,
  useLazyLibraryModelListQuery,
  useLibraryDeleteModelMutation,
  useLibraryCopyModelMutation,
  useImportToModelMutation,
  useUsedModelsMutation,
} = LibraryModelApi;
