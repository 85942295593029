import { DataTableProps, renderCellTypedValue } from '@components';
import { useTablePaginationState } from '@components/ui/table/tableHooks';
import { viewerActions } from '@modules/viewer/duck/viewerSlice';
import { ViewerModalsType } from '@modules/viewer/modals';
import {
  useLazyDataPaginatedQuery,
  ViewerDataPaginationParams,
  ViewerDataPaginationResponse,
} from '@modules/viewer/duck/viewerApi';
import { Tooltip } from '@ui';
import { selectViewerSnapshot } from '@modules/viewer/duck/viewerSelectors';
import { isAbortError, QueryErrorType } from '@shared/utils/Error';
import { AntdIconBox } from '@components/icons';
import { QueryActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate';
import {
  CH_SNAPSHOT_COLUMN_NAME,
  LATEST_DATA_SNAPSHOT,
  SNAPSHOTS_CH_POSTFIXES,
} from '@modules/snapshot/duck/snapshotConstants';
import { SNAPSHOT_STATUS } from '@modules/snapshot/SnapshotTypes';
import { DEFAULT_EXTERNAL_STORES } from '@modules/stores/duck/storeConstants';
import { selectStudyActiveUserRole } from '@modules/study/duck/studySelectors';
import { css, CSSObject } from '@emotion/react';
import { ForwardedRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notification, PaginationProps } from 'antd';
import { RenderCellProps, RenderHeaderCellProps, renderHeaderCell } from 'react-data-grid';
import { HddOutlined } from '@ant-design/icons';
import { get, isEqual } from 'lodash';
import dayjs from 'dayjs';
import { FiltersState } from './ViewerDataTableFilter';
import { ViewerDataTableRef } from './ViewerDataTableTypes';

const initialPageDefault = {
  current: 1,
  pageSize: 50,
  pageSizeOptions: [25, 50, 100, 250],
};

const getQuerySnapshotWhere = (snapshotWhere?: string, queryWhere?: string) => {
  let result = queryWhere || '';
  if (queryWhere && queryWhere.includes(`${CH_SNAPSHOT_COLUMN_NAME}=`)) {
    if (snapshotWhere) {
      result = result.replace(new RegExp(`(${CH_SNAPSHOT_COLUMN_NAME}=.*?)(\\s|$)`, 'gm'), `${snapshotWhere} `);
    }
  } else if (snapshotWhere) {
    if (queryWhere) {
      if (queryWhere.match(/\sAND\s+?$/gm)) {
        result += ` ${snapshotWhere}`;
      } else {
        result += ` AND ${snapshotWhere}`;
      }
    } else {
      result = snapshotWhere;
    }
  }

  return result;
};

export const useDataViewerTable = (
  { initialPage, tableId: mainTableId, tableName, showTracedButtons }: IUseDataViewerTableProps,
  ref: ForwardedRef<ViewerDataTableRef>,
) => {
  const dispatch = useDispatch();
  const snapshotData = useSelector(selectViewerSnapshot);
  const studyActiveRole = useSelector(selectStudyActiveUserRole);
  const { paginationState, setPagination, getPagination } = useTablePaginationState(initialPage || initialPageDefault);
  const [queryDataState, setData] = useState<QueryDataStage>({});
  const refPrevTableId = useRef<string>(mainTableId);
  const refRequest = useRef<Record<string, QueryActionCreatorResult<any> | undefined>>({});
  const refAutomaticFetchParams = useRef<any>();
  const [appliedFilters, setAppliedFilters] = useState<FiltersState>({});
  const [dataPaginatedQuery] = useLazyDataPaginatedQuery();

  const appliedSnapshot = useMemo(() => {
    if (snapshotData?.value) {
      const selectedTable = mainTableId?.split(':')[1] || '';
      const selectedTableStore = mainTableId.split(':')[0];

      let snapshotWhere = undefined;
      let snapshotTableId = undefined;

      const snapshotted = Object.values(snapshotData.tablesDetails || {}).find(
        (item) =>
          item.status === SNAPSHOT_STATUS.COMPLETED && `${snapshotData.protocolId}.${item.tableName}` === selectedTable,
      );

      if (snapshotted && snapshotData.value !== LATEST_DATA_SNAPSHOT.id) {
        if (
          [DEFAULT_EXTERNAL_STORES.Operational.id.toString(), DEFAULT_EXTERNAL_STORES.Metadata.id.toString()].includes(
            selectedTableStore,
          )
        ) {
          snapshotTableId = `${selectedTableStore}:default.${tableName}${SNAPSHOTS_CH_POSTFIXES.defaultScope}`;
        } else {
          snapshotTableId = mainTableId + SNAPSHOTS_CH_POSTFIXES.studyScope;
        }

        const formattedSnapshotDate = `${dayjs(snapshotData.createdAt).format('DD-MMM-YYYY HH:mm:ss')}`;
        snapshotWhere = `${CH_SNAPSHOT_COLUMN_NAME}='${formattedSnapshotDate} (${snapshotData.description})'`;
      }
      return {
        snapshotWhere,
        snapshotTableId,
      };
    }
    return {};
  }, [mainTableId, snapshotData, tableName]);

  const tableId = appliedSnapshot.snapshotTableId || mainTableId;

  const queryData = queryDataState[tableId] || { isLoading: false };
  const pagination = getPagination(queryData.data?.totalItems);

  const fetchData = ({
    snapshotWhere: queriedSnapshotWhere, // It is needed in order to refetch when Snapshot was changed
    ...overriedParams
  }: Partial<ViewerDataPaginationParams> & { snapshotWhere?: string } = {}) => {
    let queryParams = {
      tableId,
      page: paginationState.current - 1,
      size: paginationState.pageSize,
      ...(tableId === appliedFilters.tableId ? appliedFilters : {}),
      ...(overriedParams || {}),
    };

    queryParams.where = getQuerySnapshotWhere(queriedSnapshotWhere || appliedSnapshot.snapshotWhere, queryParams.where);
    const withAppliedFilters = queryParams.where || queryParams.order;

    setData((prev) => ({ [queryParams.tableId]: { ...prev[queryParams.tableId], isLoading: true } }));
    const request = dataPaginatedQuery({ ...queryParams, tableId: queryParams.tableId });
    refRequest.current[queryParams.tableId] = request;

    request.then(({ isSuccess, data: result, error }) => {
      if (isSuccess) {
        setData({ [queryParams.tableId]: { data: result, error: undefined, isLoading: false, fulfilled: true } });
        if (withAppliedFilters) {
          setAppliedFilters((prev) => (prev.tableId === queryParams.tableId ? { ...prev, isError: false } : prev));
        }
      } else {
        if (!isAbortError(error)) {
          const queryError: QueryErrorType = error as any;

          if (withAppliedFilters) {
            notification.error({ message: queryError.data.userMsg });
            setData((prev) => ({ [queryParams.tableId]: { ...prev[queryParams.tableId], isLoading: false } }));
            setAppliedFilters((prev) => (prev.tableId === queryParams.tableId ? { ...prev, isError: true } : prev));
          } else {
            setData({ [queryParams.tableId]: { error: queryError, data: undefined, isLoading: false } });
          }
        }
      }
    });
  };

  useEffect(() => {
    let currentPagination = { ...paginationState };
    let { tableId: _, ...currentAppliedFilters } = appliedFilters;

    if (refPrevTableId.current !== tableId) {
      currentPagination.current = initialPage?.current || initialPageDefault.current;
      currentPagination.pageSize = initialPage?.pageSize || initialPageDefault.pageSize;
      currentAppliedFilters = {};

      setAppliedFilters({});
      setData((prev) => ({ ...prev, [refPrevTableId.current]: { isLoading: false } }));
      setPagination(currentPagination.current, currentPagination.pageSize);

      if (refRequest.current[refPrevTableId.current]?.abort && queryDataState[refPrevTableId.current]?.isLoading) {
        refRequest.current[refPrevTableId.current]!.abort();
      }

      refPrevTableId.current = tableId;
    }

    const queryParams = {
      appliedStudyRole: studyActiveRole,
      tableId,
      page: currentPagination.current - 1,
      size: currentPagination.pageSize,
      snapshotWhere: appliedSnapshot.snapshotWhere,
      ...currentAppliedFilters,
    };

    if (!isEqual(queryParams, refAutomaticFetchParams.current)) {
      fetchData(queryParams);
      refAutomaticFetchParams.current = queryParams;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tableId,
    paginationState.current,
    paginationState.pageSize,
    appliedFilters.tableId,
    appliedFilters.where,
    appliedFilters.order,
    appliedSnapshot,
    studyActiveRole,
  ]);

  useImperativeHandle(ref, () => ({ refetch: fetchData }));

  const dataTableSource = useMemo((): DataTableProps<any> => {
    const data = queryData.data || { meta: [], items: [], options: { row_key_pattern: '' } };

    const handleTracedInfo = (row: RenderCellProps<any>, name: string, pattern: string) => async () => {
      const rowKey = pattern
        .split('~')
        .map((item) => get(row, item, ''))
        .join('~');
      dispatch(
        viewerActions.pushModal({ type: ViewerModalsType.traced, data: { tableId, rowKey, targetColumn: name } }),
      );
    };

    const columns =
      (data.meta &&
        data.meta.map((item) => ({
          key: item.name,
          name: item.name,
          renderHeaderCell: (props: RenderHeaderCellProps<any>) => (
            <Tooltip placement="bottom" title={item.type}>
              {renderHeaderCell(props)}
            </Tooltip>
          ),
          renderCell: ({ row }: RenderCellProps<any>) => (
            <div css={cssCell}>
              {renderCellTypedValue(row[item.name])}
              {showTracedButtons && data.options.row_key_pattern && !queryData.isLoading && (
                <span css={cssTracedIcon}>
                  <AntdIconBox
                    icon={HddOutlined}
                    css={cssCellButton}
                    clickable
                    onClick={handleTracedInfo(row, item.name, data.options.row_key_pattern)}
                  />
                </span>
              )}
            </div>
          ),
        }))) ||
      [];

    const rows = data.items || [];

    return {
      columns,
      rows,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableId, queryData.isLoading, queryData.data]);

  const exportTable = () =>
    dispatch(
      viewerActions.pushModal({
        type: ViewerModalsType.export,
        data: {
          fileName: tableName,
          tableId,
          ...(tableId === appliedFilters.tableId ? appliedFilters : {}),
          where: getQuerySnapshotWhere(appliedSnapshot.snapshotWhere, appliedFilters.where),
        },
      }),
    );

  const onTableChange: PaginationProps['onChange'] = (page, pageSize) => {
    setPagination(page, pageSize);
  };

  const onShowSizeChange: PaginationProps['onShowSizeChange'] = (current, pageSize) => {
    setPagination(current, pageSize);
  };

  const dataIsNotFilled =
    (queryData.error && !appliedFilters.tableId) || (queryData.fulfilled === true && queryData.data === undefined);

  return {
    tableId,
    appliedSnapshot,
    queryData,
    appliedFilters,
    pagination,
    dataIsNotFilled,
    dataTableSource,
    onTableChange,
    onShowSizeChange,
    exportTable,
    setAppliedFilters,
  };
};

interface IUseDataViewerTableProps {
  tableId: string;
  tableName: string;
  showTracedButtons?: boolean;
  initialPage?: typeof initialPageDefault;
}

type QueryDataStage = Record<
  string,
  {
    data?: ViewerDataPaginationResponse;
    error?: QueryErrorType;
    isLoading: boolean;
    fulfilled?: boolean;
  }
>;

const cssTracedIcon = css({});

const cssCell = css({
  [`&:hover .css-${cssTracedIcon.name}`]: {
    display: 'inline',
  },
  [`&:not(:hover) .css-${cssTracedIcon.name}`]: {
    display: 'none',
  },
});

const cssCellButton = (): CSSObject => ({
  position: 'absolute',
  right: '0',
  transform: 'translate(-5px, +50%)',
  background: 'var(--rdg-row-hover-background-color)',
});
