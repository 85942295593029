import { selectGlobalStudy } from '@app/duck/appSelectors';
import { IAuthStudyRolesItem } from '@modules/auth/AuthTypes';
import { useSetActiveStudyRoleMutation, useStudyRolesQuery } from '@modules/auth/duck/authApi';
import { Select } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectStudyActiveUserRole } from '../duck/studySelectors';
import { studyActions } from '../duck/studySlice';

export const StudyRoleSelector = () => {
  const dispatch = useDispatch();
  const globalStudy = useSelector(selectGlobalStudy);
  const studyActiveRole = useSelector(selectStudyActiveUserRole);

  const studyRolesQuery = useStudyRolesQuery(globalStudy?.id!, { skip: !globalStudy?.id });
  const [setStudyRole] = useSetActiveStudyRoleMutation();

  useEffect(() => {
    if (
      studyRolesQuery.data?.current_active_role &&
      studyActiveRole?.role_id !== studyRolesQuery.data.current_active_role.role_id
    ) {
      dispatch(studyActions.serActiveUserRole(studyRolesQuery.data.current_active_role));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, studyRolesQuery.data]);

  return (
    <Select
      style={{ order: 999 }}
      dropdownMatchSelectWidth={false}
      loading={studyRolesQuery.isLoading}
      value={studyActiveRole?.role_id}
      options={studyRolesQuery.data?.all_roles || []}
      fieldNames={{ label: 'name', value: 'role_id' }}
      onChange={(value, option) => {
        if (value !== studyActiveRole?.role_id) {
          setStudyRole({ role_id: value, study_id: globalStudy?.id! }).then(() => {
            dispatch(studyActions.serActiveUserRole(option as IAuthStudyRolesItem));
          });
        }
      }}
    />
  );
};
