import { useSnapshotListQuery } from '@modules/snapshot/duck/snapshotApi';
import { SnapshotList } from '@modules/snapshot/components/SnapshotList';
import { PageTemplateSimple } from '@components';
import { Button, Space } from '@ui';
import routes from '@routes';
import { selectGlobalStudy } from '@app/duck/appSelectors';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { UndoOutlined } from '@ant-design/icons';

export const SnapshotRootPage = () => {
  const snapshotListQuery = useSnapshotListQuery();
  const { t } = useTranslation(['snapshot']);
  const globalStudy = useSelector(selectGlobalStudy);

  return (
    <PageTemplateSimple
      hideTitleSkeleton
      title={{
        children: t('pageRootName'),
        extra: (
          <Space>
            <Button
              children={<UndoOutlined style={{ transform: 'rotate(0.25turn)' }} />}
              onClick={snapshotListQuery.refetch}
            />
            <Link to={routes.study.snapshots.create.resolver({ studyId: globalStudy!.id })}>
              <Button children={t('add')} />
            </Link>
          </Space>
        ),
      }}
      content={{ isLoading: snapshotListQuery.isLoading }}
    >
      {!snapshotListQuery.isLoading && (
        <SnapshotList data={snapshotListQuery.data || []} loading={snapshotListQuery.isFetching} t={t} />
      )}
    </PageTemplateSimple>
  );
};
