import { useModelQuery, useRunModelMutation, useSaveModelMutation } from '@modules/model/duck/modelApi';
import { PageTemplateSimple } from '@components';
import { Button, Dropdown, notification, Space } from '@ui';
import routes from '@routes';
import { RootState } from '@store';
import { ModelEditorSandbox } from '@modules/modelEditor/components';
import { modelEditorActions } from '@modules/modelEditor/duck/modelEditorSlice';
import { ModelEditorModalsController } from '@modules/modelEditor/modals';
import { exportModelEditorData, sanitizeModelEditorData } from '@modules/modelEditor/duck/modelEditorUtils';
import { validateNodes } from '@modules/modelEditor/components/builder/Validator';
import { compileSparkInstruction } from '@modules/modelEditor/duck/modelEditorSparkIntegration';
import { modelActions } from '@modules/model/duck/modelSlice';
import { ModelModalsController, ModelModalsType } from '@modules/model/modals';
import { useLazyTablesExistQuery, useLazyTablesInSqlExistQuery } from '@modules/viewer/duck/viewerApi';
import {
  ModelEditor,
  ModelEditorNodeDomain,
  ModelEditorNodeSql,
  ModelEditorNodeType,
} from '@modules/modelEditor/ModelEditorTypes';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useStore } from 'react-redux';
import { ReactFlowProvider } from 'reactflow';
import { useCallback, useEffect, useState } from 'react';
import { uniq } from 'lodash';

export const EditorModelPane = ({ modelId }: EditorModelPaneProps) => {
  const [api, contextHolder] = notification.useNotification();
  const { studyId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation(['model']);
  const modelQuery = useModelQuery(modelId);

  const store = useStore<RootState>();
  const [saveModel, saveModelQuery] = useSaveModelMutation();
  const [runModel, runModelQuery] = useRunModelMutation();
  const [getTablesExistInfo, getTablesExistResult] = useLazyTablesExistQuery();
  const [getTablesInSqlExistInfo, getTablesInSqlExistResult] = useLazyTablesInSqlExistQuery();
  const [schema, setSchema] = useState<ModelEditor>();

  const getTablesInfo = useCallback((tables: string[]) => getTablesExistInfo(tables, true), [getTablesExistInfo]);

  useEffect(() => {
    if (modelQuery.data?.name !== store.getState().modelEditor.modelName) {
      store.dispatch(modelEditorActions.reset());
      store.dispatch(modelActions.setDataViewer({ tableKey: null, tableName: null }));
    }
  }, [modelQuery.data?.name, store]);

  useEffect(() => {
    const tablesToCheck = modelQuery?.data?.schema
      .filter((node) => node.type === ModelEditorNodeType.domain)
      .map((domain) => (domain.data as ModelEditorNodeDomain).tableName ?? '')
      .filter(Boolean);

    const sqlToCheck = modelQuery?.data?.schema
      .filter((node) => node.type === ModelEditorNodeType.sql)
      .map((node) => (node.data as ModelEditorNodeSql).sql_statement ?? '')
      .filter(Boolean);

    if (modelQuery?.data?.schema) {
      setSchema(modelQuery?.data?.schema);
    }

    const checkTablesAndSql = async () => {
      try {
        let updatedSchema = modelQuery?.data?.schema || [];

        if (tablesToCheck && tablesToCheck.length > 0) {
          // console.log('---if tablesToCheck');
          const tablesInfo = await getTablesInfo(tablesToCheck);
          updatedSchema = updatedSchema.map((node) => {
            if (node.type === ModelEditorNodeType.domain) {
              const domainData = node.data as ModelEditorNodeDomain;
              const tableName = domainData.tableName ?? '';
              return {
                ...node,
                data: {
                  ...node.data,
                  exists: !!tablesInfo.data![tableName],
                },
              };
            }
            return node;
          });
        }

        if (sqlToCheck && sqlToCheck.length > 0) {
          const sqlInfo = await getTablesInSqlExistInfo(sqlToCheck);
          updatedSchema = updatedSchema.map((node) => {
            if (node.type === ModelEditorNodeType.sql) {
              const sqlData = node.data as ModelEditorNodeSql;
              const sql = sqlData.sql_statement ?? '';
              return {
                ...node,
                data: {
                  ...node.data,
                  isValid: !!sqlInfo.data![sql],
                },
              };
            }
            return node;
          });
        }

        setSchema(updatedSchema);
      } catch (e) {
        console.log("Error while checking tables and SQL's", e);
        setSchema(modelQuery?.data?.schema);
      }
    };

    checkTablesAndSql();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelQuery?.data?.schema]);

  const saveModelEditor = async () => {
    const state = store.getState();

    const modelEditorData = exportModelEditorData(state.modelEditor);

    const modelEditorDataCleared = modelEditorData.map((node) => {
      const { isCheckingNeeded = false, ...clearData } = { ...node.data };
      return {
        ...node,
        data: {
          isCheckingNeeded,
          ...clearData,
        },
      };
    });

    const validationErrors = validateNodes(modelEditorDataCleared).map((error) => ({
      ...error,
      message: t(error.message),
    }));

    store.dispatch(modelEditorActions.setErrors(validationErrors));
    if (validationErrors.length !== 0) {
      api.error({
        message: <Space direction="vertical" children={uniq(validationErrors.map((item) => item.message))} />,
      });
      throw new Error(validationErrors.map((error) => error.message).join('; '));
    }

    await saveModel({
      schema: sanitizeModelEditorData(modelEditorDataCleared),
      spark_schema: compileSparkInstruction(
        modelEditorDataCleared,
        modelQuery.data!.name,
        store.getState().study.fallbackCHDB,
        store.getState().auth.user!.cluster,
      ),
      id: modelQuery.data!.id,
    });
  };

  const onSave = async () => {
    try {
      await saveModelEditor();
    } catch (e) {
      console.error(e);
    }
  };

  const onSaveExit = async () => {
    try {
      await saveModelEditor();

      store.dispatch(modelEditorActions.reset());
      onCancel();
    } catch (e) {
      console.error(e);
    }
  };

  const onRunModel = async () => {
    try {
      await saveModelEditor();

      await runModel(modelId);
      notification.info({ message: t('builder.runNotification') });
    } catch (e) {
      console.error(e);
    }
  };

  const onViewLogs = () =>
    store.dispatch(modelActions.pushModal({ type: ModelModalsType.openLogs, data: modelQuery.data }));

  const onCancel = () => {
    if (studyId) {
      navigate(routes.study.models.root.resolver({ studyId }));
    } else {
      navigate(routes.app.root.resolver());
    }
  };

  const schemaIsLoading = !schema || !modelQuery.data || modelQuery.isLoading;
  const isValidating = getTablesExistResult.isLoading || getTablesInSqlExistResult.isLoading;

  return (
    <>
      <PageTemplateSimple
        content={{
          isLoading: schemaIsLoading,
          errorText: t('loadingError'),
          error: modelQuery.error,
        }}
        title={{
          hidden: schemaIsLoading,
          children: modelQuery.data?.name,
          extra: (
            <Space wrap>
              <Dropdown.Button
                type="primary"
                disabled={isValidating || schemaIsLoading}
                loading={runModelQuery.isLoading || saveModelQuery.isLoading}
                menu={{
                  items: [
                    {
                      label: t('builder.runAndSave'),
                      key: 'save_run',
                      onClick: onRunModel,
                    },
                    {
                      label: t('saveExit'),
                      key: 'save_exit',
                      onClick: onSaveExit,
                    },
                  ],
                }}
                onClick={onSave}
                children={t('save')}
              />
              <Button children={t('rootTable.actionMenu.viewLog')} onClick={onViewLogs} />
              <Button children={t('cancel')} onClick={onCancel} />
            </Space>
          ),
        }}
      >
        {!schemaIsLoading && (
          <ReactFlowProvider>
            <ModelEditorSandbox initData={schema} modelName={modelQuery.data!.name} />
          </ReactFlowProvider>
        )}
      </PageTemplateSimple>
      {contextHolder}
      <ModelEditorModalsController />
      <ModelModalsController />
    </>
  );
};

interface EditorModelPaneProps {
  modelId: number;
}
