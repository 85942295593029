import { useModelListPaginatedQuery } from '@modules/model/duck/modelApi';
import { ModelModalsController, ModelModalsType } from '@modules/model/modals';
import { initialPage } from '@shared/utils/common';
import { useStoreListQuery } from '@modules/stores/duck/storeApi';
import { selectGlobalStudy } from '@app/duck/appSelectors';
import { PageTemplateSimple } from '@components';
import { Model } from '@modules/model/ModelTypes';
import { ModelList } from '@modules/model/components';
import { modelActions } from '@modules/model/duck/modelSlice';
import { Button } from '@ui';
import { useTablePaginationState } from '@components/ui/table/tableHooks';
import { useDispatch, useSelector } from 'react-redux';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';

export const ModelRootPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['model']);
  const globalStudy = useSelector(selectGlobalStudy);
  const { paginationState, getPagination, onTableChange, preparedFilters, preparedSorter } =
    useTablePaginationState<Model>(initialPage);

  const modelQuery = useModelListPaginatedQuery({
    page: paginationState.current - 1,
    ...preparedFilters,
    ...preparedSorter,
  });

  const storeQuery = useStoreListQuery({ detailed: 0 });

  let libraryCopyButton = null;
  let libraryModalController = null;

  if (process.env.APP_MODE === 'GL') {
    const { LibraryModelModalsController } = require('@modules/library/model/modals');
    const onCopyFromLibrary = async (values: any) => {
      const { libraryModelActions } = require('@modules/library/model/duck/libraryModelSlice');
      const { ELibraryModelModalsType } = require('@modules/library/model/modals');

      dispatch(
        libraryModelActions.pushModal({
          type: ELibraryModelModalsType.copyModel,
          data: { importToStudyId: globalStudy?.id },
        }),
      );
    };

    libraryCopyButton = <Button children={t('copyFromLibrary')} onClick={onCopyFromLibrary} />;
    libraryModalController = <LibraryModelModalsController />;
  }

  const pagination = getPagination(modelQuery.data?.totalItems);

  const addModelModal = () => dispatch(modelActions.pushModal({ type: ModelModalsType.saveModel }));

  return (
    <PageTemplateSimple
      content={{
        isLoading: modelQuery.isLoading && !modelQuery.data,
        errorText: t('loadingError'),
        error: modelQuery.error,
      }}
      title={{
        children: t('pageRootName'),
        extra: (
          <Space>
            {libraryCopyButton}
            <Button children={t('add')} onClick={addModelModal} />
          </Space>
        ),
      }}
    >
      {modelQuery.data && (
        <ModelList
          data={modelQuery.data}
          storeList={storeQuery.data}
          studyId={globalStudy?.id!}
          onChange={onTableChange}
          pagination={pagination}
          loading={modelQuery.isFetching}
          t={t}
        />
      )}
      {libraryModalController}
      <ModelModalsController />
    </PageTemplateSimple>
  );
};
