import i18n from '@i18n';
import dayjs from 'dayjs';

export const timestampToUNIX = (date: string) => dayjs(date).unix();

export const dateToString = (date?: number | string) => {
  if (typeof date === 'string') {
    return dayjs(date).format('YYYY-MM-DD HH:mm');
  }
  if (typeof date === 'number') {
    return dayjs(date * 1000).format('YYYY-MM-DD HH:mm');
  }
  return i18n.t('na');
};

export const dataToHumanMonthDayYear = (date?: number) => (date ? dayjs(date).format('MMM DD, YYYY') : i18n.t('na'));
