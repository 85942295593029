import { Button, Checkbox, Input, Select, Space, Table, Tooltip } from '@ui';
import { ViewerCHTableInfoData } from '@modules/viewer/ViewerTypes';
import { ModelEditorSchemaEditorItem } from '@modules/modelEditor/ModelEditorTypes';
import { AntdIconBox } from '@components/icons';
import { modelEditorActions } from '@modules/modelEditor/duck/modelEditorSlice';
import { ModelEditorModalsType } from '@modules/modelEditor/modals';
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSSObject, Theme } from '@emotion/react';
import { useDispatch } from 'react-redux';
import type { DragEndEvent } from '@dnd-kit/core';
import { ColumnsType } from 'antd/lib/table';
import { useMemo } from 'react';
import { FunctionOutlined } from '@ant-design/icons';
import { ModelEditorSchemaEditorRow } from './ModelEditorSchemaEditorRow';
import { ModelEditorSchemaEditorDataTypesOptions } from './ModelEditorSchemaEditorConstants';
import { useModelEditorSchemaEditor } from './ModelEditorSchemaEditorHooks';

export const ModelEditorSchemaEditor = ({ initData, tableInfo, isLoading }: ModelEditorSchemaEditorProps) => {
  const dispatch = useDispatch();

  const { schemaEditorData = [], actions, deletedAllValue, moveRow } = useModelEditorSchemaEditor(tableInfo, initData);
  const getVariables = schemaEditorData.map((el) => el.name);

  const columns: ColumnsType<ModelEditorSchemaEditorItem> = useMemo(
    () => [
      {
        width: 40,
        key: 'sort',
        align: 'center',
      },
      {
        width: 40,
        key: 'index',
        render: (_, __, index) => index + 1,
        align: 'center',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        render: (name, record) => (
          <Input
            name={`${record.index}:name`}
            onBlur={actions.onChangeItemWithInputText}
            size="middle"
            bordered={false}
            defaultValue={name}
            style={{ fontWeight: 'bold', color: 'black' }}
            disabled={!record.custom}
          />
        ),
      },
      {
        title: 'New Name',
        render: (_, record) => (
          <Input
            name={`${record.index}:newName`}
            size="middle"
            bordered={false}
            defaultValue={record.newName}
            onBlur={actions.onChangeItemWithInputText}
          />
        ),
      },
      {
        width: 150,
        title: 'Data Type',
        dataIndex: 'type',
        render: (type, record) => (
          <Select
            css={cssSelect}
            id={`${record.index}:type`}
            size="middle"
            bordered={false}
            value={type || ModelEditorSchemaEditorDataTypesOptions[0].value}
            style={{ width: '100%', color: 'black' }}
            disabled={!record.custom}
            onChange={(value) => actions.onChangeItemWithSelect(`${record.index}:type`, value)}
            options={ModelEditorSchemaEditorDataTypesOptions}
          />
        ),
      },
      {
        width: 70,
        title: 'Length',
        render: (_, record) =>
          record.custom && (
            <Input
              name={`${record.index}:length`}
              size="middle"
              bordered={false}
              defaultValue={record.length}
              onBlur={actions.onChangeItemWithInputText}
            />
          ),
      },
      {
        width: 100,
        title: 'Precision',
        render: (_, record) =>
          record.custom && (
            <Input
              name={`${record.index}:precision`}
              size="middle"
              bordered={false}
              defaultValue={record.precision}
              onBlur={actions.onChangeItemWithInputText}
            />
          ),
      },
      {
        width: 100,
        title: 'Scale',
        render: (_, record) =>
          record.custom && (
            <Input
              name={`${record.index}:scale`}
              size="middle"
              bordered={false}
              defaultValue={record.scale}
              onBlur={actions.onChangeItemWithInputText}
            />
          ),
      },
      {
        width: 70,
        title: () => (
          <Space size="middle">
            <FunctionOutlined style={{ opacity: 0 }} />
            <Tooltip title="Delete Column">
              <Checkbox
                css={cssCheckbox}
                onChange={actions.onChangeDeletedAllCheckbox}
                indeterminate={deletedAllValue === null}
                checked={deletedAllValue !== null ? deletedAllValue : undefined}
              />
            </Tooltip>
          </Space>
        ),
        render: (_, record) => (
          <Space size="middle" style={{ marginLeft: '8px' }}>
            <AntdIconBox
              icon={FunctionOutlined}
              css={cssTransformation(!!record.expression)}
              clickable
              onClick={() => {
                dispatch(
                  modelEditorActions.pushModal({
                    type: ModelEditorModalsType.transformSettings,
                    callback: (result) => actions.onChangeItemWithExpression(`${record.index}:expression`, result),
                    data: { variables: getVariables, value: record.expression || record.name },
                  }),
                );
              }}
            />
            <Checkbox
              name={`${record.index}:deleted`}
              css={cssCheckbox}
              onChange={actions.onChangeItemWithCheckbox}
              checked={record.deleted}
            />
          </Space>
        ),
      },
    ],
    [actions, deletedAllValue, dispatch, getVariables],
  );

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    }),
  );

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = schemaEditorData.findIndex((i) => i.key === active.id);
      const overIndex = schemaEditorData.findIndex((i) => i.key === over?.id);
      moveRow(activeIndex, overIndex);
    }
  };

  const disableAddButton = schemaEditorData.some((item) => item.name === '');

  return (
    <>
      <Space full justify="end">
        <Button
          type="primary"
          style={{ marginBottom: 20, marginLeft: 'auto' }}
          onClick={actions.onAddNewItem}
          disabled={disableAddButton}
        >
          Add Derived Variable
        </Button>
      </Space>

      <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext items={schemaEditorData.map((i) => i.key ?? 'empty')} strategy={verticalListSortingStrategy}>
          <Table
            css={cssTable}
            components={{
              body: {
                row: ModelEditorSchemaEditorRow,
              },
            }}
            rowClassName={(item) => (item.deleted ? 'deleted-row' : '')}
            rowKey="key"
            columns={columns}
            dataSource={schemaEditorData}
            pagination={false}
            scroll={{ y: 'calc(100vh - 300px)' }}
            sticky
            bordered
            size="small"
            loading={isLoading}
          />
        </SortableContext>
      </DndContext>
    </>
  );
};

const cssTransformation =
  (isActive: boolean) =>
  (theme: Theme): CSSObject =>
    isActive
      ? {
          stroke: theme.colorPrimary,
          color: theme.colorPrimary,
          strokeWidth: '70px',
          fill: theme.colorPrimary,
        }
      : { stroke: theme.colorText, color: theme.colorText, strokeWidth: '50px' };

const cssTable = (theme: Theme): CSSObject => ({
  '&&&& td.ant-table-cell': {
    padding: 0,
  },
  '& .deleted-row': {
    backgroundColor: theme.red1,
  },
});

const cssCheckbox = (theme: Theme): CSSObject => ({
  '&&:hover .ant-checkbox-inner': {
    borderColor: theme.red6,
  },
  '& .ant-checkbox-checked .ant-checkbox-inner': {
    backgroundColor: theme.red6,
    borderColor: theme.red6,
  },
  '&&&:hover .ant-checkbox-checked .ant-checkbox-inner': {
    backgroundColor: theme.red4,
    borderColor: theme.red4,
  },
  '&&& .ant-checkbox-checked:after': {
    borderColor: theme.red6,
  },
  '&&&:hover .ant-checkbox-checked:after': {
    borderColor: theme.red4,
  },
  '& .ant-checkbox-indeterminate .ant-checkbox-inner:after': {
    backgroundColor: theme.red6,
  },
});

const cssSelect = (): CSSObject => ({
  '&&& .ant-select-selector': {
    color: 'inherit',
  },
});

interface ModelEditorSchemaEditorProps {
  tableInfo?: ViewerCHTableInfoData[];
  initData: ModelEditorSchemaEditorItem[];
  isLoading?: boolean;
}
