import { Typography } from 'antd';
import { CSSObject } from '@emotion/react';

const fontSizeMap = {
  xs: '12px',
  sm: '14px',
  ms: '16px',
  lg: '18px',
};

export const ActorAndInfo = ({ actor, info, actorSize = 'xs', infoSize = 'xs' }: ActorAndInfoProps) => (
  <div css={cssBody}>
    {actor && <Typography.Text css={cssActor(actorSize)} children={actor} ellipsis title={actor} />}
    <Typography.Text css={cssInfo(infoSize)} type="secondary" children={info} />
  </div>
);

const cssBody = (): CSSObject => ({
  display: 'flex',
  flexDirection: 'column',
});

const cssInfo = (size: ActorAndInfoProps['infoSize']) => (): CSSObject => ({
  fontSize: fontSizeMap[size!],
});

const cssActor = (size: ActorAndInfoProps['actorSize']) => (): CSSObject => ({
  fontSize: fontSizeMap[size!],
});

interface ActorAndInfoProps {
  actor?: string;
  info: string;
  actorSize?: 'xs' | 'sm' | 'ms' | 'lg';
  infoSize?: 'xs' | 'sm' | 'ms' | 'lg';
}
