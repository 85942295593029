import { DraggableModal } from '@ui';
import { useCdrColumns } from '@modules/library/cdr/duck/libraryCdrHooks';
import { useLazyLibraryCdrListQuery } from '@modules/library/cdr/duck/libraryCdrApi';
import { LibraryStatus } from '@modules/library/root/duck/libraryConstants';
import { ELibraryEntityNames, ELibrarySourceType } from '@modules/library/root/LibraryTypes';
import { useCopyAnalysisObjectCDRMutation } from '@modules/library/analysisObjects/cdr/duck/analysisObjectCDRApi';
import {
  getLibraryImportCDRFromLibrarySource,
  ILibraryImportCDRProps,
  LibraryImportCDR,
  WrapperFn,
} from '@modules/library/cdr/components/LibraryImportCDR';
import { IAnalysisObjectCDRQuery } from '@modules/library/analysisObjects/cdr/AnalysisObjectCDRTypes';
import { prepareLibraryCDR } from '@modules/library/cdr/duck/libraryCdrUtils';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

const AnalysisObjectCdrModalsCopyCdrContent = ({ onClose, t }: IAnalysisObjectCdrModalsCopyCdrContentProps) => {
  const { cdrColumns, locale } = useCdrColumns();
  const [libraryCDRListQuery, libraryCDRListQueryData] = useLazyLibraryCdrListQuery();
  const [copyCDR] = useCopyAnalysisObjectCDRMutation();

  const wrapperLibrary: WrapperFn = async (id: number) => {
    const data = await libraryCDRListQuery({ library_id: id }).unwrap();
    return getLibraryImportCDRFromLibrarySource(data.items, ELibrarySourceType.Library);
  };

  const onImport: ILibraryImportCDRProps['onImport'] = async (values, { kind }) => {
    await copyCDR({ data: prepareLibraryCDR(values, kind) as IAnalysisObjectCDRQuery['data'] }).unwrap();
  };

  return (
    <LibraryImportCDR
      onClose={onClose}
      columns={cdrColumns}
      locale={locale}
      kind={ELibraryEntityNames.CDR}
      libraryTableListQuery={wrapperLibrary}
      tableDataFetching={libraryCDRListQueryData.isFetching}
      onImport={onImport}
      libraryStatuses={[LibraryStatus.Active]}
      sourceOnlyLibrary
      hideOverwriteHandles
    />
  );
};

export const AnalysisObjectCdrModalsCopyCdr = ({ open, data, onClose }: IAnalysisObjectCdrModalsCopyCdrProps) => {
  const { t } = useTranslation(['libraryCdr']);

  return (
    <DraggableModal width="50%" open={open} onCancel={onClose} title={t('copyForm.title')} footer={null} destroyOnClose>
      {open && <AnalysisObjectCdrModalsCopyCdrContent data={data} onClose={onClose} t={t} />}
    </DraggableModal>
  );
};

export interface IAnalysisObjectCdrModalsCopyCdrProps {
  open: boolean;
  data: any;
  onClose: () => void;
}

interface IAnalysisObjectCdrModalsCopyCdrContentProps
  extends Pick<IAnalysisObjectCdrModalsCopyCdrProps, 'data' | 'onClose'> {
  t: TFunction;
}
