import { getTableNameWithSchema, isContainSchema, removeItemId } from '@shared/utils/Viewer';
import { downloadByUrl } from '@shared/utils/File';
import { listToOptions } from '@shared/utils/Form';
import { FileFormatExport } from '@modules/viewer/ViewerTypes';
import { useExportDataMutation } from '@modules/viewer/duck/viewerApi';
import { DraggableModal, Form, FormItem, FormLayout, Input, ProgressWithTitle, Select } from '@ui';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useState } from 'react';
import { CSSObject } from '@emotion/react';

const fileFormatOptions = listToOptions(Object.values(FileFormatExport));
const separatorDefaultOptions = [
  { label: '<comma>', value: ',' },
  { label: '<semicolon>', value: ';' },
  { label: '<tab>', value: '\t' },
];
const getFormattedFileName = (format: FileFormatExport, fileName: string) => {
  const clearFileName = fileName.split('.')[0] || '';

  if (format === FileFormatExport.custom) {
    return clearFileName;
  }

  return `${clearFileName}.${format.toLowerCase()}`;
};

const ViewerModalsExportContent = ({ data, onClose, t }: ViewerModalsExportContentProps) => {
  const [form] = Form.useForm();
  const [exportData, { isError, error, isLoading }] = useExportDataMutation();
  const [format, setFormat] = useState('');
  const [separatorOptions, setSeparatorOptions] = useState(separatorDefaultOptions);
  const [progress, setProgress] = useState({ progress: 0, step: 1 });
  const exportErrorMessage = (isError && error && 'data' in error && error.data.userMsg) || null;

  const { name } = getTableNameWithSchema(data.tableId);

  const increaseProgress = (value: number = 0) => {
    const increment = Math.floor(Math.random() * 5 + 3);
    if (value) {
      setProgress((prev) => ({ ...prev, progress: value }));
    } else {
      setProgress((prev) =>
        prev.progress + increment >= 100
          ? { progress: 0, step: prev.step + 1 }
          : { ...prev, progress: prev.progress + increment },
      );
    }
  };

  const onSubmit = async (values: ViewerExportFormValues) => {
    try {
      setProgress({ progress: 0, step: 1 });
      const url = await exportData({
        ...values,
        separator: format === FileFormatExport.custom ? values.separator : ',',
        tableId: removeItemId(isContainSchema(data.tableId) ? data.tableId : name),
        where: data.where,
        order: data.order,
        callback: increaseProgress,
      }).unwrap();

      downloadByUrl(url);

      onClose();
    } catch (e) {
      console.error(e);
    }
  };

  const onChangeFormat = (value: FileFormatExport) => {
    setFormat(value);
    form.setFieldValue('fileName', getFormattedFileName(value, form.getFieldValue('fileName')));
  };

  const initValues = (data && {
    fileName: getFormattedFileName(FileFormatExport.csv, data.fileName),
    fileFormat: FileFormatExport.csv,
    separator: ',',
  }) || {
    fileName: '',
    fileFormat: FileFormatExport.csv,
    separator: ',',
  };

  const searchAndAdd = (value: string) => {
    if (!separatorDefaultOptions.some((item) => item.value === value)) {
      setSeparatorOptions([{ label: value, value }].concat(separatorDefaultOptions));
    }
  };

  return (
    <FormLayout
      form={form}
      onCancel={onClose}
      onSubmit={onSubmit}
      okText={t('exportForm.exportBtn')}
      initialValues={initValues}
    >
      <FormItem name="fileName" label={t('exportForm.fileName')} rules={[{ required: true }]}>
        <Input />
      </FormItem>
      <FormItem name="fileFormat" label={t('exportForm.fileFormat')} rules={[{ required: true }]}>
        <Select options={fileFormatOptions} placeholder={t('select')} onChange={onChangeFormat} />
      </FormItem>
      <FormItem name="separator" label={t('exportForm.separator')} hidden={format !== FileFormatExport.custom}>
        <Select showSearch options={separatorOptions} placeholder={t('select')} onSearch={searchAndAdd} />
      </FormItem>
      {isLoading && (
        <ProgressWithTitle title={t('exportForm.processing', { num: progress.step })} percent={progress.progress} />
      )}
      {isError && <div css={cssTestMsg}>{exportErrorMessage}</div>}
      {/*<FormItem name="exportTo" label={t('exportForm.exportTo')} rules={[{ required: false }]}>*/}
      {/*  <Select options={dataStoreOption} placeholder={t('select')} loading={storeListQuery.isLoading} disabled />*/}
      {/*</FormItem>*/}
    </FormLayout>
  );
};

export const ViewerModalsExport = ({ open, data, onClose }: ViewerModalsExportProps) => {
  const { t } = useTranslation(['viewer']);
  const title = data?.where ? t('exportForm.titleFiltered') : t('exportForm.title');

  return (
    <DraggableModal open={open} onCancel={onClose} title={title} footer={null} destroyOnClose>
      {open && <ViewerModalsExportContent data={data} onClose={onClose} t={t} />}
    </DraggableModal>
  );
};

const cssTestMsg = (): CSSObject => ({
  color: 'red',
});

export interface ViewerModalsExportProps {
  open: boolean;
  data: { tableId: string; fileName: string; where?: string; order?: string };
  onClose: () => void;
}

interface ViewerModalsExportContentProps extends Pick<ViewerModalsExportProps, 'data' | 'onClose'> {
  t: TFunction;
}

export interface ViewerExportFormValues {
  fileName: string;
  fileFormat: FileFormatExport;
  separator: string;
  // exportTo: Store;
}
