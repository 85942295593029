import { Button, Space, Spin } from '@ui';
import { useTestConnectionMutation } from '@modules/source/duck/sourceApi';
import { CSSObject } from '@emotion/react';
import { RefObject } from 'react';
import { AutoForm, IFormDefinition, IFormValue, validateRequiredProperties } from 'react-auto-form';
import 'react-auto-form/dist/auto-form.css';
import { useTranslation } from 'react-i18next';

export const SourceModalsSaveConnection = ({
  definition,
  initValues,
  onSave,
  innerRef,
}: SourceModalsSaveConnectionProps) => {
  const { t } = useTranslation('datasource');
  const [testConnection, { data, isLoading, isError, error, isSuccess }] = useTestConnectionMutation();
  const testConnectionResultMessage =
    (isError && error && 'data' in error && error.data.rawData?.message) || (isSuccess && data?.message) || null;

  const validate = (values: IFormValue) =>
    validateRequiredProperties(definition!, (name: string) => t('shared.fieldRequired', { field: name }), values);

  const testConnectionHandler = (values: IFormValue) => testConnection(values).unwrap();

  return (
    <AutoForm<IFormValue>
      definition={definition}
      initialValue={initValues}
      i18nRequiredProperty="Required"
      validate={validate}
      isInitialValid
      onSave={onSave}
      enableReinitialize
      innerRef={innerRef}
    >
      {({ fields, handleSubmit, submitForm, isSubmitting, isValid, values, isValidating }) => (
        <div css={cssBody}>
          {fields}
          <Space full justify="end">
            {isLoading ? <Spin size="small" /> : <div css={cssTestMsg(isError)}>{testConnectionResultMessage}</div>}
            <Button css={cssTestBtn} disabled={!isValid || isLoading} onClick={() => testConnectionHandler(values)}>
              {t('buttons.testConnection')}
            </Button>
          </Space>
        </div>
      )}
    </AutoForm>
  );
};

const cssBody = (): CSSObject => ({
  width: '100%',
});

const cssTestBtn = (): CSSObject => ({
  margin: '8px 0',
});

const cssTestMsg = (isError: boolean): CSSObject => ({
  color: isError ? 'red' : 'green',
});

interface SourceModalsSaveConnectionProps {
  initValues: any;
  definition: IFormDefinition;
  onSave: (values: IFormDefinition) => void;
  innerRef: RefObject<any>;
}
