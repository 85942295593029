import {
  ModelEditor,
  ModelEditorNode,
  ModelEditorNodeDomain,
  ModelEditorNodeGroupBy,
  ModelEditorNodeJoin,
  ModelEditorNodeResult,
  ModelEditorNodeSql,
  ModelEditorNodeTransformation,
  ModelEditorNodeType,
  ModelEditorValidationError,
} from '@modules/modelEditor/ModelEditorTypes';
import { isContainForbiddenWords, isContainSelect, isContainTable } from '@modules/modelEditor/modals/utils';
import { StageConfig } from '@modules/modelEditor/components/builder/StageConfig';
import { checkSpecialCharacters } from './Utils';

type ModelEditorNodeWithParents = ModelEditorNode & { parents: string[] };

class BaseValidator {
  validate(node: ModelEditorNode): ModelEditorValidationError[] {
    if (node.data?.isCheckingNeeded) {
      return [{ message: 'errors.checkingNeeded', node }];
    }
    return [];
  }
}

class DomainValidator extends BaseValidator {
  validate(node: ModelEditorNode): ModelEditorValidationError[] {
    const errors = super.validate(node);
    const modelName = (node.data as ModelEditorNodeDomain).modelName ?? '';
    const exists = (node.data as ModelEditorNodeDomain).exists ?? true;

    if (node.data.tableName?.endsWith(`.${modelName}`)) {
      errors.push({ message: 'domain.errors.recursiveModel', node });
    }

    if (!exists) {
      errors.push({ message: 'domain.errors.tableNotExists', node });
    }

    return errors;
  }
}

class CustomSqlValidator extends BaseValidator {
  validate(node: ModelEditorNode): ModelEditorValidationError[] {
    const errors = super.validate(node);

    const isValid = (node.data as ModelEditorNodeSql).isValid ?? true;
    if (!isValid) {
      errors.push({ message: 'sql.errors.sqlIsValid', node });
    }

    const modelName = (node.data as ModelEditorNodeSql).modelName ?? '';
    const { sql_statement: sqlStatement, sql_schema: sqlSchema } = node.data as ModelEditorNodeSql;

    if (!sqlStatement) {
      errors.push({ message: 'sql.errors.emptyStatement', node });
    }

    if (!sqlSchema) {
      errors.push({ message: 'sql.errors.emptyColumns', node });
    }

    if (isContainForbiddenWords(sqlStatement)) {
      errors.push({ message: 'sql.errors.containForbiddenWordsError', node });
    }

    if (!isContainSelect(sqlStatement)) {
      errors.push({ message: 'sql.errors.containSelectError', node });
    }

    if (isContainTable(sqlStatement, modelName)) {
      errors.push({ message: 'sql.errors.recursiveModel', node });
    }

    return errors;
  }
}

class TransformValidator extends BaseValidator {
  validate(node: ModelEditorNode): ModelEditorValidationError[] {
    const errors = super.validate(node);
    const data = node.data as ModelEditorNodeTransformation;

    if (!Array.isArray(data.transformation) || data.transformation.length === 0) {
      errors.push({ message: 'transform.errors.missingProperties', node });
    }

    return errors;
  }
}

class JoinValidator extends BaseValidator {
  validate(node: ModelEditorNode): ModelEditorValidationError[] {
    const errors = super.validate(node);
    const data = node.data as ModelEditorNodeJoin;

    if (!data.type) {
      errors.push({ message: 'join.errors.missingType', node });
    }

    if (!Array.isArray(data.relations) || data.relations.length === 0) {
      errors.push({ message: 'join.errors.missingRelations', node });
    }

    return errors;
  }
}

class GroupByValidator extends BaseValidator {
  validate(node: ModelEditorNode): ModelEditorValidationError[] {
    const errors = super.validate(node);
    const data = node.data as ModelEditorNodeGroupBy;

    if (!Array.isArray(data.aggregateFn) || data.aggregateFn.length === 0) {
      errors.push({ message: 'groupBy.errors.missingAggregatedFn', node });
    }
    if (!Array.isArray(data.groupBy) || data.groupBy.length === 0) {
      errors.push({ message: 'groupBy.errors.missingGroupBy', node });
    }

    return errors;
  }
}

class ResultValidator extends BaseValidator {
  validate(node: ModelEditorNode): ModelEditorValidationError[] {
    const errors = super.validate(node);
    const data = node.data as ModelEditorNodeResult;

    if (!Array.isArray(data.primaryKeys) || data.primaryKeys.length === 0) {
      errors.push({ message: 'result.errors.missingPrKeys', node });
    }
    if (checkSpecialCharacters(data.primaryKeys)) {
      errors.push({ message: 'result.errors.specialCharacters', node });
    }

    return errors;
  }
}

class PivotValidator extends BaseValidator {
  validate(node: ModelEditorNode): ModelEditorValidationError[] {
    const errors = super.validate(node);

    return errors;
  }
}

class UnpivotValidator extends BaseValidator {
  validate(node: ModelEditorNode): ModelEditorValidationError[] {
    const errors = super.validate(node);

    return errors;
  }
}

class UnionValidator extends BaseValidator {
  validate(node: ModelEditorNode): ModelEditorValidationError[] {
    const errors = super.validate(node);

    return errors;
  }
}

class StageValidator {
  private validators: Record<string, BaseValidator> = {
    [ModelEditorNodeType.result]: new ResultValidator(),
    [ModelEditorNodeType.transform]: new TransformValidator(),
    [ModelEditorNodeType.join]: new JoinValidator(),
    [ModelEditorNodeType.groupBy]: new GroupByValidator(),
    [ModelEditorNodeType.union]: new UnionValidator(),
    [ModelEditorNodeType.pivot]: new PivotValidator(),
    [ModelEditorNodeType.unpivot]: new UnpivotValidator(),
    [ModelEditorNodeType.domain]: new DomainValidator(),
    [ModelEditorNodeType.sql]: new CustomSqlValidator(),
  };
  validate(node: ModelEditorNode): ModelEditorValidationError[] {
    const stageValidator = this.validators[node.type] ?? new BaseValidator();
    return stageValidator.validate(node);
  }
}

class Validator {
  nodes: ModelEditor;
  index: Map<string, ModelEditorNodeWithParents>;

  constructor(nodes: ModelEditor) {
    this.nodes = nodes;

    this.index = new Map<string, ModelEditorNodeWithParents>();
    nodes.forEach((node) => this.index.set(node.id, { ...node, parents: this.getParents(nodes, node.id) }));
  }

  private getParents = (nodes: ModelEditor, id: string) =>
    nodes.filter((node) => node.children.includes(id)).map((node) => node.id);

  private findNodesConnectedTo = (node: ModelEditorNode) => {
    const connectedNodes: string[] = [];
    connectedNodes.push(node.id);

    const addConnectedNodes = (id: string) => {
      const children = this.index.get(id)?.children ?? [];
      connectedNodes.push(...children);
      children.forEach((item) => addConnectedNodes(item));
    };

    addConnectedNodes(node.id);

    return connectedNodes;
  };

  private findCycleWith = (node: ModelEditorNode) => {
    const connectedNodes = new Set<string>();

    const addMissingConnectedNodes = (id: string) => {
      const children = this.index.get(id)?.children ?? [];
      children.forEach((item) => {
        if (!connectedNodes.has(item)) {
          connectedNodes.add(item);
          addMissingConnectedNodes(item);
        }
      });
    };

    addMissingConnectedNodes(node.id);

    return connectedNodes.has(node.id) ? Array.from(connectedNodes) : [];
  };

  validate(): ModelEditorValidationError[] {
    const errors: ModelEditorValidationError[] = [];

    // validate Result node(s)
    const results = this.nodes.filter((node) => node.type === ModelEditorNodeType.result);
    if (results.length === 0) {
      errors.push({ message: 'errors.shouldHaveResultNode' });
    }
    if (results.length > 1) {
      errors.push(...results.map((node) => ({ message: 'errors.shouldHaveOnlyOneResultNode', node })));
    }

    const nodesConnectedToResult = results.length === 1 ? this.findNodesConnectedTo(results[0]) : [];

    // validate all nodes connections and types
    this.index.forEach((item) => {
      const incomingConnections = item.children;
      const outgoingConnections = item.parents;
      const config = StageConfig[item.type].validation;

      if (
        config.minIncomingConnections > incomingConnections.length ||
        incomingConnections.length > config.maxIncomingConnections
      ) {
        errors.push({ message: 'errors.wrongIncomingConnectionsNumber', node: item });
      }

      if (
        config.minOutgoingConnections > outgoingConnections.length ||
        outgoingConnections.length > config.maxOutgoingConnections
      ) {
        errors.push({ message: 'errors.wrongOutgoingConnectionsNumber', node: item });
      }

      if (item.parents.some((parent) => !!config.notConnectTo?.includes(this.index.get(parent)?.id ?? ''))) {
        errors.push({ message: 'errors.wrongOutgoingNode', node: item });
      }

      errors.push(
        ...this.findCycleWith(item).map((id) => ({ message: 'errors.nodeIsCycled', node: this.index.get(id) })),
      );

      errors.push(...new StageValidator().validate(item));

      // check only if Result node exists and some outgoing connection is missing
      // to not overwhelm user with error messages
      if (
        results.length === 1 &&
        config.minOutgoingConnections > outgoingConnections.length &&
        !nodesConnectedToResult.includes(item.id)
      ) {
        errors.push({ message: 'errors.notConnectedToResult', node: item });
      }
    });

    return errors;
  }
}

export const validateNodes = (nodes: ModelEditor) => new Validator(nodes).validate();
