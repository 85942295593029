export interface ViewerCHTableInfoData {
  name: string;
  type: string;
}

export type ViewerCHTableInfoResponse = ViewerCHTableInfoData[];

export enum FileFormatExport {
  csv = 'CSV',
  json = 'JSON',
  xlsx = 'XLSX',
  custom = 'Custom',
}

export interface ViewerTracedInfoParams {
  tableId: string;
  rowKey: string;
  targetColumn?: string;
}

export type GroupsDataResponse = Partial<Group>[];

export type Group = {
  id: number;
  name: string;
  description: string;
  creationDate: string;
  userName: string;
  status: string;
  studyId: number;
  selectedTables: (Partial<GroupTable> | string)[];
};

export type GroupTable = {
  description: string;
  domain: string;
  status: string;
  name: string;
  imported: boolean;
  valid: boolean;
  content?: Record<string, any>;
  entityOid?: string;
  id: number;
  skipExtendedAttributes?: boolean;
  traced?: boolean;
};
