import { findNodeById, findSourceNodesByNodeId } from '@modules/modelEditor/components/builder/Utils';
import {
  ModelEditorNodeDomain,
  ModelEditorNodeGroupBy,
  ModelEditorNodeJoin,
  ModelEditorNodePivot,
  ModelEditorNodeSql,
  ModelEditorNodeTransformation,
  ModelEditorNodeType,
  ModelEditorNodeUnion,
  ModelEditorNodeUnionMode,
  ModelEditorNodeUnPivot,
} from '@modules/modelEditor/ModelEditorTypes';
import { useLazyTableInfoQuery } from '@modules/viewer/duck/viewerApi';
import { ViewerCHTableInfoData } from '@modules/viewer/ViewerTypes';
import { selectStudyFallbackCHDB } from '@modules/study/duck/studySelectors';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Edge, Node } from 'reactflow';
import { selectModelEditorEdges, selectModelEditorNodes } from './modelEditorSelectors';

export const useSourceTableInfoAnalyzer = (nodeId: string) => {
  const edges = useSelector(selectModelEditorEdges);
  const nodes = useSelector(selectModelEditorNodes);
  const [loading, setLoading] = useState(true);
  const [sourceColumns, setSourceColumns] = useState<ViewerCHTableInfoData[][]>([]);
  const fallbackCHDB = useSelector(selectStudyFallbackCHDB);
  const [getTableInfo] = useLazyTableInfoQuery();

  useEffect(() => {
    const getAnalyzedSourceColumns = async () => {
      setLoading(true);
      setSourceColumns(await analyzeSourceTableInfo(nodeId, edges, nodes, fallbackCHDB, getTableInfo));
      setLoading(false);
    };

    getAnalyzedSourceColumns();
  }, [nodeId, edges, nodes, fallbackCHDB, getTableInfo]);

  return {
    loading,
    sourceColumns,
    edges,
    nodes,
  };
};

const checkExistedSourceName = (data: ViewerCHTableInfoData[], name: string) => data.some((item) => item.name === name);
const clearGroupByName = (name: string) => {
  const splitedName = name.split('.');
  if (splitedName[1]) {
    return splitedName[1];
  }
  return splitedName[0];
};

const analyzeSourceTableInfo = async (
  targetNodeId: string,
  edges: Edge<any>[],
  nodes: Node<any, string | undefined>[],
  fallbackCHDB: string,
  getTableInfo: ReturnType<typeof useLazyTableInfoQuery>[0],
): Promise<ViewerCHTableInfoData[][]> => {
  const sourceData: ViewerCHTableInfoData[][] = [];

  const sourceNodes = findSourceNodesByNodeId(targetNodeId, edges);

  for (const nodeId of sourceNodes) {
    const node = findNodeById(nodes, nodeId);

    if (node!.type === ModelEditorNodeType.domain) {
      const nodeData = node?.data as ModelEditorNodeDomain;

      let tableInfo: ViewerCHTableInfoData[] = [];

      try {
        tableInfo = await getTableInfo({ tableName: nodeData.tableName!, fallbackCHDB }, true).unwrap();
      } catch (error) {
        console.log(error);
      }

      sourceData.push(tableInfo);
    } else if (node!.type === ModelEditorNodeType.join) {
      const prevSourceResult = await analyzeSourceTableInfo(node!.id, edges, nodes, fallbackCHDB, getTableInfo);

      if (Array.isArray(prevSourceResult[1])) {
        const nodeData = node?.data as ModelEditorNodeJoin;
        const leftFields = prevSourceResult[0] || [];
        const rightFields =
          prevSourceResult[1].reduce((acc, item) => {
            if (nodeData.relations.includes(item.name)) return acc;
            if (checkExistedSourceName(prevSourceResult[0], item.name)) return acc;
            return acc.concat(item);
          }, [] as ViewerCHTableInfoData[]) || [];

        sourceData.push([...leftFields, ...rightFields]);
      } else {
        sourceData.push(...prevSourceResult);
      }
    } else if (node!.type === ModelEditorNodeType.groupBy) {
      const nodeData = node?.data as ModelEditorNodeGroupBy;

      const data: ViewerCHTableInfoData[] = [];

      if (Array.isArray(nodeData.groupBy) && !nodeData.dropGroupingColumns) {
        const groupByColumns = nodeData.groupBy.map((item) => ({ name: clearGroupByName(item), type: 'String' }));
        data.push(...groupByColumns);
      }
      if (Array.isArray(nodeData.aggregateFn)) {
        const aggregatedColumns = nodeData.aggregateFn.map((item) => ({
          name: `${item.operation.toLowerCase()}(${item.variable === '*' ? '1' : clearGroupByName(item.variable)})`,
          type: 'String',
        }));
        data.push(...aggregatedColumns);
      }

      if (data.length > 0) {
        sourceData.push(data);
      } else {
        const prevSourceResult = await analyzeSourceTableInfo(node!.id, edges, nodes, fallbackCHDB, getTableInfo);
        sourceData.push(...prevSourceResult);
      }
    } else if (node!.type === ModelEditorNodeType.transform) {
      const nodeData = node?.data as ModelEditorNodeTransformation;

      if (Array.isArray(nodeData.transformation)) {
        const data = nodeData.transformation
          .filter((item) => !item.deleted)
          .map((item) => ({
            name: item.newName || item.name,
            type: item.type || 'String',
          }));

        sourceData.push(data);
      } else {
        const prevSourceResult = await analyzeSourceTableInfo(node!.id, edges, nodes, fallbackCHDB, getTableInfo);
        sourceData.push(...prevSourceResult);
      }
    } else if (node!.type === ModelEditorNodeType.union) {
      const nodeData = node?.data as ModelEditorNodeUnion;
      const prevSourceResult = await analyzeSourceTableInfo(node!.id, edges, nodes, fallbackCHDB, getTableInfo);

      if (nodeData.mode === ModelEditorNodeUnionMode.byPosition && Array.isArray(prevSourceResult[0])) {
        const leftFields = prevSourceResult[0] || [];

        sourceData.push([...leftFields]);
      } else if (
        nodeData.mode === ModelEditorNodeUnionMode.byName &&
        Array.isArray(prevSourceResult[0]) &&
        Array.isArray(prevSourceResult[1])
      ) {
        const leftFields = prevSourceResult[0];
        const rightFields =
          prevSourceResult[1].reduce((acc, item) => {
            if (checkExistedSourceName(prevSourceResult[0], item.name)) return acc;

            return acc.concat(item);
          }, [] as ViewerCHTableInfoData[]) || [];

        sourceData.push([...leftFields, ...rightFields]);
      } else {
        sourceData.push(...prevSourceResult);
      }
    } else if (node!.type === ModelEditorNodeType.pivot) {
      const nodeData = node?.data as ModelEditorNodePivot;
      const prevSourceResult = await analyzeSourceTableInfo(node!.id, edges, nodes, fallbackCHDB, getTableInfo);

      if (Array.isArray(prevSourceResult[0]) && nodeData.groupingColumns) {
        const fields: ViewerCHTableInfoData[] = [];

        const groupingColumns = prevSourceResult[0].filter(({ name }) => nodeData.groupingColumns.includes(name));

        fields.push(...groupingColumns);

        sourceData.push(fields);
      }
    } else if (node!.type === ModelEditorNodeType.unpivot) {
      const nodeData = node?.data as ModelEditorNodeUnPivot;
      const prevSourceResult = await analyzeSourceTableInfo(node!.id, edges, nodes, fallbackCHDB, getTableInfo);

      if (Array.isArray(prevSourceResult[0])) {
        const fields: ViewerCHTableInfoData[] = [];

        if (nodeData.unchangedColumns) {
          fields.push(...nodeData.unchangedColumns.map((item) => ({ name: item, type: 'Any' })));
        }

        if (nodeData.unpivotNames) {
          fields.push(...nodeData.unpivotNames.map((item) => ({ name: item, type: 'Any' })));
        }

        sourceData.push(fields);
      }
    } else if (node!.type === ModelEditorNodeType.sql) {
      const nodeData = node?.data as ModelEditorNodeSql;
      sourceData.push(nodeData.sql_schema);
    }
  }

  return sourceData;
};
