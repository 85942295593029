import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

export const useAuthSessionListener = () => {
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const sessionReferrer = searchParams.get('referrer');

    if (sessionReferrer) {
      localStorage.setItem('referrer', sessionReferrer);
    }

    if (sessionReferrer) {
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, [dispatch, searchParams]);
};

export const useSetAuthReferrer = () => {
  const setAuthReferrerToSearchParams = useCallback(() => {
    const sessionReferrer = localStorage.getItem('referrer');
    const currentURL = new URL(window.location.href);
    if (sessionReferrer) {
      currentURL.searchParams.set('referrer', sessionReferrer);
    }
    currentURL.searchParams.set('redirect_url', window.location.pathname);
    window.history.replaceState({}, document.title, decodeURIComponent(currentURL.href));
  }, []);

  return {
    setAuthReferrerToSearchParams,
  };
};
