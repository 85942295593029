import { ModelEditorSchemaEditorItem } from '@modules/modelEditor/ModelEditorTypes';

export const parseInputName = (inputName?: string) => {
  if (typeof inputName === 'string') {
    const [index, name] = inputName.split(':');

    return { index: +index, name };
  }
  return {};
};

export const exportSchemaData = (data: ModelEditorSchemaEditorItem[]): ModelEditorSchemaEditorItem[] => {
  return data.map((item) => {
    return Object.entries(item).reduce((acc, [key, value]) => {
      if (key === 'name' && !value) {
        return acc;
      }
      if (value === '' || key === 'index') {
        return acc;
      }
      return { ...acc, [key]: value };
    }, {});
  }) as ModelEditorSchemaEditorItem[];
};
