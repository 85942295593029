export const DATASTAGE_PREFIX = 'datastage-';
const CDR_PREFIX = 'reports-';

export const getFormattedDnaPlatformUrl = () => {
  const { origin } = window.location;
  const isLocalhost = origin.includes('localhost');

  if (isLocalhost) {
    return 'https://reports-staging3.redcapcloud.com/';
  }

  return origin.includes(DATASTAGE_PREFIX) ? origin.replace(DATASTAGE_PREFIX, CDR_PREFIX) : `${CDR_PREFIX}${origin}`;
};

export const isValidStudyId = (id?: string | number) => {
  if (typeof id === 'string') {
    return Number.isNaN(Number(id)) === false;
  }
  if (typeof id === 'number') {
    return true;
  }
  return false;
};

export const getStudyPathName = (studyId: number) => (studyId === 0 ? 'crossStudy' : 'study');
