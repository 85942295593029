import { Button, Checkbox, Form, FormItem, notification, Select, Table, Tooltip } from '@ui';
import { MappingProps } from '@modules/job/JobTypes';
import { DiscoveryJobResponse } from '@modules/job/duck/jobApi';
import { ReferenceTableInfo } from '@modules/viewer/duck/viewerApi';
import { SKIP_COLUMN } from '@modules/job/duck/constants';
import { TFunction } from 'i18next';
import { TableColumnsType } from 'antd';
import { useMemo } from 'react';
import { CSSObject } from '@emotion/react';
import { InfoCircleOutlined } from '@ant-design/icons';

export const DiscoverTab = ({
  tab,
  t,
  referenceTables,
  refTableInfo,
  mappingData,
  updateMappingData,
  referenceTablesLoading,
  referenceTablesInfoLoading,
  handleTabConfirm,
  form,
}: DiscoverTabProps) => {
  const refTablesOption = referenceTables?.map((el) => ({ label: el.split('.')[1], value: el })) ?? [];
  const mappingTabData = mappingData?.find((el) => el.tableName === tab.name);
  const tableData = mappingTabData?.mapping || [];
  const tabReferenceTable = mappingTabData?.referenceTable;
  const isDisableConfirmMapping =
    tableData.length === 0 || mappingTabData?.isError || tableData.some((el) => !el.sourceColumn);

  const targetColumnsOptions = useMemo(
    () =>
      [{ label: '<skip>', value: SKIP_COLUMN }].concat(
        Object.keys(tab.structure)?.map((el) => ({
          label: el,
          value: el,
        })),
      ),
    [tab.structure],
  );

  const handlesTargetColumnChange = (value: string, record: any) => {
    if (value) {
      const updatedMappingTable = tableData.map((el) =>
        el.targetColumn === record.targetColumn
          ? {
              ...el,
              sourceColumn: value,
            }
          : el,
      );
      updateMappingData(tab.name, { mapping: updatedMappingTable, confirmed: false, isError: false });
    }
  };

  const columns: TableColumnsType<MappingProps['mapping'][0]> = useMemo(
    () => [
      {
        width: 250,
        key: 'targetColumn',
        dataIndex: 'targetColumn',
        title: t('uploadRT.mapping.targetColumn'),
        render: (value, record) => (
          <>
            {value}
            {record.description && (
              <Tooltip css={cssTooltipIcon} title={record.description}>
                <InfoCircleOutlined />
              </Tooltip>
            )}
          </>
        ),
      },
      {
        width: 250,
        key: 'sourceColumn',
        dataIndex: 'sourceColumn',
        title: t('uploadRT.mapping.sourceColumn'),
        render: (_, record) => {
          return (
            <FormItem
              css={cssTableInput}
              name={`${tab.name}.${tabReferenceTable}_sourceColumn_${record.targetColumn}`}
              label={null}
              rules={[{ required: true, message: '' }]}
            >
              <Select
                style={{ width: '100%', maxWidth: '300px' }}
                options={targetColumnsOptions}
                onChange={(value) => handlesTargetColumnChange(value, record)}
                disabled={!tabReferenceTable}
              />
            </FormItem>
          );
        },
      },
      { width: 100, key: 'type', dataIndex: 'type', title: t('uploadRT.mapping.type') },
      { width: 80, key: 'length', dataIndex: 'length', title: t('uploadRT.mapping.length'), align: 'center' },
      {
        width: 60,
        key: 'nullable',
        dataIndex: 'nullable',
        title: t('uploadRT.mapping.nullable'),
        align: 'center',
        render: (_, record) => <Checkbox checked={record.nullable} disabled />,
      },
      {
        width: 60,
        key: 'primaryKey',
        dataIndex: 'primaryKey',
        title: t('uploadRT.mapping.primaryKey'),
        align: 'center',
        render: (_, record) => <Checkbox checked={record.primaryKey} disabled />,
      },
    ],
    [targetColumnsOptions, tabReferenceTable, tab.name, handlesTargetColumnChange],
  );

  const onChangeRefTable = async (table: string) => {
    try {
      const referenceTableInfo = await refTableInfo(table).unwrap();

      const fieldName = `${tab.name}.${table}_sourceColumn`;
      const tableInfoData = referenceTableInfo?.map((el: ReferenceTableInfo) => {
        const valueFromForm = form.getFieldValue(`${fieldName}_${el.name}`);

        return {
          targetColumn: el.name,
          sourceColumn: valueFromForm || '',
          description: el.description,
          type: el.type,
          length: el.length,
          nullable: el.is_nullable,
          primaryKey: el.is_primary_key,
        };
      });
      updateMappingData(tab.name, { referenceTable: table, mapping: tableInfoData, isError: false, confirmed: false });
    } catch (e) {
      console.error(e);
      notification.error({ message: t('uploadRT.errors.errorInfo') });
      updateMappingData(tab.name, { referenceTable: table, mapping: [], isError: false, confirmed: false });
    }
  };

  return (
    <>
      <div css={cssTabHeaderContainer}>
        <FormItem
          style={{ flex: 1 }}
          name={`${tab.name}.referenceTable`}
          label={t('uploadRT.referenceTable')}
          rules={[{ required: true }]}
          labelCol={{ span: 6 }}
        >
          <Select
            value={tabReferenceTable}
            placeholder={t('uploadRT.referenceTablePlaceholder')}
            options={refTablesOption}
            onChange={onChangeRefTable}
            loading={referenceTablesLoading}
          />
        </FormItem>

        <Button
          type="primary"
          disabled={isDisableConfirmMapping}
          onClick={() => {
            handleTabConfirm(tab.name);
          }}
        >
          {t('uploadRT.confirmTabBtn')}
        </Button>
      </div>
      <FormItem name="mapping" wrapperCol={{ span: 24 }}>
        <Table
          loading={referenceTablesInfoLoading}
          css={cssTable}
          key="mappingTable"
          rowKey="discoverColumn"
          size="small"
          bordered
          columns={columns}
          dataSource={tableData}
          pagination={false}
          tableLayout="auto"
          scroll={{ y: 250 }}
        />
      </FormItem>
    </>
  );
};
const cssTable = (): CSSObject => ({
  height: '300px',
  maxHeight: '300px',
  width: '100%',
  minWidth: 'inherit',

  '&&&& td.ant-table-cell': {
    padding: '4px',
  },
});

const cssTooltipIcon = (): CSSObject => ({
  color: 'grey',
  marginLeft: 4,
});

const cssTableInput = (): CSSObject => ({
  marginBottom: 0,
});

const cssTabHeaderContainer = (): CSSObject => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'start',
});

interface DiscoverTabProps {
  tab: DiscoveryJobResponse;
  t: TFunction;
  mappingData: MappingProps[];
  updateMappingData: (val: string, obj: Record<string, any>) => void;
  referenceTables: string[];
  refTableInfo: any;
  referenceTablesLoading: boolean;
  referenceTablesInfoLoading: boolean;
  handleTabConfirm: (val: string) => void;
  form: ReturnType<typeof Form.useForm>[0];
}
