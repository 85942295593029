import { DraggableModal, notification, Typography } from '@ui';
import { ELibraryEntityNames, ELibrarySourceType } from '@modules/library/root/LibraryTypes';
import {
  useLazyLibraryCdrListQuery,
  useLibraryCopyCdrMutation,
  useUsedCDRMutation,
} from '@modules/library/cdr/duck/libraryCdrApi';
import { ICascadeObjectsTableData, LibraryCascadeObjects } from '@shared/components/CascadeObjects';
import { useCdrColumns } from '@modules/library/cdr/duck/libraryCdrHooks';
import { useLazyEnvCDRListQuery } from '@modules/library/root/duck/libraryApi';
import { LibraryStatus } from '@modules/library/root/duck/libraryConstants';
import { ConfirmModal, useConfirmModal } from '@components';
import { QueryErrorType } from '@shared/utils/Error';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useState } from 'react';
import {
  getLibraryImportCDRFromLibrarySource,
  ILibraryImportCDRProps,
  LibraryImportCDR,
  WrapperFn,
} from '../../components/LibraryImportCDR';
import { prepareLibraryCDR } from '../../duck/libraryCdrUtils';

const LibraryCdrModalsCopyCdrContent = ({ t, onClose }: LibraryCdrModalsCopyCdrContentContentProps) => {
  const { cdrColumns, locale } = useCdrColumns();
  const [libraryCdrListQuery, libraryCdrListQueryData] = useLazyLibraryCdrListQuery();
  const [studyCdrListQuery, studyCdrListQueryData] = useLazyEnvCDRListQuery();
  const [copyCdr] = useLibraryCopyCdrMutation();
  const [usedCDR] = useUsedCDRMutation();
  const confirmModal = useConfirmModal();

  const [isCascade, setIsCascade] = useState(false);
  const [selectedCascadeObjects, setSelectedCascadeObjects] = useState<ICascadeObjectsTableData[]>([]);
  const [isUsedError, setIsUsedError] = useState(false);

  const wrapperLibrary: WrapperFn = async (id: number) => {
    const data = await libraryCdrListQuery({ library_id: id, detailed: '1' }).unwrap();
    return getLibraryImportCDRFromLibrarySource(data.items, ELibrarySourceType.Library);
  };

  const wrapperStudy: WrapperFn = async (id, env) => {
    const data = await studyCdrListQuery({ study_id: id, env: env! }).unwrap();
    return getLibraryImportCDRFromLibrarySource(data, ELibrarySourceType.Study);
  };

  const onImport: ILibraryImportCDRProps['onImport'] = async (values, { kind, overwrite, systemInfo }) => {
    if (overwrite) {
      try {
        await usedCDR({ cdr_names: values.map((item) => item.name) }).unwrap();

        await copyCdr({ data: prepareLibraryCDR(values, kind, systemInfo), overwrite: overwrite }).unwrap();
      } catch (error) {
        const { data } = error as QueryErrorType;
        const isError = typeof data.rawData?.error === 'string';
        setIsUsedError(isError);

        confirmModal.openConfirmation({
          content: isError ? (
            <Typography.Text type="danger">{data.rawData?.error}</Typography.Text>
          ) : (
            <div>
              {t('confirmOverwrite.content')}
              <strong>{(Object.keys(data.rawData?.error) || []).join(', ')}</strong>
            </div>
          ),
          data: prepareLibraryCDR(values, kind, systemInfo),
          cascadeObjects: !isError ? data.rawData?.error : {},
        });
        // TODO Refactor this stuff later
        // eslint-disable-next-line no-throw-literal
        throw '';
      }
    } else {
      await copyCdr({ data: prepareLibraryCDR(values, kind, systemInfo), overwrite: overwrite }).unwrap();
    }
  };

  const onOverwriteConfirm = async (data: any) => {
    try {
      const processSelectedCascadeObjects = selectedCascadeObjects.filter((el) => !el.children);
      await copyCdr({ data, cascade_update: processSelectedCascadeObjects, overwrite: true }).unwrap();
      onClose();
    } catch (error) {
      notification.error({ message: (error as QueryErrorType).data.userMsg });
    }
  };

  const clearCascadeData = () => {
    setIsCascade(false);
    setSelectedCascadeObjects([]);
  };

  return (
    <>
      <LibraryImportCDR
        onClose={onClose}
        columns={cdrColumns}
        locale={locale}
        kind={ELibraryEntityNames.CDR}
        libraryTableListQuery={wrapperLibrary}
        studyTableListQuery={wrapperStudy}
        tableDataFetching={libraryCdrListQueryData.isFetching || studyCdrListQueryData.isFetching}
        onImport={onImport}
        libraryStatuses={[LibraryStatus.Active, LibraryStatus.Development]}
      />
      <ConfirmModal
        title={t('confirmOverwrite.content')}
        submitFunc={onOverwriteConfirm}
        {...confirmModal}
        closeConfirmation={() => {
          clearCascadeData();
          confirmModal.closeConfirmation();
        }}
        okButtonProps={{ disabled: isUsedError }}
      >
        {!isUsedError && (
          <LibraryCascadeObjects
            data={confirmModal.confirmState?.cascadeObjects}
            isCascade={isCascade}
            setIsCascade={setIsCascade}
            setSelectedCascadeObjects={setSelectedCascadeObjects}
          />
        )}
      </ConfirmModal>
    </>
  );
};

export const LibraryCdrModalsCopyCdrReport = ({ open, data, onClose }: ILibraryCdrModalsCopyCdrProps) => {
  const { t } = useTranslation(['libraryCdr']);

  return (
    <DraggableModal width="50%" open={open} onCancel={onClose} title={t('copyForm.title')} footer={null} destroyOnClose>
      {open && <LibraryCdrModalsCopyCdrContent data={data} onClose={onClose} t={t} />}
    </DraggableModal>
  );
};

export interface ILibraryCdrModalsCopyCdrProps {
  open: boolean;
  data: any;
  onClose: () => void;
}

interface LibraryCdrModalsCopyCdrContentContentProps extends Pick<ILibraryCdrModalsCopyCdrProps, 'data' | 'onClose'> {
  t: TFunction;
}
