import { selectAppInnerSiderWidth } from '@app/duck/appSelectors';
import { appActions } from '@app/duck/appSlice';
import { configuration } from '@config/constants';
import { CSSObject, Theme } from '@emotion/react';
import { ReactNode, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const InternalSider = ({ children, adjustable = true }: InternalSiderProps) => {
  const dispatch = useDispatch();
  const innerSiderWidth = useSelector(selectAppInnerSiderWidth);
  const refBody = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  const refDimentions = useRef({
    prevX: 0,
    prevWidth: innerSiderWidth,
    width: innerSiderWidth,
  });

  useEffect(() => {
    if (adjustable) {
      const currentRef = ref.current;
      const mouseDownEvent = (event: MouseEvent) => {
        refDimentions.current.prevX = event.clientX;
        refDimentions.current.prevWidth = refBody.current?.getBoundingClientRect()?.width || innerSiderWidth;
        document?.addEventListener('mousemove', mouseMoveEvent);
        document?.addEventListener('mouseup', mouseUpEvent);
      };
      const mouseMoveEvent = (event: MouseEvent) => {
        const width = refDimentions.current.prevWidth + (event.clientX - refDimentions.current.prevX);
        const expectedMaxWidth = window.innerWidth * 0.5;
        const maxWidth =
          expectedMaxWidth < configuration.defaultInnerSiderWidth
            ? configuration.defaultInnerSiderWidth
            : expectedMaxWidth;
        refDimentions.current.width = width > maxWidth ? maxWidth : width;
        if (refBody.current) {
          refBody.current.style.width = `${refDimentions.current.width}px`;
        }
      };
      const mouseUpEvent = (event: MouseEvent) => {
        document?.removeEventListener('mousemove', mouseMoveEvent);
        document?.removeEventListener('mouseup', mouseUpEvent);
        dispatch(appActions.setInnerSiderWidth(refDimentions.current.width));
      };
      currentRef?.addEventListener('mousedown', mouseDownEvent);

      return () => {
        currentRef?.removeEventListener('mousedown', mouseDownEvent);
        document?.removeEventListener('mousemove', mouseMoveEvent);
        document?.removeEventListener('mouseup', mouseUpEvent);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      css={cssBody}
      ref={refBody}
      style={{ width: adjustable ? innerSiderWidth : configuration.defaultInnerSiderWidth }}
    >
      <div css={cssContent}>{children}</div>
      {adjustable && (
        <div css={cssAdjustSide} ref={ref}>
          <div css={cssLine} />
        </div>
      )}
    </div>
  );
};

interface InternalSiderProps {
  adjustable?: boolean;
  children: ReactNode;
}

const cssBody = (theme: Theme): CSSObject => ({
  flex: '0 0 auto',
  position: 'relative',
  backgroundColor: theme.colorBorderSecondary,
  color: theme.colorTextBase,
  borderRight: `1px solid ${theme.colorBorder}`,
});

const cssContent = (theme: Theme): CSSObject => ({
  width: '100%',
  height: '100%',
  '& .ant-layout-sider-children': {
    paddingTop: theme.paddingSM,
    textAlign: 'center' as 'center',
  },
  overflowY: 'auto',
  overflowX: 'hidden',
});

const cssAdjustSide = (theme: Theme): CSSObject => ({
  position: 'absolute',
  userSelect: 'none',
  zIndex: '1',
  right: '-24px',
  top: '0',
  width: '20px',
  height: '100%',
  opacity: '0',
  transition: '0.3s all',
  cursor: 'ew-resize',
  '&:hover': {
    opacity: '1',
  },
});

const cssLine = (theme: Theme): CSSObject => ({
  marginLeft: '-4px',
  width: '2px',
  height: '100%',
  background: theme.colorPrimaryActive,
});
