import { DiscoveryJobResponse } from '@modules/job/duck/jobApi';
import { MappingProps } from '@modules/job/JobTypes';
import { Button, Checkbox, FormItem, Select, Table } from '@ui';
import { COLUMN_TYPES } from '@modules/job/duck/constants';
import { TFunction } from 'i18next';
import { useMemo } from 'react';
import { TableColumnsType } from 'antd';
import { CSSObject } from '@emotion/react';

interface DiscoverTabProps {
  tab: DiscoveryJobResponse;
  t: TFunction;
  mappingData: MappingProps[];
  updateMappingData: (val: string, obj: Record<string, any>) => void;
  handleTabConfirm: (val: string) => void;
}

export const DiscoverTabManual = ({ tab, t, mappingData, updateMappingData, handleTabConfirm }: DiscoverTabProps) => {
  const mappingTabData = mappingData?.find((el) => el.tableName === tab.name);
  const tableData = mappingTabData?.mapping || [];

  const isDisableConfirmMapping = mappingTabData?.isError;

  const typeOptions = Object.entries(COLUMN_TYPES).map(([key, value]) => ({ label: value, value: key }));

  const handlesColumnChange = (record: any, field: string, value?: string) => {
    const updatedMappingTable = tableData.map((el) =>
      el.sourceColumn === record.sourceColumn
        ? {
            ...el,
            [field]: value || !record[field],
          }
        : el,
    );
    updateMappingData(tab.name, { mapping: updatedMappingTable, confirmed: false, isError: false });
  };

  const columns: TableColumnsType<MappingProps['mapping'][0]> = useMemo(
    () => [
      {
        width: 250,
        key: 'sourceColumn',
        dataIndex: 'sourceColumn',
        title: t('uploadRT.mapping.sourceColumn'),
      },
      {
        width: 100,
        key: 'type',
        dataIndex: 'type',
        title: t('uploadRT.mapping.type'),
        render: (_, record) => {
          return (
            <FormItem
              name={[tab.name, 'structure', record.sourceColumn]}
              wrapperCol={{ span: 24 }}
              css={cssTableInput}
              label={null}
              rules={[{ required: true, message: '' }]}
            >
              <Select options={typeOptions} onChange={(value) => handlesColumnChange(record, 'type', value)} />
            </FormItem>
          );
        },
      },
      {
        width: 60,
        key: 'nullable',
        dataIndex: 'nullable',
        title: t('uploadRT.mapping.nullable'),
        align: 'center',
        render: (_, record) => (
          <Checkbox checked={record.nullable} onChange={() => handlesColumnChange(record, 'nullable')} />
        ),
      },
      {
        width: 60,
        key: 'primaryKey',
        dataIndex: 'primaryKey',
        title: t('uploadRT.mapping.primaryKey'),
        align: 'center',
        render: (_, record) => (
          <Checkbox checked={record.primaryKey} onChange={() => handlesColumnChange(record, 'primaryKey')} />
        ),
      },
    ],
    [tab.name, typeOptions],
  );

  return (
    <>
      <div css={cssTabHeaderContainer}>
        <Button
          type="primary"
          disabled={isDisableConfirmMapping}
          onClick={() => {
            handleTabConfirm(tab.name);
          }}
        >
          {t('uploadRT.confirmTabBtn')}
        </Button>
      </div>
      <Table
        css={cssTable}
        key={`${tab.name}_mappingTable`}
        rowKey="discoverColumn"
        size="small"
        bordered
        columns={columns}
        dataSource={tableData}
        pagination={false}
        tableLayout="auto"
        scroll={{ y: 250 }}
      />
    </>
  );
};

const cssTable = (): CSSObject => ({
  height: '300px',
  maxHeight: '300px',
  width: '100%',
  minWidth: 'inherit',

  '&&&& td.ant-table-cell': {
    padding: '4px',
  },
});

const cssTableInput = (): CSSObject => ({
  marginBottom: 0,
  width: '100%',
});

const cssTabHeaderContainer = (): CSSObject => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'right',
  marginBottom: '16px',
});
