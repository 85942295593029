export interface Study {
  summary: string;
  uniqueIdentifier: string;
  studyCategory: string;
  userCreated: string;
  creationDate: number;
  description: string;
  name: string;
  official_title: string;
  protocol_id: string;
  sponsor: string;
  studyStatus: 'active';
  id: number;
  update_by: number;
  update_date: string;
  use_sites: 'true' | 'false';
  studyVersion: string;
  protocol: string | null;
}

export type StudyListResponse = Array<{
  creationDate: number;
  exportInProgress: boolean;
  hasStudyNotifications: boolean;
  id: number;
  isEConsentStudy: boolean;
  isSharedStudy: boolean;
  name: string;
  principalInvestigator: string;
  protocol: string | null;
  study_schema: string;
  sharedRead: boolean;
  sharedWrite: boolean;
  studyCategory: string;
  studyStatus: string;
  studyVersion: string;
  summary: string;
  uniqueIdentifier: string;
  userCreated: string;
  configured: boolean;
}>;

export interface StudyDetails {
  studyVersionId: number;
  uniqueIdentifier: string;
  name: string;
  officialTitle: string;
  secondaryIdentifier: string;
  principalInvestigator: string;
  summary: string;
  sponsor: string;
  collaborators: string;
  oid: string;
  adLookupCodeByStatusId: number;
  studyCategory: string;
  screeningStartDateValue: string;
  protocolId: string;
  protocolDescription: string;
  ageMin: number;
  ageMax: number;
  isNotUsingSites: false;
  copyFromTemplate: false;
  settings: Record<string, any>;
  id: number;
}

export interface StudyContextResponse {
  id: number;
  name: string;
  protocol_id?: string;
  study_schema: string;
  configured: boolean;
}

export enum ESiderType {
  Study = 'Study',
  AnalysisObject = 'AnalysisObject',
}

export interface SchemaTableColumn {
  name: string;
  type: string;
}

export interface SchemaTable {
  name: string;
  schema: SchemaTableColumn[];
}

export interface SchemaTables {
  tables: SchemaTable[];
  views: SchemaTable[];
}
