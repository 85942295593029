export const SKIP_COLUMN = 'skip';

export const INITIAL_PROGRESS = { progress: 0, step: 0 };

export enum COLUMN_TYPES {
  Int32 = 'Int32',
  Int64 = 'Int64',
  Float32 = 'Float32',
  Float64 = 'Float64',
  String = 'String',
  Boolean = 'Boolean',
  Date = 'Date',
  DateTime = 'DateTime',
}
