import { Skeleton, Space, Typography } from '@ui';
import { selectGlobalLibrary, selectGlobalStudy } from '@app/duck/appSelectors';
import { css, CSSObject, Theme } from '@emotion/react';
import { useSelector } from 'react-redux';
import { APP_HEADER_CUSTOM_TOOLBAR, APP_HEADER_ID, APP_HEADER_LEFT_ID } from './AppHeaderContants';
import { AppUserButton } from '../AppUserButton';
import { NotificationsButton } from '../Notifications';

const AppContextTitle = ({ title }: { title?: string }) => {
  if (!title) return <Skeleton active css={cssAppContextTitle} paragraph={{ rows: 1, width: 150 }} title={false} />;

  return <Typography.Text type="secondary" children={title} />;
};

export const AppHeader = () => {
  const globalStudy = useSelector(selectGlobalStudy);
  const globalLibrary = useSelector(selectGlobalLibrary);

  return (
    <Space css={cssBody} full justify="space-between">
      <Space>
        {(globalStudy || globalLibrary) && <AppContextTitle title={globalStudy?.name || globalLibrary?.name} />}
        <div css={cssMenu} id={APP_HEADER_LEFT_ID}></div>
      </Space>
      <Space>
        <div css={cssCustomToolbar} id={APP_HEADER_CUSTOM_TOOLBAR} />
        <Space>
          <NotificationsButton />
          <div css={cssMenu} id={APP_HEADER_ID}></div>
          <AppUserButton />
        </Space>
      </Space>
    </Space>
  );
};

const cssBody = (theme: Theme): CSSObject => ({
  padding: `0 ${theme.paddingLG}px`,
  backgroundColor: theme.colorBgBase,
  height: '100%',
  borderBottom: `1px solid ${theme.colorBorder}`,
});

const cssMenu = (theme: Theme): CSSObject => ({
  display: 'flex',
  gap: theme.paddingXS,
});

const cssAppContextTitle = css({
  '& .ant-skeleton-paragraph': {
    marginBottom: 0,
  },
});

const cssCustomToolbar = css({
  display: 'flex',
  gap: 12,
});
