import { Spin } from '@ui';
import { CSSObject } from '@emotion/react';
import { SpinSize } from 'antd/lib/spin';

interface LoaderProps {
  mode: 'fixed' | 'default' | 'absolute';
  size?: SpinSize;
}

export const Loader = ({ mode, size }: LoaderProps) => {
  if (mode === 'absolute') {
    return <Spin css={cssLoaderAbsolute} spinning size={size} />;
  }

  if (mode === 'fixed') {
    return <Spin css={cssLoaderFixed} spinning size={size} />;
  }

  return <Spin spinning size={size} />;
};

const cssLoaderFixed = (): CSSObject => ({
  zIndex: 999,
  top: 0,
  left: 0,
  position: 'fixed',
  width: '100vw',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const cssLoaderAbsolute = (): CSSObject => ({
  zIndex: 999,
  position: 'absolute' as const,
  left: '50%',
  top: '50%',
  marginLeft: '-10px',
  marginTop: '-13px',
});
