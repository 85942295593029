import {
  useCrossStudyRTListQuery,
  useExternalTablesQuery,
  useGroupsQuery,
  useStudyRTListQuery,
} from '@modules/viewer/duck/viewerApi';
import { viewerActions, ViewerGroup, ViewerGroupType } from '@modules/viewer/duck/viewerSlice';
import { selectExpandedGroups, selectViewerGroups } from '@modules/viewer/duck/viewerSelectors';
import { DEFAULT_EXTERNAL_STORES, EXTERNAL_STORES } from '@modules/stores/duck/storeConstants';
import { applySchemaToTable, getTableNameWithSchema } from '@shared/utils/Viewer';
import { useStoreListQuery } from '@modules/stores/duck/storeApi';
import { selectGlobalStudy } from '@app/duck/appSelectors';
import { selectStudyFallbackCHDB } from '@modules/study/duck/studySelectors';
import { ModelEditorNodeType } from '@modules/modelEditor/ModelEditorTypes';
import { getOnMouseClickCoordinates } from '@modules/modelEditor/components/builder/Utils';
import { IAppStudyContext } from '@app/AppTypes';
import { css } from '@emotion/react';
import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataNode, DirectoryTreeProps, TreeProps } from 'antd/es/tree';
import { HolderOutlined } from '@ant-design/icons';

const storeTablesFallback: ViewerGroup[] = [];
const StudyRTStoreName = 'Reference Table';

export const useTableListSider = ({
  selectedTable,
  defaultTableName,
  onSelectTable,
  globalStudy,
  treeData,
}: IUseTaleListSiderProps) => {
  const dispatch = useDispatch();

  const expandedGroups = useSelector(selectExpandedGroups(globalStudy!.id));

  const defaultTableNameProcessed = useRef(false);

  const selectedKeys = useMemo(() => (selectedTable ? [selectedTable] : []), [selectedTable]);

  useEffect(() => {
    if (defaultTableName && !defaultTableNameProcessed.current) {
      let defaultTable: DataNode | undefined;

      treeData?.some((parent) =>
        parent.children?.some((child) => {
          if (child.key.toString().match(new RegExp(`[.:]${defaultTableName}$`))) {
            defaultTable = { key: child.key, title: child.title };
            return true;
          }
          return false;
        }),
      );

      if (defaultTable) {
        const key = parseInt((defaultTable['key'] as string).split(':').at(0) ?? '0', 10);

        if (key && !expandedGroups.includes(key)) {
          dispatch(viewerActions.updateExpanded({ studyId: globalStudy!.id, keys: [...expandedGroups, key] }));
        }

        defaultTableNameProcessed.current = true;
        onSelectTable(defaultTable.key as string, defaultTable.title as string);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTableName, treeData, expandedGroups]);

  const onDragStart: TreeProps['onDragStart'] = (info) => {
    const { event, node } = info;
    if (node.isLeaf) {
      event.dataTransfer.setData('application/reactflow', ModelEditorNodeType.domain);
      event.dataTransfer.setData('application/reactflow/name', node.title?.toString()!);
      event.dataTransfer.setData('application/reactflow/tableName', (node as any).originalId);
      event.dataTransfer.setData(
        'application/reactflow/mouse_click_coordinates',
        JSON.stringify(getOnMouseClickCoordinates(event)),
      );
      event.dataTransfer.effectAllowed = 'move';
    } else {
      info.event.preventDefault();
    }
  };

  const onSelect: DirectoryTreeProps['onSelect'] = (keys, info) => {
    if (info.node.isLeaf) {
      onSelectTable(info.node.key as string, info.node.title as string);
    }
  };

  const onExpand: TreeProps['onExpand'] = (keys: Array<string | number>) =>
    dispatch(viewerActions.updateExpanded({ studyId: globalStudy!.id, keys: keys ?? [] }));

  return {
    expandedGroups,
    selectedKeys,
    onDragStart,
    onSelect,
    onExpand,
  };
};

export const useStoreAggregation = (draggable?: boolean) => {
  const dispatch = useDispatch();
  const globalStudy = useSelector(selectGlobalStudy);
  const fallbackCHDB = useSelector(selectStudyFallbackCHDB);
  const {
    rccGroups: rccGroupsTables = storeTablesFallback,
    internal: internalTables = storeTablesFallback,
    external: externalTables = storeTablesFallback,
    studyRT: studyRTTables = storeTablesFallback,
  } = useSelector(selectViewerGroups(globalStudy!.id));

  const storeQuery = useStoreListQuery({ detailed: 0 });
  const studyRTQuery = useStudyRTListQuery();
  const externalTablesQuery = useExternalTablesQuery();
  const rccGroupsQuery = useGroupsQuery();

  // Set External Data
  useEffect(() => {
    let data: ViewerGroup[] = [];

    if (externalTables?.length && !externalTablesQuery.data) {
      return;
    }

    const currentData = (externalTablesQuery.data ||
      Object.entries(DEFAULT_EXTERNAL_STORES).reduce(
        (acc, [key, value]) => ({ ...acc, [key]: value.tables }),
        {},
      )) as Record<EXTERNAL_STORES, string[]>;

    data = data.concat(
      Object.entries(currentData).map(([storeName, tables], index) => ({
        id: DEFAULT_EXTERNAL_STORES[storeName as EXTERNAL_STORES].id,
        name: storeName,
        tables: tables.map((tableName) => ({
          id: tableName,
          name: getTableNameWithSchema(tableName).name || tableName,
        })),
      })),
    );

    dispatch(viewerActions.setGroup({ studyId: globalStudy!.id, group: 'external', data }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalTablesQuery.data, globalStudy!.id]);

  // Set RCC Group Data
  useEffect(() => {
    if (rccGroupsQuery.data && fallbackCHDB) {
      const data: ViewerGroup[] = (rccGroupsQuery.data || []).map((group, index) => ({
        id: -group.id! - 10, // to not conflict with data stores and default data stores
        name: group.name!,
        tables: group?.selectedTables?.map((table) =>
          typeof table === 'string'
            ? { id: table, name: table.split('.')[1] }
            : {
                id: `${fallbackCHDB}.${table.name}`,
                name: table.name!,
              },
        ),
      }));
      dispatch(viewerActions.setGroup({ studyId: globalStudy!.id, group: 'rccGroups', data }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, fallbackCHDB, globalStudy!.id, rccGroupsQuery.data]);

  // Set Internal Data
  useEffect(() => {
    if (storeQuery.data) {
      const withoutSystemStores = (storeQuery.data || []).filter((store) => ![StudyRTStoreName].includes(store.name));
      const data: ViewerGroup[] = withoutSystemStores.map((store, index) => ({
        id: store.id,
        name: store.name,
        tables: store.tables.map((tableName) => ({
          id: tableName,
          name: getTableNameWithSchema(tableName).name || tableName,
        })),
      }));

      dispatch(viewerActions.setGroup({ studyId: globalStudy!.id, group: 'internal', data }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeQuery.data, globalStudy!.id]);

  // Set Study Reference Tables
  useEffect(() => {
    if (studyRTQuery.data && storeQuery.data) {
      const systemRTStore = (storeQuery.data || []).filter((store) => store.name === StudyRTStoreName);

      const data: ViewerGroup[] = systemRTStore.map((store, index) => ({
        id: store.id,
        name: store.name,
        tables: (studyRTQuery.data || []).map((table) => {
          const tableName = applySchemaToTable(table, fallbackCHDB);

          return {
            id: tableName,
            name: getTableNameWithSchema(tableName).name || tableName,
          };
        }),
      }));

      dispatch(viewerActions.setGroup({ studyId: globalStudy!.id, group: 'studyRT', data }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeQuery.data, studyRTQuery.data, globalStudy!.id]);

  const treeData: ExtendedNodeData[] = useMemo(() => {
    const getTreeData = (data: ViewerGroup[], groupType: ViewerGroupType) =>
      data.map((store) => ({
        title: store.name,
        key: store.id,
        groupType,
        children: (store.tables || []).map((table) => ({
          key: `${store.id}:${table.id}`,
          originalId: table.id,
          title: table.name,
          isLeaf: true,
          icon: draggable ? <HolderOutlined css={cssDraggableIcon} /> : <React.Fragment />,
        })),
      }));

    return [
      ...getTreeData(internalTables, 'internal'),
      ...getTreeData(studyRTTables, 'studyRT'),
      ...getTreeData(externalTables, 'external'),
      ...getTreeData(rccGroupsTables, 'rccGroups'),
    ];
  }, [draggable, rccGroupsTables, internalTables, externalTables, studyRTTables]);

  return { treeData, storeQuery, externalTablesQuery, rccGroupsQuery, globalStudy };
};

export const useCrossStoreAggregation = (draggable?: boolean) => {
  const dispatch = useDispatch();
  const globalStudy = useSelector(selectGlobalStudy);
  const { crossStudyRT: crossStudyRTTables = storeTablesFallback } = useSelector(selectViewerGroups(globalStudy!.id));

  const crossRTListQuery = useCrossStudyRTListQuery();
  const storeQuery = useStoreListQuery({ detailed: 0 });

  // Set Cross Study Reference Tables
  useEffect(() => {
    if (crossRTListQuery.data && storeQuery.data) {
      const systemRTStore = (storeQuery.data || []).filter((store) => store.name === StudyRTStoreName);

      const data: ViewerGroup[] = systemRTStore.map((store, index) => ({
        id: store.id,
        name: store.name,
        tables: (crossRTListQuery.data || []).map((tableName) => ({
          id: tableName,
          name: getTableNameWithSchema(tableName).name || tableName,
        })),
      }));

      dispatch(viewerActions.setGroup({ studyId: globalStudy!.id, group: 'crossStudyRT', data }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeQuery.data, crossRTListQuery.data, globalStudy!.id]);

  const treeData: ExtendedNodeData[] = useMemo(() => {
    const getTreeData = (data: ViewerGroup[], groupType: ViewerGroupType) =>
      data.map((store) => ({
        title: store.name,
        key: store.id,
        groupType,
        children: (store.tables || []).map((table) => ({
          key: `${store.id}:${table.id}`,
          originalId: table.id,
          title: table.name,
          isLeaf: true,
          icon: draggable ? <HolderOutlined css={cssDraggableIcon} /> : <React.Fragment />,
        })),
      }));

    return [...getTreeData(crossStudyRTTables, 'crossStudyRT')];
  }, [draggable, crossStudyRTTables]);

  return { treeData, storeQuery, crossRTListQuery, globalStudy };
};

export type ExtendedNodeData = DataNode & { groupType: ViewerGroupType };

interface IUseTaleListSiderProps {
  onSelectTable: (tableKey: string, tableName: string) => void;
  selectedTable?: string | null;
  defaultTableName?: string | null;
  treeData: ExtendedNodeData[];
  globalStudy: IAppStudyContext | null;
}

const cssDraggableIcon = css({
  width: '24px',
  opacity: 0.2,
  transition: 'opacity 0.3s',
});
