import { useColumnSearch } from '@components/ui/table/tableHooks';
import { Table, Tag } from '@ui';
import { dateToString } from '@shared/utils/Date';
import { TableColumnsType, Tooltip } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ISnapshot, SNAPSHOT_STATUS } from '../SnapshotTypes';
import { SNAPSHOT_STATUS_MAPPING } from '../duck/snapshotConstants';

export const SnapshotTableList = ({ data, storeList, loading }: ISnapshotTableListProps) => {
  const { t } = useTranslation(['snapshot']);
  const { getColumnSearchProps, locale } = useColumnSearch<ISnapshotTableListProps['data'][0]>();

  const columns: TableColumnsType<ISnapshotTableListProps['data'][0]> = useMemo(
    () => [
      {
        title: t('createPage.category'),
        dataIndex: 'store',
        filters: storeList.map((store) => ({ text: store, value: store })),
        onFilter: (value, record) => record.store === value,
      },
      {
        title: t('createPage.tableName'),
        dataIndex: 'tableName',
        key: 'tableName',
        ...getColumnSearchProps('tableName'),
      },
      {
        title: t('viewPage.lastUpdate'),
        dataIndex: 'lastUpdateDate',
        key: 'lastUpdateDate',
        render: (lastUpdateDate) => dateToString(lastUpdateDate),
      },
      {
        title: t('viewPage.status'),
        dataIndex: 'status',
        key: 'snapTable',
        render: (status: SNAPSHOT_STATUS) => {
          const content = <Tag color={SNAPSHOT_STATUS_MAPPING[status]} children={status} />;

          if (SNAPSHOT_STATUS.FAILED === status) {
            return <Tooltip title="Server error">{content}</Tooltip>;
          }
          return <Tag color={SNAPSHOT_STATUS_MAPPING[status]} children={status} />;
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, storeList],
  );

  return (
    <Table
      size="small"
      bordered
      columns={columns}
      locale={locale}
      loading={loading}
      dataSource={data}
      pagination={false}
    />
  );
};

interface ISnapshotTableListProps {
  loading?: boolean;
  data: Array<ISnapshot['tablesDetails'][0] & { store: string }>;
  storeList: string[];
}
