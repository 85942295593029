import { DraggableModal, notification, Typography } from '@ui';
import { ELibraryEntityNames, ELibrarySourceType } from '@modules/library/root/LibraryTypes';
import {
  useImportToModelMutation,
  useLazyLibraryModelListQuery,
  useLibraryCopyModelMutation,
  useUsedModelsMutation,
} from '@modules/library/model/duck/libraryModelApi';
import { ICascadeObjectsTableData, LibraryCascadeObjects } from '@shared/components/CascadeObjects';
import { useModelColumns } from '@modules/library/model/duck/libraryModelHooks';
import { useLazyEnvModelListQuery } from '@modules/library/root/duck/libraryApi';
import { LibraryStatus } from '@modules/library/root/duck/libraryConstants';
import { QueryErrorType } from '@shared/utils/Error';
import { ConfirmModal, useConfirmModal } from '@components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { prepareLibraryModel } from '../../duck/libraryModelUtils';
import {
  getLibraryImportModelFromLibrarySource,
  ILibraryImportModelProps,
  LibraryImportModel,
  WrapperFn,
} from '../../components/LibraryImportModel';
import { ILibraryModel } from '../../LibraryModelTypes';

const LibraryModelModalsCopyModelContent = ({
  data: { importToStudyId } = {},
  onClose,
  t,
}: ILibraryModelModalsCopyModelContentProps) => {
  const { modelColumns, locale } = useModelColumns();
  const [libraryModelsListQuery, libraryModelsListQueryData] = useLazyLibraryModelListQuery();
  const [envStudyModelsListQuery, studyModelsListQueryData] = useLazyEnvModelListQuery();
  const [copyModel] = useLibraryCopyModelMutation();
  const [importToModel] = useImportToModelMutation();
  const [usedModels] = useUsedModelsMutation();
  const confirmModal = useConfirmModal();

  const [isCascade, setIsCascade] = useState(false);
  const [selectedCascadeObjects, setSelectedCascadeObjects] = useState<ICascadeObjectsTableData[]>([]);
  const [isUsedError, setIsUsedError] = useState(false);

  const wrapperLibrary: WrapperFn = async (id: number) => {
    const data = await libraryModelsListQuery({ library_id: id, page_size: 9999, detailed: '1' }).unwrap();
    return getLibraryImportModelFromLibrarySource(data.items, ELibrarySourceType.Library);
  };

  const wrapperStudy: WrapperFn = async (id: number, env?: string) => {
    const data = await envStudyModelsListQuery({ study_id: id, env: env! }).unwrap();
    return getLibraryImportModelFromLibrarySource(data, ELibrarySourceType.Study);
  };

  const onImport: ILibraryImportModelProps['onImport'] = async (values, { overwrite, kind, systemInfo }) => {
    if (importToStudyId) {
      return await importToModel({
        data: values.map((item) => item.rawData as ILibraryModel),
        study_id: importToStudyId,
      }).unwrap();
    }
    if (overwrite) {
      try {
        await usedModels({ model_names: values.map((item) => item.name) }).unwrap();

        await copyModel({ data: prepareLibraryModel(values, kind, systemInfo), overwrite }).unwrap();
      } catch (error) {
        const { data } = error as QueryErrorType;
        const isError = typeof data.rawData?.error === 'string';
        setIsUsedError(isError);

        confirmModal.openConfirmation({
          content: isError ? (
            <Typography.Text type="danger">{data.rawData?.error}</Typography.Text>
          ) : (
            <div>
              {t('confirmOverwrite.content')}
              <strong>{(Object.keys(data.rawData?.error) || []).join(', ')}</strong>
            </div>
          ),
          data: prepareLibraryModel(values, kind, systemInfo),
          cascadeObjects: !isError ? data.rawData?.error : {},
        });
        // TODO Refactor this stuff later
        // eslint-disable-next-line no-throw-literal
        throw '';
      }
    } else {
      await copyModel({ data: prepareLibraryModel(values, kind, systemInfo), overwrite }).unwrap();
    }
  };

  const onOverwriteConfirm = async (data: any) => {
    try {
      const processSelectedCascadeObjects = selectedCascadeObjects.filter((el) => !el.children);
      await copyModel({ data, cascade_update: processSelectedCascadeObjects, overwrite: true }).unwrap();
      onClose();
    } catch (error) {
      notification.error({ message: (error as QueryErrorType)?.data?.userMsg });
    }
  };

  const clearCascadeData = () => {
    setIsCascade(false);
    setSelectedCascadeObjects([]);
  };

  return (
    <>
      <LibraryImportModel
        onClose={onClose}
        columns={modelColumns}
        locale={locale}
        kind={ELibraryEntityNames.Model}
        sourceOnlyLibrary={!!importToStudyId}
        libraryTableListQuery={wrapperLibrary}
        hideOverwriteHandles={!!importToStudyId}
        studyTableListQuery={wrapperStudy}
        tableDataFetching={libraryModelsListQueryData.isFetching || studyModelsListQueryData.isFetching}
        onImport={onImport}
        libraryStatuses={[LibraryStatus.Active, LibraryStatus.Development]}
      />
      <ConfirmModal
        title={t('confirmOverwrite.content')}
        submitFunc={onOverwriteConfirm}
        {...confirmModal}
        closeConfirmation={() => {
          clearCascadeData();
          confirmModal.closeConfirmation();
        }}
        okButtonProps={{ disabled: isUsedError }}
      >
        {!isUsedError && (
          <LibraryCascadeObjects
            data={confirmModal.confirmState?.cascadeObjects}
            isCascade={isCascade}
            setIsCascade={setIsCascade}
            setSelectedCascadeObjects={setSelectedCascadeObjects}
          />
        )}
      </ConfirmModal>
    </>
  );
};

export const LibraryModelModalsCopyModel = ({ open, data, onClose }: ILibraryModelModalsCopyModelProps) => {
  const { t } = useTranslation(['libraryModel']);

  return (
    <DraggableModal width="50%" open={open} onCancel={onClose} title={t('copyForm.title')} footer={null} destroyOnClose>
      {open && <LibraryModelModalsCopyModelContent data={data} onClose={onClose} t={t} />}
    </DraggableModal>
  );
};

export interface ILibraryModelModalsCopyModelProps {
  open: boolean;
  data?: { importToStudyId?: number };
  onClose: () => void;
}

interface ILibraryModelModalsCopyModelContentProps extends Pick<ILibraryModelModalsCopyModelProps, 'data' | 'onClose'> {
  t: TFunction;
}
