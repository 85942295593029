import { PageTemplateSimple } from '@components';
import { TableExtraConfig, TableFiltersConfig, TableSorterConfig } from '@ui';
import { Store } from '@modules/stores/StoreTypes';
import { DEFAULT_STORES } from '@modules/stores/duck/storeConstants';
import { StoreList } from '@modules/stores/components';
import { isCrossStudy } from '@shared/utils/common';
import { useStoreListQuery } from '@modules/stores/duck/storeApi';
import { useSourceListQuery } from '@modules/source/duck/sourceApi';
import { useTablePaginationState } from '@components/ui/table/tableHooks';
import { selectGlobalStudy } from '@app/duck/appSelectors';
import { storeActions } from '@modules/stores/duck/storeSlice';
import { StoreModalsType } from '@modules/stores/modals';
import { useDispatch, useSelector } from 'react-redux';
import { Button, TablePaginationConfig } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';

const initialPage = {
  current: 1,
  pageSize: 10,
  pageSizeOptions: [],
};

export const StoreRootPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['datastore']);
  const [stores, setStores] = useState<Store[]>([]);
  const globalStudy = useSelector(selectGlobalStudy);
  const { paginationState, setPagination, getPagination } = useTablePaginationState(initialPage);
  // request was changed to local pagination because of incorrect behaviour pages with default stores
  const storeQueryOrigin = useStoreListQuery();

  const storeQueryData = useMemo(() => {
    return storeQueryOrigin.data ? [...DEFAULT_STORES, ...storeQueryOrigin.data] : DEFAULT_STORES;
  }, [storeQueryOrigin.data]);

  const sourceListQuery = useSourceListQuery().data;

  useEffect(() => {
    setStores(storeQueryData);
  }, [storeQueryData]);

  const onTableChange = (
    tablePagination: TablePaginationConfig,
    filters: TableFiltersConfig,
    sorter: TableSorterConfig<Store>,
    extra: TableExtraConfig<Store>,
  ) => {
    const isNoFilters = Object.values(filters).every((el) => !el);
    if (isNoFilters) {
      setStores(storeQueryData);
    } else {
      setStores(extra.currentDataSource);
    }
    setPagination(tablePagination.current!, tablePagination.pageSize);
  };

  const pagination = getPagination(stores?.length);

  const addStoreModal = () => {
    dispatch(storeActions.pushModal({ type: StoreModalsType.saveStore }));
  };

  return (
    <PageTemplateSimple
      hideTitleSkeleton
      title={{
        children: t('pageRootName'),
        extra: !isCrossStudy(globalStudy?.id!) && <Button children={t('add')} onClick={addStoreModal} />,
      }}
      content={{
        isLoading: storeQueryOrigin.isLoading && !storeQueryOrigin.data,
        errorText: t('loadingError'),
        error: storeQueryOrigin.error,
      }}
    >
      {!storeQueryOrigin.isLoading && (
        <StoreList
          data={stores}
          sourceList={sourceListQuery}
          studyId={globalStudy?.id!}
          onChange={onTableChange}
          pagination={pagination}
          loading={storeQueryOrigin.isFetching}
          t={t}
        />
      )}
    </PageTemplateSimple>
  );
};
