import { ELibrarySourceType, IBaseLibraryEntitySystemInfo } from '@modules/library/root/LibraryTypes';
import { Model } from '@modules/model/ModelTypes';
import { ILibraryImportModel } from '../components/LibraryImportModel';
import { ILibraryModel, ILibraryModelQuery } from '../LibraryModelTypes';

export const prepareLibraryModel = (
  data: ILibraryImportModel[],
  kind: ELibrarySourceType,
  systemInfo?: IBaseLibraryEntitySystemInfo,
): ILibraryModelQuery['data'] => {
  if (!Array.isArray(data)) return [];
  return data.map((item) => {
    if (kind === ELibrarySourceType.Library) {
      const rawData = item.rawData as ILibraryModel;
      return {
        id: rawData.id,
        name: rawData.name,
        data_source: rawData.data_source,
        data_store: rawData.data_store,
        model_type: rawData.model_type,
        configuration: rawData.configuration,
        version_id: rawData.version_id,
        source: rawData.source || '',
        env: rawData.env || '',
        tenant_info: rawData.tenant_info || '',
      };
    }

    const rawData = item.rawData as Model;
    return {
      name: rawData.name,
      model_type: rawData.type,
      data_source: rawData.data_store.data_source_name!,
      data_store: rawData.data_store.name,
      configuration: {
        name: rawData.name,
        active: rawData.active,
        description: rawData.description,
        intermediate: rawData.intermediate,
        job: rawData.job,
        schema: rawData.schema,
        spark_schema: rawData.spark_schema,
        sensitive: rawData.sensitive,
        type: rawData.type,
        version: rawData.version,
        data_store: {
          active: rawData.data_store.active,
          description: rawData.data_store.description,
          name: rawData.data_store.name,
          data_source_name: rawData.data_store.data_source_name,
          job: rawData.data_store.job,
          sensitive: rawData.data_store.sensitive,
        },
      },
      source: systemInfo?.source || '',
      env: systemInfo?.env || '',
      tenant_info: systemInfo?.tenant_info || '',
    };
  });
};
