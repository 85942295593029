import { DraggableModal } from '@ui';
import { useModelColumns } from '@modules/library/model/duck/libraryModelHooks';
import { useLazyLibraryModelListQuery } from '@modules/library/model/duck/libraryModelApi';
import { LibraryStatus } from '@modules/library/root/duck/libraryConstants';
import { ELibraryEntityNames, ELibrarySourceType } from '@modules/library/root/LibraryTypes';
import { useCopyAnalysisObjectModelMutation } from '@modules/library/analysisObjects/model/duck/analysisObjectModelApi';
import {
  getLibraryImportModelFromLibrarySource,
  ILibraryImportModelProps,
  LibraryImportModel,
  WrapperFn,
} from '@modules/library/model/components/LibraryImportModel';
import { IAnalysisObjectModelQuery } from '@modules/library/analysisObjects/model/AnalysisObjectModelTypes';
import { prepareLibraryModel } from '@modules/library/model/duck/libraryModelUtils';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

const AnalysisObjectModelModalsCopyModelContent = ({ onClose, t }: IAnalysisObjectModelModalsCopyModelContentProps) => {
  const { modelColumns, locale } = useModelColumns();
  const [libraryModelsListQuery, libraryModelsListQueryData] = useLazyLibraryModelListQuery();
  const [copyModel] = useCopyAnalysisObjectModelMutation();

  const wrapperLibrary: WrapperFn = async (id: number) => {
    const data = await libraryModelsListQuery({ library_id: id, detailed: '1' }).unwrap();
    return getLibraryImportModelFromLibrarySource(data.items, ELibrarySourceType.Library);
  };

  const onImport: ILibraryImportModelProps['onImport'] = async (values, { kind }) => {
    await copyModel({ data: prepareLibraryModel(values, kind) as IAnalysisObjectModelQuery['data'] }).unwrap();
  };

  return (
    <LibraryImportModel
      onClose={onClose}
      columns={modelColumns}
      locale={locale}
      kind={ELibraryEntityNames.Model}
      libraryTableListQuery={wrapperLibrary}
      tableDataFetching={libraryModelsListQueryData.isFetching}
      onImport={onImport}
      libraryStatuses={[LibraryStatus.Active]}
      sourceOnlyLibrary
      hideOverwriteHandles
    />
  );
};

export const AnalysisObjectModelModalsCopyModel = ({
  open,
  data,
  onClose,
}: IAnalysisObjectModelModalsCopyModelProps) => {
  const { t } = useTranslation(['libraryModel']);

  return (
    <DraggableModal width="50%" open={open} onCancel={onClose} title={t('copyForm.title')} footer={null} destroyOnClose>
      {open && <AnalysisObjectModelModalsCopyModelContent data={data} onClose={onClose} t={t} />}
    </DraggableModal>
  );
};

export interface IAnalysisObjectModelModalsCopyModelProps {
  open: boolean;
  data: any;
  onClose: () => void;
}

interface IAnalysisObjectModelModalsCopyModelContentProps
  extends Pick<IAnalysisObjectModelModalsCopyModelProps, 'data' | 'onClose'> {
  t: TFunction;
}
