export const downloadByUrl = (url: string) => {
  const a = document.createElement('a');
  a.style.display = 'none';
  document.body.appendChild(a);

  a.rel = 'noopener';
  a.href = url;

  a.click();

  document.body.removeChild(a);
};
