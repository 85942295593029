import { RootState } from '@store';

export const selectModelEditorModelName = (state: RootState) => state.modelEditor.modelName;

export const selectModelEditorModals = (state: RootState) => state.modelEditor.modals;

export const selectModelEditorSchemaEditor = (state: RootState) => state.modelEditor.schemaEditor;

export const selectModelEditorNodes = (state: RootState) => state.modelEditor.nodes;

export const selectModelEditorEdges = (state: RootState) => state.modelEditor.edges;

export const selectConfirmationOpened = (state: RootState) => state.modelEditor.confirmation.opened;

export const selectModelEditorErrors = (state: RootState) => state.modelEditor.errors;

export const selectModelEditorHoveredEdge = (state: RootState) => state.modelEditor.hoveredEdge;
