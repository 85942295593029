import { I18nLocales } from '@i18n';
import en from './job_en.json';

export const jobLocales: Record<I18nLocales, I18nJobTranslations> = {
  en,
};

interface I18nJobTranslations {
  pageRootName: string;
  pageEditName: string;
  loadingError: string;
  editLoadingError: string;
  uploadModal: {
    title: string;
    skipBlankRows: string;
    skipHashOfRows: string;
    ignoreErrors: string;
    importToStaging: string;
    timeFormat: string;
    separator: string;
    targetDataStore: string;
    btnName: string;
    btnLabel: string;
  };
  rootTable: {
    name: string;
    dataSource: string;
    connection: string;
    dataStore: string;
    lastRunAt: string;
    status: string;
    btnEdit: string;
  };
  editTable: {
    name: string;
    tableName: string;
    totalRows: string;
    rowsImported: string;
    rowsErrored: string;
    importStatus: string;
    btnDelete: string;
    btnMove: string;
    actionMenu: {
      label: string;
      viewData: string;
      viewVariables: string;
    };
  };
  inputJobName: string;
  btnUpload: string;
  viewVariablesModal: {
    rowNumber: string;
    name: string;
    type: string;
    length: string;
    precision: string;
    scale: string;
  };
}
