import { PageTemplateSimple } from '@components';
import { useJobDomainsQuery } from '@modules/job/duck/jobApi';
import { Button, Space } from '@ui';
import routes from '@routes';
import { JobModalsController, JobModalsType } from '@modules/job/modals';
import { DataDomainProps, JobDomainList } from '@modules/job/components/JobDomainList';
import { selectGlobalStudy } from '@app/duck/appSelectors';
// import { useJobNavigation } from '@job/duck/useJobNavigation';
import { Domain } from '@modules/job/JobTypes';
import { jobActions } from '@modules/job/duck/jobSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const JobEditPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['job']);
  const navigate = useNavigate();

  const globalStudy = useSelector(selectGlobalStudy);
  const { jobId, studyId } = useParams();

  const currentJobId = parseInt(jobId!);

  const jobDomainsQuery = useJobDomainsQuery(currentJobId);
  const jobDomain = jobDomainsQuery?.data?.find((el) => !el.deleted_at && el.name);
  const jobName = jobDomain?.name!;
  const jobData = jobDomainsQuery?.data;

  const [domainsData, setDomainsData] = useState<DataDomainProps>();

  useEffect(() => {
    // all items deleted
    if (domainsData?.items && !domainsData?.items?.some((item) => !item.deleted_at)) {
      if (studyId) {
        navigate(routes.study.jobs.root.resolver({ studyId }));
      } else {
        navigate(routes.app.root.resolver());
      }
    }
  }, [domainsData, navigate, studyId]);

  useEffect(() => {
    setDomainsData({
      name: jobName,
      items: jobData?.filter((item) => !item.deleted_at),
    });
  }, [jobName, jobData]);

  const handleDeleteDomain = (domain: Domain) => {
    dispatch(jobActions.pushModal({ type: JobModalsType.deleteJob, data: domain }));
  };

  const cancelJobImport = () => {
    if (studyId) {
      navigate(routes.study.jobs.root.resolver({ studyId }));
    } else {
      navigate(routes.app.root.resolver());
    }
  };

  return (
    <PageTemplateSimple
      title={{
        children: jobName,
        extra: (
          <Space>
            <Button children={t('back')} onClick={cancelJobImport} />
          </Space>
        ),
      }}
      content={{
        isLoading: jobDomainsQuery.isLoading,
        errorText: t('editLoadingError'),
        error: jobDomainsQuery.error,
      }}
      hideTitleSkeleton
      // navigation={navigation}
    >
      {jobDomainsQuery.data && (
        <JobDomainList data={domainsData} studyId={globalStudy?.id!} onHandleDeleteDomain={handleDeleteDomain} t={t} />
      )}
      <JobModalsController />
    </PageTemplateSimple>
  );
};
