import { appApi } from '@config/appApi';
import { ANALYSIS_OBJECT_MODEL_TAG_DESCRIPTION } from '@modules/library/analysisObjects/model/duck/analysisObjectModelConstants';
import {
  IAnalysisObjectModelListQueryParams,
  IAnalysisObjectModelListResponse,
  IAnalysisObjectModelQuery,
} from '@modules/library/analysisObjects/model/AnalysisObjectModelTypes';
import { TagDescription } from '@reduxjs/toolkit/query/react';

export const AnalysisObjectModelApiRoutes = {
  list: `api/library/analysis/models`,
  model: (modelId: number) => `api/library/analysis/models/${modelId}`,
};

const AnalysisObjectModelInvalidations: {
  LIST: TagDescription<ANALYSIS_OBJECT_MODEL_TAG_DESCRIPTION.LIST>;
  ID: (id: number) => TagDescription<ANALYSIS_OBJECT_MODEL_TAG_DESCRIPTION.ID>;
} = {
  LIST: { type: ANALYSIS_OBJECT_MODEL_TAG_DESCRIPTION.LIST, id: 'LIST' },
  ID: (modelId: number) => ({ type: ANALYSIS_OBJECT_MODEL_TAG_DESCRIPTION.ID, modelId }),
};

export const AnalysisObjectModelApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    analysisObjectModelList: builder.query<
      IAnalysisObjectModelListResponse,
      IAnalysisObjectModelListQueryParams | void
    >({
      providesTags: [AnalysisObjectModelInvalidations.LIST],
      query: (params) => ({
        params,
        url: AnalysisObjectModelApiRoutes.list,
      }),
    }),
    deleteAnalysisObjectModel: builder.mutation<void, number>({
      invalidatesTags: (request, error, id) => [
        AnalysisObjectModelInvalidations.LIST,
        AnalysisObjectModelInvalidations.ID(id),
      ],
      query: (id) => ({
        method: 'DELETE',
        url: AnalysisObjectModelApiRoutes.model(id),
      }),
    }),
    copyAnalysisObjectModel: builder.mutation<void, IAnalysisObjectModelQuery>({
      invalidatesTags: [AnalysisObjectModelInvalidations.LIST],
      query: ({ data }) => ({
        data,
        method: 'POST',
        url: AnalysisObjectModelApiRoutes.list,
      }),
    }),
  }),
});

export const {
  useAnalysisObjectModelListQuery,
  useDeleteAnalysisObjectModelMutation,
  useCopyAnalysisObjectModelMutation,
} = AnalysisObjectModelApi;
