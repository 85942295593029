import { AppToolbarPortal } from '@app/components/Header/AppToolbarPortal';
import { Loader, QueryError } from '@components';
import { useStudyAppListener } from '@modules/study/duck/studyHooks';
import { Outlet } from 'react-router-dom';
import { StudyRoleSelector } from './components/StudyRoleSelector';

export const StudyAppRouterProvider = () => {
  const { queryError, studyActiveRole, studyAppPreloaded, isCrossStudy } = useStudyAppListener();

  if (queryError) {
    return <QueryError error={{ status: 404, data: { userMsg: 'Study Not Found' } }} title="Study Not Found" />;
  }

  return (
    <>
      {!isCrossStudy && (
        <AppToolbarPortal>
          <StudyRoleSelector />
        </AppToolbarPortal>
      )}
      {!studyAppPreloaded || (!isCrossStudy && !studyActiveRole) ? <Loader mode="fixed" size="large" /> : <Outlet />}
    </>
  );
};
