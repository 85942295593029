import { StoreListResponse } from '@modules/stores/duck/storeApi';
import { dateToString } from '@shared/utils/Date';
import { ActorAndInfo } from '@components';
import { useColumnSearch } from '@components/ui/table/tableHooks';
import { tableListToOptions, tableNamesToOptions } from '@shared/utils/Table';
import { TLibraryDeleteModelProps } from '@modules/library/model/LibraryModelTypes';
import { Button, Popconfirm, Space, Table } from '@ui';
import { SupportedEnvs } from '@app/AppTypes';
import { useMemo } from 'react';
import { TableColumnsType, TableProps } from 'antd';
import { TFunction } from 'i18next';
import { DeleteOutlined } from '@ant-design/icons';
import { IAnalysisObjectModelListResponse } from '../AnalysisObjectModelTypes';

export const AnalysisObjectModelList = ({
  data,
  loading,
  pagination,
  onChange,
  loadingDelete,
  supportedEnvs,
  onDeleteDataModel,
  t,
}: AnalysisObjectModelListProps) => {
  const { getColumnSearchProps, locale } = useColumnSearch<IAnalysisObjectModelListResponse['items'][0]>();

  const storeListOption = tableNamesToOptions(data?.items, 'data_store');
  const sourceListOption = tableListToOptions(data?.items?.map((el) => ({ name: el.source!, id: el.source })));

  const columns: TableColumnsType<IAnalysisObjectModelListResponse['items'][0]> = useMemo(
    () => [
      {
        title: t('rootTable.name'),
        dataIndex: 'name',
        ...getColumnSearchProps('name'),
        onFilter: undefined,
      },
      {
        width: 100,
        title: t('rootTable.version'),
        dataIndex: 'version',
        render: (_, record) => record.version,
      },
      {
        title: t('rootTable.source'),
        dataIndex: 'source',
        filters: sourceListOption,
        onFilter: undefined,
        render: (_, record) =>
          record.source && (
            <ActorAndInfo info={supportedEnvs[record.env]?.label || t('na')} actor={record.source} actorSize={'sm'} />
          ),
      },
      {
        title: t('rootTable.dataStore'),
        dataIndex: 'data_store',
        filters: storeListOption,
        onFilter: undefined,
        render: (_, record) => record.data_store,
      },
      {
        title: t('rootTable.created'),
        dataIndex: 'linked_at',
        sorter: (a, b) => 0,
        sortDirections: ['ascend'],
        render: (linkedAt: number, record) => <ActorAndInfo info={dateToString(linkedAt)} actor={record.created_by} />,
      },
      {
        width: 80,
        title: t('delete'),
        dataIndex: 'actionDelete',
        align: 'center',
        render: (_, record) => (
          <Space>
            <Popconfirm
              title={t('confirmation.title')}
              description={t('confirmation.description')}
              okText={t('yes')}
              cancelText={t('no')}
              onConfirm={() => onDeleteDataModel({ id: record.version_id, name: record.name })}
            >
              <Button icon={<DeleteOutlined />} loading={loadingDelete} />
            </Popconfirm>
          </Space>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [storeListOption, sourceListOption],
  );

  return (
    <Table
      bordered
      locale={locale}
      columns={columns}
      dataSource={data?.items}
      loading={loading}
      rowKey={(item) => item.id}
      onChange={onChange}
      tableLayout="fixed"
      scroll={{ x: 900 }}
      pagination={pagination}
    />
  );
};

export interface AnalysisObjectModelListProps {
  studyId: number;
  data?: IAnalysisObjectModelListResponse;
  storeList?: StoreListResponse['items'];
  pagination?: TableProps<IAnalysisObjectModelListResponse['items'][0]>['pagination'];
  onChange?: TableProps<IAnalysisObjectModelListResponse['items'][0]>['onChange'];
  loading?: boolean;
  loadingDelete?: boolean;
  onDeleteDataModel: (val: TLibraryDeleteModelProps) => void;
  pageSize?: number;
  disableKindSort?: boolean;
  supportedEnvs: SupportedEnvs;
  t: TFunction;
}
