import { useColumnSearch } from '@components/ui/table/tableHooks';
import { Popover, Table, Checkbox } from '@ui';
import { SELECTED_ACTIONS } from '@shared/GlobalConstants';
import { selectGlobalStudy } from '@app/duck/appSelectors';
import { TableColumnsType } from 'antd';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableRowSelection } from 'antd/es/table/interface';
import { useSelector } from 'react-redux';
import { useSnapshotSourceTableListQuery } from '../duck/snapshotApi';

const isValidSchema = (record: ISnapshotTableItem, studySchema: string) => record.originalId.includes(studySchema);

export const SnapshotTableSelect = memo(({ onSelectTable, selectedTables, disabled }: ISnapshotTableSelectProps) => {
  const { t } = useTranslation(['snapshot']);
  const { getColumnSearchProps, locale } = useColumnSearch<ISnapshotTableItem>();
  const globalStudy = useSelector(selectGlobalStudy);
  const sourceTableListQuery = useSnapshotSourceTableListQuery();

  const data = useMemo(() => {
    return {
      tables: Object.entries(sourceTableListQuery?.data || {}).reduce<ISnapshotTableItem[]>(
        (acc, [storeName, tables], storeIndex) => {
          if (tables?.length) {
            tables.forEach((table, index) => {
              acc.push({
                key: `${storeIndex}${index}:${table}`,
                originalId: table,
                title: table.split('.')[1],
                store: storeName,
              });
            });
          }
          return acc;
        },
        [],
      ),
      storeOptions: Object.keys(sourceTableListQuery?.data || {}).map((storeName) => ({
        text: storeName,
        value: storeName,
      })),
    };
  }, [sourceTableListQuery?.data]);

  const columns: TableColumnsType<ISnapshotTableItem> = useMemo(
    () => [
      {
        title: t('createPage.category'),
        dataIndex: 'store',
        filters: data.storeOptions,
        onFilter: (value, record) => record.store === value,
      },
      {
        title: t('createPage.tableName'),
        dataIndex: 'title',
        key: 'title',
        ...getColumnSearchProps('title'),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  );

  const rowSelection: TableRowSelection<ISnapshotTableItem> = {
    onChange: (selectedRowKeys) => {
      onSelectTable(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: disabled || !isValidSchema(record, globalStudy?.studySchema!),
      name: `selected-${record.key}`,
    }),
    renderCell: (value, record, index, originNode) =>
      isValidSchema(record, globalStudy?.studySchema!) ? (
        originNode
      ) : (
        <Popover title={t('errors.tableHasWrongSchema', { name: record.title })} color="yellow">
          <Checkbox id={`selected-${record.key}`} disabled />
        </Popover>
      ),
  };

  return (
    <Table
      size="small"
      bordered
      columns={columns}
      locale={locale}
      loading={sourceTableListQuery.isLoading}
      rowKey="key"
      dataSource={data.tables}
      pagination={false}
      rowSelection={{
        preserveSelectedRowKeys: true,
        type: 'checkbox',
        ...rowSelection,
        selectedRowKeys: selectedTables,
        selections: [
          Table.SELECTION_INVERT,
          {
            key: SELECTED_ACTIONS.SHOW_ALL,
            text: 'Select all',
            onSelect: () => onSelectTable(data.tables.map((table) => table.key)),
          },
          {
            key: SELECTED_ACTIONS.HIDE_SELECTED,
            text: 'Unselect all',
            onSelect: () => onSelectTable([]),
          },
        ],
      }}
    />
  );
});

interface ISnapshotTableSelectProps {
  onSelectTable: (keys: React.Key[]) => void;
  selectedTables: React.Key[];
  disabled?: boolean;
}

interface ISnapshotTableItem {
  key: string;
  originalId: string;
  title: string;
  store: string;
}
