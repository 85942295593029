import AceEditor from 'react-ace';
import { CSSObject, Theme } from '@emotion/react';
import { forwardRef, ForwardedRef } from 'react';
import { TFunction } from 'i18next';

import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/mode-sql';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/ext-searchbox';
import 'ace-builds/src-min-noconflict/snippets/mysql';

export const SqlBuilder = forwardRef(
  ({ id, className, t, defaultValue, onChange, onDrop, onDragOver }: SqlBuilderProps, ref: ForwardedRef<AceEditor>) => {
    return (
      <>
        <div css={cssBody} className={className} onDrop={onDrop} onDragOver={onDragOver}>
          <AceEditor
            id={'yourAceEditorContainerId'}
            ref={ref}
            mode="sql"
            theme="github"
            css={cssEditorBody}
            height="250px"
            width="100%"
            fontSize={14}
            placeholder={t('sql.placeholder')}
            name={id ?? 'sql_editor'}
            defaultValue={defaultValue ?? ''}
            onChange={onChange}
            wrapEnabled
            focus
            enableBasicAutocompletion
            enableLiveAutocompletion
            enableSnippets
            editorProps={{ $blockScrolling: Infinity }}
            onError={(error: any) => {
              console.error('Ace Editor internal Error:', error);
            }}
            setOptions={{
              dragEnabled: true,
            }}
          />
        </div>
      </>
    );
  },
);

const cssBody = (theme: Theme): CSSObject => ({
  width: '100%',
  border: `1px solid ${theme.colorBorder}`,
  borderRadius: theme.borderRadiusSM,
});

const cssEditorBody = (theme: Theme): CSSObject => ({
  borderBottom: `1px solid ${theme.colorBorder}`,
  backgroundColor: 'transparent',
});

interface SqlBuilderProps {
  id?: string;
  className?: string;
  defaultValue?: string;
  addDataToEditor?: any;
  onDrop?: (e: React.DragEvent<HTMLElement>) => void;
  onDragOver?: (e: React.DragEvent<HTMLElement>) => void;
  onChange?: (value: string) => void;
  t: TFunction;
}
