import { Button, DraggableModal, Space } from '@ui';
import { ExpressionBuilder } from '@modules/modelEditor/components/expressionBuilder';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

const ModelEditorModalsTransformationContent = ({ data, onChange, t }: ModelEditorModalsTransformationContentProps) => {
  return <ExpressionBuilder variables={data.variables} value={data.value ?? ''} onChange={onChange} t={t} />;
};

export const ModelEditorModalsTransformation = ({ open, data, onClose }: ModelEditorModalsTransformationProps) => {
  const { t } = useTranslation(['model']);
  const [value, onChange] = useState(data?.value ?? '');

  useEffect(() => {
    onChange(data?.value ?? '');
  }, [data?.value]);

  const onSubmit = () => {
    onClose(value);
  };

  const onCancel = () => {
    onChange(data?.value ?? '');
    onClose(data?.value ?? '');
  };

  const onDelete = () => {
    onChange('');
    onClose('');
  };

  return (
    <DraggableModal
      width={920}
      open={open}
      onCancel={onCancel}
      onOk={onSubmit}
      title={t('transform.title')}
      cancelText={t('transform.cancel')}
      okText={t('transform.save')}
      destroyOnClose
      footer={
        <Space>
          <Button danger children={t('delete')} onClick={onDelete} />
          <Button children={t('cancel')} onClick={onCancel} />
          <Button type="primary" children={t('save')} onClick={onSubmit} />
        </Space>
      }
    >
      {open && <ModelEditorModalsTransformationContent data={data} onChange={onChange} t={t} />}
    </DraggableModal>
  );
};

interface ModelEditorModalsTransformationContentProps extends Pick<ModelEditorModalsTransformationProps, 'data'> {
  t: TFunction;
  onChange: Dispatch<SetStateAction<string>>;
}
export interface ModelEditorModalsTransformationProps {
  open: boolean;
  data: {
    variables: string[];
    value?: string;
  };
  onClose: (result?: string) => void;
}
