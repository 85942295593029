import {
  Button,
  Card,
  Checkbox,
  DraggableModal,
  Form,
  FormItem,
  FormLayout,
  Input,
  InputNumber,
  ProgressWithTitle,
  Select,
  Space,
  Upload,
} from '@ui';
import { Job } from '@modules/job/JobTypes';
import {
  useUploadFileMutation,
  useProcessJobMutation,
  useDiscoveryJobMutation,
  ProcessJobParams,
} from '@modules/job/duck/jobApi';
import { CSVFile } from '@components/icons';
import { useManualUploadStores } from '@modules/stores/duck/storeHooks';
import routes from '@routes';
import { selectGlobalStudy } from '@app/duck/appSelectors';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { UploadFile } from 'antd/lib/upload';
import { UploadProps } from 'antd';
import { CSSObject } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// const timeFormatOptions = [
//   { label: 'Basic', value: 'basic' },
//   { label: 'Best effort', value: 'best_effort' },
// ];
// const timeFormatTooltip = (
//   <dl>
//     <dt>Basic</dt>
//     <dd>YYYY-MM-DD HH:MM:SS or YYYY-MM-DD format</dd>
//     <dt>Best efforts</dt>
//     <dd> YYYY-MM-DD HH:MM:SS format and all ISO 8601 date and time formats</dd>
//   </dl>
// );

const JobModalsUploadJobContent = ({ data, onClose, t }: JobModalsUploadJobContentProps) => {
  const globalStudy = useSelector(selectGlobalStudy);
  const [form] = Form.useForm();
  const [uploadFile, uploadFileResult] = useUploadFileMutation();
  const [discoveryJob, discoveryJobResult] = useDiscoveryJobMutation();
  const [processJob, processJobResult] = useProcessJobMutation();
  const [progress, setProgress] = useState({ progress: 0, step: 1 });
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const { loading, manualUploadStores } = useManualUploadStores(data?.data_store_id);
  const navigate = useNavigate();

  const increaseProgress = (value: number = 0) => {
    const increment = Math.floor(Math.random() * 5 + 3);
    if (value) {
      setProgress((prev) => ({ ...prev, progress: value }));
    } else {
      setProgress((prev) =>
        prev.progress + increment >= 100
          ? { progress: 0, step: prev.step + 1 }
          : { ...prev, progress: prev.progress + increment },
      );
    }
  };

  const updateUploadProgress = (part: number, parts: number) =>
    setProgress((prev) => ({ ...prev, progress: Math.floor((100 * part) / parts) }));

  useEffect(() => {
    if (!loading && !manualUploadStores.length) {
      form.setFields([{ name: 'store_id', errors: [t('shared.errors.emptyManualUploadStoreList')] }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manualUploadStores, loading]);

  const isError = uploadFileResult.isError || processJobResult.isError || discoveryJobResult.isError;
  const uploadResultMessage = [uploadFileResult, processJobResult, discoveryJobResult]
    .map(
      (result) =>
        result.isError && result.error && ('data' in result.error ? result.error.data.userMsg : result.error?.message),
    )
    .filter(Boolean)
    .join(';');

  const onSubmit = async (values: JobUploadFormValues) => {
    try {
      const result = await uploadFile({
        file: values.file[0].originFileObj as File,
        callback: updateUploadProgress,
      }).unwrap();
      const data = {
        store_id: values.store_id,
        separator: values.separator,
        filename: result.filename ?? '',
        skip_blank: values.skipBlankRows,
        skip_rows: values.skipHashOfRows,
        ignore_errors: values.ignoreErrors,
        // time_format: values.timeFormat,
        callback: increaseProgress,
      };
      setProgress({ progress: 0, step: 1 });
      const discoveryResult = await discoveryJob(data).unwrap();

      setProgress({ progress: 0, step: 1 });
      const params: ProcessJobParams = {
        store_id: values.store_id,
        filename: result.filename ?? '',
        ignore_errors: values.ignoreErrors,
        callback: increaseProgress,
        data: discoveryResult.map((item) => ({
          tableName: item.name,
          total_columns: item.total_columns,
          total_rows: item.total_rows,
          mapping: Object.keys(item.structure).map((key) => ({
            sourceColumn: key,
            targetColumn: key,
            type: item.structure[key],
            nullable: true,
            primaryKey: false,
          })),
        })),
      };
      const jobId = await processJob(params).unwrap();

      if (jobId !== undefined) {
        navigate(routes.study.jobs.view.resolver({ jobId, studyId: globalStudy?.id! }));
      }

      onClose();
    } catch (e) {
      console.error(e);
    }
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const initValues = {
    skipBlankRows: true,
    skipHashOfRows: 0,
    separator: ',',
    // timeFormat: timeFormatOptions[0].value,
  };

  const layout = {
    labelCol: { span: 14 },
    wrapperCol: { span: 10 },
  };

  return (
    <FormLayout
      {...layout}
      form={form}
      onCancel={onClose}
      onSubmit={onSubmit}
      okText={t('upload')}
      initialValues={initValues}
    >
      {/*<Row gutter={24}>*/}
      {/*  <Col span={12}>*/}
      {/*    <FormItem name="skipBlankRows" label={t('uploadModal.skipBlankRows')} valuePropName="checked">*/}
      {/*      <Checkbox />*/}
      {/*    </FormItem>*/}
      {/*  </Col>*/}
      {/*  <Col span={12}>*/}
      {/*    <FormItem name="skipHashOfRows" label={t('uploadModal.skipHashOfRows')}>*/}
      {/*      <InputNumber defaultValue={0} min={0} />*/}
      {/*    </FormItem>*/}
      {/*  </Col>*/}
      {/*</Row>*/}
      <Row gutter={24}>
        <Col span={14}>
          <FormItem name="skipHashOfRows" label={t('uploadModal.skipHashOfRows')}>
            <InputNumber defaultValue={0} min={0} />
          </FormItem>
        </Col>
        <Col span={10}>
          <FormItem name="ignoreErrors" label={t('uploadModal.ignoreErrors')} valuePropName="checked">
            <Checkbox />
          </FormItem>
        </Col>
        {/*<Col span={12}>*/}
        {/*  <FormItem name="importToStaging" label={t('uploadModal.importToStaging')} valuePropName="checked">*/}
        {/*    <Checkbox />*/}
        {/*  </FormItem>*/}
        {/*</Col>*/}
      </Row>
      <Row gutter={24}>
        {/* <Col span={14}>
          <FormItem
            name="timeFormat"
            label={
              <>
                {t('uploadModal.timeFormat')}
                <Tooltip placement="left" title={timeFormatTooltip}>
                  <QuestionCircleOutlined css={cssTooltipIcon} size={32} />
                </Tooltip>
              </>
            }
          >
            <Select options={timeFormatOptions} placeholder={t('select')} />
          </FormItem>
        </Col> */}
        <Col span={14}>
          <FormItem name="separator" label={t('uploadModal.separator')} rules={[{ required: true }]}>
            <Input />
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormItem
            name="store_id"
            label={t('uploadModal.targetDataStore')}
            valuePropName="checked"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            rules={[{ required: true }]}
          >
            <Select
              options={manualUploadStores}
              placeholder="Select"
              loading={loading}
              fieldNames={{ label: 'name', value: 'id' }}
            />
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormItem
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            name="file"
            label={t('uploadModal.btnLabel')}
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[{ required: true }]}
          >
            <Upload
              accept=".csv, .xlsx, .txt"
              name="files"
              beforeUpload={() => false}
              listType="text"
              fileList={fileList}
              onChange={onChange}
              maxCount={1}
              itemRender={(_, file) => (
                <Card size="small" key={file.uid}>
                  <Space css={cssFileLayout} block>
                    <CSVFile />
                    <div css={cssFileNameLabel} title={file.name}>
                      {file.name}
                    </div>
                  </Space>
                </Card>
              )}
            >
              <Button icon={<UploadOutlined />} css={cssUploadBtn}>
                {t('uploadModal.btnName')}
              </Button>
            </Upload>
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {uploadFileResult.isLoading && <ProgressWithTitle title="Upload" percent={progress.progress} />}
          {processJobResult.isLoading && (
            <ProgressWithTitle
              title={t('uploadModal.processing', { num: progress.step })}
              percent={progress.progress}
            />
          )}
          {discoveryJobResult.isLoading && (
            <ProgressWithTitle
              title={t('uploadModal.discovering', { num: progress.step })}
              percent={progress.progress}
            />
          )}
        </Col>
      </Row>
      <Row>{isError && <div css={cssTestMsg}>{uploadResultMessage}</div>}</Row>
    </FormLayout>
  );
};

export const JobModalsUploadJob = ({ open, data, onClose }: JobModalsUploadJobProps) => {
  const { t } = useTranslation(['job']);

  return (
    <DraggableModal open={open} onCancel={onClose} title={t('uploadModal.title')} footer={null} destroyOnClose>
      {open && <JobModalsUploadJobContent data={data} onClose={onClose} t={t} />}
    </DraggableModal>
  );
};

export interface JobModalsUploadJobProps {
  open: boolean;
  data: Partial<Job>;
  onClose: () => void;
}

interface JobUploadFormValues {
  skipBlankRows: boolean;
  skipHashOfRows: number;
  ignoreErrors: boolean;
  importToStaging: boolean;
  // timeFormat: 'basic' | 'best_effort';
  separator: string;
  store_id: number;
  file: UploadFile[];
}

interface JobModalsUploadJobContentProps extends Pick<JobModalsUploadJobProps, 'data' | 'onClose'> {
  t: TFunction;
}

const cssUploadBtn = () => ({
  marginBottom: '12px',
});

const cssTestMsg = (): CSSObject => ({
  color: 'red',
});

const cssTooltipIcon = (): CSSObject => ({
  marginLeft: '4px',
});

const cssFileLayout = (): CSSObject => ({
  '&& div:last-child': {
    overflow: 'hidden',
  },
});

const cssFileNameLabel = (): CSSObject => ({
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});
