import { Space, Typography } from '@components/ui';
import Progress, { ProgressProps } from 'antd/es/progress';
import { CSSObject } from '@emotion/react';

export const ProgressWithTitle = ({ title, ...rest }: ProgressWithTitleProps) => (
  <div css={cssProgressLayout}>
    <div css={cssProgressTitleLayout}>{title}:</div>
    <Progress {...rest} />
  </div>
);

export const ProgressCircleWithTitle = ({ title, size, ...rest }: ProgressCircleWithTitleProps) => (
  <Space full direction="vertical" justify="center" align="center">
    <Typography.Title level={4}>{title}</Typography.Title>
    <Progress type="circle" size={size ?? 200} {...rest} />
  </Space>
);

const cssProgressLayout = (): CSSObject => ({
  display: 'flex',
  alignItems: 'center',
});

const cssProgressTitleLayout = (): CSSObject => ({
  marginRight: '1em',
  textWrap: 'nowrap',
});

interface ProgressWithTitleProps extends ProgressProps {
  title: string;
}

interface ProgressCircleWithTitleProps extends ProgressProps {
  title: string;
  size?: number;
}

export { Progress };
