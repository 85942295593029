import { Dropdown, notification, Table } from '@ui';
import { tableListToOptions } from '@shared/utils/Table';
import { dateToString } from '@shared/utils/Date';
import { useColumnSearch } from '@components/ui/table/tableHooks';
import { modelActions } from '@modules/model/duck/modelSlice';
import { useRunModelMutation } from '@modules/model/duck/modelApi';
import { ModelModalsType } from '@modules/model/modals';
import { StoreListResponse } from '@modules/stores/duck/storeApi';
import routes from '@routes';
import { ActorAndInfo } from '@components';
import { TableColumnsType, TableProps } from 'antd';
import { useMemo } from 'react';
import { TFunction } from 'i18next';
import { CSSObject, Theme } from '@emotion/react';
import { DownOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ModelListResponse } from '../ModelTypes';

export const ModelList = ({ data, storeList, loading, studyId, pagination, onChange, t }: ModelListProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [runModel] = useRunModelMutation();
  const { getColumnSearchProps, locale } = useColumnSearch<ModelListResponse['items'][0]>();
  const storeListOption = tableListToOptions(storeList);

  const items = [
    { key: 'run', label: t('rootTable.actionMenu.run') },
    { key: 'viewLog', label: t('rootTable.actionMenu.viewLog') },
    { key: 'edit', label: t('rootTable.actionMenu.edit') },
    { key: 'configure', label: t('rootTable.actionMenu.configure') },
  ];

  const columns: TableColumnsType<ModelListResponse['items'][0]> = useMemo(
    () => [
      {
        title: t('rootTable.name'),
        dataIndex: 'name',
        // TODO add tag here after adding logic for intermediate
        ...getColumnSearchProps('name'),
        onFilter: undefined,
      },
      {
        title: t('rootTable.dataStore'),
        dataIndex: 'data_store_id',
        filters: storeListOption,
        onFilter: undefined,
        render: (_, record) => record.data_store.name,
      },
      {
        title: t('rootTable.created'),
        dataIndex: 'created_at',
        sorter: (a, b) => 0,
        sortDirections: ['ascend'],
        render: (createdAt: number, record) => (
          <ActorAndInfo info={dateToString(createdAt)} actor={record.created_by} />
        ),
      },
      {
        title: t('rootTable.updated'),
        dataIndex: 'updated_at',
        sorter: (a, b) => 0,
        sortDirections: ['ascend'],
        render: (updatedAt: number, record) => (
          <ActorAndInfo info={dateToString(updatedAt)} actor={record.updated_by} />
        ),
      },
      {
        width: 90,
        title: t('rootTable.active'),
        dataIndex: 'active',
        render: (active: boolean) => (active ? 'Active' : 'Inactive'),
      },
      {
        width: 115,
        title: ' ',
        dataIndex: 'actionMenu',
        render: (_, record) => (
          <Dropdown
            menu={{
              items:
                record.spark_schema?.config && !record.spark_schema?.config?.includes('data_stage.')
                  ? items
                  : items.map((item) =>
                      item.key === 'run' ? { ...item, disabled: true, title: t('errors.notConfiguredYet') } : item,
                    ),
              onClick: async ({ key }) => {
                switch (key) {
                  case 'run':
                    await runModel(record.id);
                    notification.info({ message: t('builder.runNotification') });
                    break;
                  case 'viewLog':
                    dispatch(modelActions.pushModal({ type: ModelModalsType.openLogs, data: record }));
                    break;
                  case 'edit':
                    dispatch(modelActions.pushModal({ type: ModelModalsType.saveModel, data: record }));
                    break;
                  case 'configure':
                    navigate(routes.study.models.view.resolver({ modelId: record.id, studyId }));
                    break;
                  default:
                    break;
                }
              },
            }}
            trigger={['click']}
            placement="bottomRight"
            autoAdjustOverflow={false}
          >
            <button onClick={(e) => e.preventDefault()} css={cssActionBtn}>
              {t('rootTable.actionMenu.label')} <DownOutlined />
            </button>
          </Dropdown>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [storeListOption],
  );
  return (
    <Table
      bordered
      locale={locale}
      columns={columns}
      dataSource={data?.items}
      loading={loading}
      rowKey={(item) => item.id}
      onChange={onChange}
      tableLayout="fixed"
      scroll={{ x: 900 }}
      pagination={pagination}
    />
  );
};

export interface ModelListProps {
  studyId: number;
  data?: ModelListResponse;
  storeList?: StoreListResponse['items'];
  pagination?: TableProps<ModelListResponse['items'][0]>['pagination'];
  onChange?: TableProps<ModelListResponse['items'][0]>['onChange'];
  loading?: boolean;
  pageSize?: number;
  disableKindSort?: boolean;
  t: TFunction;
}

const cssActionBtn = (theme: Theme): CSSObject => ({
  border: 'none',
  background: 'none',
  color: theme.colorLink,
  cursor: 'pointer',
});
