import { selectGlobalLibrary } from '@app/duck/appSelectors';
import { initialPage } from '@shared/utils/common';
import { useTablePaginationState } from '@components/ui/table/tableHooks';
import { ILibraryModel, TLibraryDeleteModelProps } from '@modules/library/model/LibraryModelTypes';
import { useLibraryModelListQuery, useLibraryDeleteModelMutation } from '@modules/library/model/duck/libraryModelApi';
import { LibraryModelList } from '@modules/library/model/components/LibraryModelList';
import { libraryModelActions } from '@modules/library/model/duck/libraryModelSlice';
import { ELibraryModelModalsType } from '@modules/library/model/modals';
import { PageTemplateSimple } from '@components';
import { Button, notification } from '@ui';
import { QueryErrorType } from '@shared/utils/Error';
import { useSupportedEnvsQuery } from '@app/duck/appApi';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const LibraryModelRootPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['libraryModel']);
  const supportedEnvsQuery = useSupportedEnvsQuery();
  const globalLibrary = useSelector(selectGlobalLibrary);
  const { paginationState, getPagination, onTableChange, preparedFilters, preparedSorter } =
    useTablePaginationState<ILibraryModel>(initialPage);
  const dataModelQuery = useLibraryModelListQuery({
    detailed: '0',
    page: paginationState.current - 1,
    page_size: paginationState.pageSize,
    ...preparedFilters,
    ...preparedSorter,
  });
  const [deleteDataModel, deleteDataModelQuery] = useLibraryDeleteModelMutation();

  const pagination = getPagination(dataModelQuery.data?.totalItems);

  const copyDataModal = () => dispatch(libraryModelActions.pushModal({ type: ELibraryModelModalsType.copyModel }));

  const onDeleteDataModel = async (value: TLibraryDeleteModelProps) => {
    const { id, name } = value;
    try {
      await deleteDataModel(id).unwrap();
      notification.success({ message: t('confirmation.successMessage', { name }) });
    } catch (e) {
      console.error(e);
      notification.error({ message: (e as QueryErrorType).data.userMsg });
    }
  };

  return (
    <PageTemplateSimple
      title={{
        children: t('pageRootName'),
        extra: <Button children={t('import')} onClick={copyDataModal} />,
      }}
      content={{
        isLoading: dataModelQuery.isLoading && !dataModelQuery.data,
        errorText: t('loadingError'),
        error: dataModelQuery.error,
      }}
    >
      {!dataModelQuery.isLoading && (
        <LibraryModelList
          data={dataModelQuery.data}
          libraryId={globalLibrary?.id!}
          onChange={onTableChange}
          onDeleteDataModel={onDeleteDataModel}
          pagination={pagination}
          loading={dataModelQuery.isFetching}
          loadingDelete={deleteDataModelQuery.isLoading}
          supportedEnvs={supportedEnvsQuery.data || {}}
          t={t}
        />
      )}
    </PageTemplateSimple>
  );
};
