import { ViewerDataTable } from '@shared/components';
import { Typography } from '@ui';
import { TableListSider } from '@shared/components';
import { VerticalSplit } from '@components';
import { EditorModelPane } from '@modules/modelEditor/containers';
import { selectModelDataViewer } from '@modules/model/duck/modelSelectors';
import { modelActions } from '@modules/model/duck/modelSlice';
import { ViewerDataTableRef } from '@shared/components/ViewerDataTable/ViewerDataTableTypes';
import { CSSObject } from '@emotion/react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useRef } from 'react';

const initialPage = {
  current: 1,
  pageSize: 10,
  pageSizeOptions: [],
};

export const ModelConfigurationPage = () => {
  const dispatch = useDispatch();
  const { modelId } = useParams();
  const childRef = useRef<ViewerDataTableRef | null>(null);
  const { tableKey, tableName, opened } = useSelector(selectModelDataViewer);

  const selectDataViewerTable = useCallback((tableKey: string, tableName: string) => {
    dispatch(modelActions.setDataViewer({ tableKey, tableName }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnCollapse = useCallback(() => dispatch(modelActions.closeDataViewer()), [dispatch]);

  const handleRefetch = useCallback(() => {
    childRef.current && childRef.current.refetch();
  }, []);

  if (!modelId) {
    return <Typography.Title type="secondary" children="There is no selected Model" />;
  }

  return (
    <div css={cssBody}>
      <TableListSider
        selectedTable={tableKey}
        onSelectTable={selectDataViewerTable}
        refetch={handleRefetch}
        draggable
      />
      <VerticalSplit
        top={<EditorModelPane modelId={+modelId} />}
        bottom={
          <div css={cssContent}>
            {tableName && tableKey ? (
              // TODO: change when "DNA-1676-Uploading the same table to different stores" task will be done
              <ViewerDataTable
                ref={childRef}
                tableId={tableKey}
                tableName={tableName}
                initialPage={initialPage}
                showRowsCount
              />
            ) : (
              <div css={cssEmptyBox}>
                <Typography.Title type="secondary" children="There is no selected Table" />
              </div>
            )}
          </div>
        }
        collapsed={!opened}
        onCollapse={handleOnCollapse}
      />
    </div>
  );
};

const cssBody = (): CSSObject => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  position: 'relative',
});

const cssContent = (): CSSObject => ({
  minWidth: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  overflowX: 'hidden',
  overflowY: 'hidden',
});

const cssEmptyBox = (): CSSObject => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
