import { MenuType } from '@routes/appMenu';
import { AppstoreOutlined, GlobalOutlined } from '@ant-design/icons';
import libraryRoutes from './RoutesLibrary';

export const libraryMenu = (libraryId: number): MenuType => [
  {
    path: libraryRoutes.view.resolver({ libraryId }),
    key: libraryRoutes.view.id,
    keys: libraryInternalMenu(libraryId).map((data) => data.key),
    title: 'Library',
    icon: <GlobalOutlined />,
  },
];

export const libraryInternalMenu = (libraryId: number): MenuType => [
  {
    path: libraryRoutes.models.root.resolver({ libraryId }),
    key: libraryRoutes.models.root.id,
    title: 'Data Models',
  },
  {
    path: libraryRoutes.cdr.root.resolver({ libraryId }),
    key: libraryRoutes.cdr.root.id,
    title: 'CDR Reports',
  },
  {
    path: libraryRoutes.sqlLab.root.resolver({ libraryId }),
    key: libraryRoutes.sqlLab.root.id,
    title: 'SQL Lab Queries',
  },
  {
    path: libraryRoutes.notebook.root.resolver({ libraryId }),
    key: libraryRoutes.notebook.root.id,
    title: 'Python Notebooks',
  },
  {
    path: libraryRoutes.audit.root.resolver({ libraryId }),
    key: libraryRoutes.audit.root.id,
    title: 'Audit Log',
  },
];

export const appStudyLibraryMenu = () => [
  {
    path: libraryRoutes.root.path,
    key: libraryRoutes.root.id,
    title: 'Global Library',
    icon: <GlobalOutlined />,
  },
];

export const appStudyAnalysisPackageMenu = (studyId: number) => [
  {
    path: libraryRoutes.analysisPackage.resolver({ studyId }),
    key: libraryRoutes.analysisPackage.id,
    title: 'Analysis Package Versions',
  },
  {
    path: libraryRoutes.deploymentPlans.resolver({ studyId }),
    key: libraryRoutes.deploymentPlans.id,
    title: 'Deployment Plans',
  },
];

export const appStudyAnalysisObjectsMenu = (studyId: number) => [
  {
    path: libraryRoutes.analysisObjectsModels.root.resolver({ studyId }),
    key: libraryRoutes.analysisObjects.id,
    keys: studyAnalysisObjectsInternalMenu(studyId).map((data) => data.key),
    title: 'Analysis Objects',
    icon: <AppstoreOutlined />,
  },
];

export const studyAnalysisObjectsInternalMenu = (studyId: number): MenuType => [
  {
    path: libraryRoutes.analysisObjectsModels.root.resolver({ studyId }),
    key: libraryRoutes.analysisObjectsModels.root.id,
    title: 'Data Models',
  },
  {
    path: libraryRoutes.analysisObjectsCdr.root.resolver({ studyId }),
    key: libraryRoutes.analysisObjectsCdr.root.id,
    title: 'CDR Reports',
  },
];
