import { configuration } from '@config/constants';
import { IAppContext, IAppStudyContext } from '@app/AppTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AppStore {
  ready: boolean;
  isUnauthorized: boolean;
  isForbidden: boolean;
  innerSiderWidth: number;
  context: {
    library: null | IAppContext;
    study: null | IAppStudyContext;
  };
}

type SetContextPayload = { type: 'library' | 'study'; context: IAppContext | IAppStudyContext | null };

const initialState: AppStore = {
  ready: false,
  isUnauthorized: false,
  isForbidden: false,
  innerSiderWidth: configuration.defaultInnerSiderWidth,
  context: {
    library: null,
    study: null,
  },
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setReady: (state, action: PayloadAction<boolean>) => {
      state.ready = action.payload;
    },
    setContext: (state, { payload }: PayloadAction<SetContextPayload>) => {
      state.context = {
        ...{ library: null, study: null },
        [payload.type]: payload.context,
      };
    },
    setUnauthorized: (state, action: PayloadAction<boolean>) => {
      state.isUnauthorized = action.payload;
    },
    setForbidden: (state, action: PayloadAction<boolean>) => {
      state.isForbidden = action.payload;
    },
    setInnerSiderWidth: (state, action: PayloadAction<number>) => {
      state.innerSiderWidth = action.payload;
    },
  },
});

export const appActions = appSlice.actions;

export const appReducer = appSlice.reducer;
