import { jobActions } from '@modules/job/duck/jobSlice';
import { initialPage } from '@shared/utils/common';
import { useConnectionListQuery, useSourceListQuery } from '@modules/source/duck/sourceApi';
import { useStoreListQuery } from '@modules/stores/duck/storeApi';
import { JobModalsController, JobModalsType } from '@modules/job/modals';
import { useJobListPaginatedQuery } from '@modules/job/duck/jobApi';
import { Job } from '@modules/job/JobTypes';
import { JobList } from '@modules/job/components';
import { PageTemplateSimple } from '@components';
import { useTablePaginationState } from '@components/ui/table/tableHooks';
import { selectGlobalStudy } from '@app/duck/appSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

export const JobRootPage = () => {
  const { t } = useTranslation(['job']);
  const dispatch = useDispatch();
  const { paginationState, getPagination, onTableChange, preparedFilters, preparedSorter } =
    useTablePaginationState<Job>(initialPage);
  const globalStudy = useSelector(selectGlobalStudy);

  const jobQuery = useJobListPaginatedQuery({
    page: paginationState.current - 1,
    ...preparedFilters,
    ...preparedSorter,
  });

  const sourceListQuery = useSourceListQuery();
  const storeQuery = useStoreListQuery({ detailed: 0 });
  const connectionListQuery = useConnectionListQuery();
  const pagination = getPagination(jobQuery.data?.totalItems);

  const uploadJobHandlerWithRT = () => {
    dispatch(jobActions.pushModal({ type: JobModalsType.uploadJobRT }));
  };

  return (
    <PageTemplateSimple
      hideTitleSkeleton
      title={{
        children: t('pageRootName'),
        extra: <Button children={t('uploadRT.btnUpload')} onClick={uploadJobHandlerWithRT} />,
      }}
      content={{
        isLoading: jobQuery.isLoading && !jobQuery.data,
        errorText: t('loadingError'),
        error: jobQuery.error,
      }}
    >
      <JobModalsController />
      {jobQuery.data && (
        <JobList
          data={jobQuery.data}
          storeList={storeQuery.data}
          sourceList={sourceListQuery.data}
          connectionList={connectionListQuery.data}
          studyId={globalStudy?.id!}
          onChange={onTableChange}
          pagination={pagination}
          loading={jobQuery.isFetching}
          t={t}
        />
      )}
    </PageTemplateSimple>
  );
};
