export const OPERATORS = [
  { value: '[]' },
  { value: '()' },
  { value: '-' },
  { value: '+' },
  { value: '*' },
  { value: '/' },
  { value: '^' },
  { value: '%' },
  { value: '=' },
  { value: '<>' },
  { value: '>' },
  { value: '<' },
  { value: '>=' },
  { value: '<=' },
  { value: 'and' },
  { value: 'or' },
];

export const FUNCTIONS = [
  {
    name: 'abs',
    description: 'abs(number)',
    rawData: {
      value: 'abs()',
    },
  },
  {
    name: 'concat',
    description: 'concat([variable])',
    rawData: {
      value: 'concat()',
    },
  },
  {
    name: 'isnull',
    description: 'isnull([variable])',
    rawData: {
      value: 'isnull()',
    },
  },
  // {
  //   name: 'concatdlm',
  //   description: 'concatdlm("delimiter", [variable1], [variable2], ... , [variableN])',
  //   rawData: {
  //     value: 'concatdlm()',
  //   },
  // },
  {
    name: 'dateadd',
    description: 'dateadd([date], "units", quantity, "dateformat")',
    rawData: {
      value: 'dateadd()',
    },
  },
  {
    name: 'datediff',
    description: 'datediff([date1], [date2], "units", returnSignedValue, decimal places)',
    rawData: {
      value: 'datediff()',
    },
  },
  // {
  //   name: 'dateextract',
  //   description: 'dateextract([date], "units")',
  //   rawData: {
  //     value: 'dateextract()',
  //   },
  // },
  {
    name: 'decode',
    description: 'decode(col: [variable], charset: str)',
    rawData: {
      value: 'decode()',
    },
  },
  // {
  //   name: 'decode_driver_license_barcode',
  //   description: 'decode_driver_license_barcode([variable] | (expression), "field")',
  //   rawData: {
  //     value: 'decode_driver_license_barcode()',
  //   },
  // },
  {
    name: 'if',
    description: 'if (CONDITION, VALUE if condition is TRUE, VALUE if condition is FALSE)',
    rawData: {
      value: 'if()',
    },
  },
  {
    name: 'not',
    description: 'not(boolean)',
    rawData: {
      value: 'not()',
    },
  },
  {
    name: 'round',
    description: 'round(number,decimal places)',
    rawData: {
      value: 'round()',
    },
  },
  // {
  //   name: 'rounddownrounddown',
  //   description: 'rounddownrounddown(number,decimal places)',
  //   rawData: {
  //     value: 'rounddownrounddown()',
  //   },
  // },
  // {
  //   name: 'roundup',
  //   description: 'roundup(number,decimal places)',
  //   rawData: {
  //     value: 'roundup()',
  //   },
  // },
  {
    name: 'sqrt',
    description: 'sqrt(number)',
    rawData: {
      value: 'sqrt()',
    },
  },
  // {
  //   name: 'stdev',
  //   description: 'stdev(number,number,...)',
  //   rawData: {
  //     value: 'stdev()',
  //   },
  // },
  // {
  //   name: 'strifempty',
  //   description: "strifempty([variable]|(expression), 'value when first param is empty')",
  //   rawData: {
  //     value: 'strifempty()',
  //   },
  // },
  // {
  //   name: 'string_date_format',
  //   description: 'string_date_format("string_value", "source dateformat", "target dateformat")',
  //   rawData: {
  //     value: 'string_date_format()',
  //   },
  // },
  {
    name: 'substring',
    description: 'substring([text], beginIndex, endIndex)',
    rawData: {
      value: 'substring()',
    },
  },
  // {
  //   name: 'today',
  //   description: 'today()',
  //   rawData: {
  //     value: 'today()',
  //   },
  // },
];
