import { ModelEditor, ModelEditorNodeType } from '@modules/modelEditor/ModelEditorTypes';
import { nanoid } from 'nanoid';
import { Edge, Node, NodeProps } from 'reactflow';
import { ModelEditorStore } from './modelEditorSlice';
import { compileSparkInstruction } from './modelEditorSparkIntegration';

export const getStageData = (node: Node | NodeProps) => {
  let data: any = {
    name: node.data.name,
    tableName: node.data.tableName,
  };

  switch (node.type) {
    case ModelEditorNodeType.domain:
      data.exists = node.data.exists;
      break;
    case ModelEditorNodeType.sql:
      data.sql_schema = node.data.sql_schema;
      data.sql_statement = node.data.sql_statement;
      data.isValid = node.data.isValid;
      break;
    case ModelEditorNodeType.result:
      data.primaryKeys = node.data.primaryKeys;
      break;
    case ModelEditorNodeType.join:
      data.type = node.data.type;
      data.relations = node.data.relations;
      break;
    case ModelEditorNodeType.union:
      data.type = node.data.type;
      data.mode = node.data.mode;
      break;
    case ModelEditorNodeType.groupBy:
      data.dropGroupingColumns = node.data.dropGroupingColumns;
      data.groupBy = node.data.groupBy;
      data.aggregateFn = node.data.aggregateFn;
      break;
    case ModelEditorNodeType.pivot:
      data.groupingColumns = node.data.groupingColumns;
      data.pivotColumn = node.data.pivotColumn;
      data.aggregateFn = node.data.aggregateFn;
      data.columns = node.data.columns;
      data.translatedValues = node.data.translatedValues;
      break;
    case ModelEditorNodeType.unpivot:
      data.unchangedColumns = node.data.unchangedColumns;
      data.unpivotColumns = node.data.unpivotColumns;
      data.unpivotNames = node.data.unpivotNames;
      break;
    case ModelEditorNodeType.transform:
      data.transformation = node.data.transformation;
      break;
  }

  data.isCheckingNeeded = !!node.data.isCheckingNeeded;

  return data;
};

export const exportModelEditorData = ({
  nodes,
  edges,
  modelName,
}: Pick<ModelEditorStore, 'nodes' | 'edges' | 'modelName'>): ModelEditor => {
  const result: ModelEditor = [];

  nodes.forEach((node) => {
    result.push({
      id: node.id,
      position: { x: node.position.x, y: node.position.y },
      type: ModelEditorNodeType[node.type as keyof typeof ModelEditorNodeType],
      data: {
        ...getStageData(node),
        ...((node.type === ModelEditorNodeType.domain || node.type === ModelEditorNodeType.sql) && { modelName }),
      },
      children: [],
    });
  });

  edges.forEach((edge) => {
    const { target, source } = edge;

    result[result.findIndex((rNode) => rNode.id === target)].children.push(source);
  });

  return result;
};

export const tableNameGenerator = (modelName: string, nodeType: ModelEditorNodeType, nodeId: string) => {
  if (nodeType === ModelEditorNodeType.result) {
    return modelName;
  }
  return `${modelName}_${nodeId}`;
};

export const generateNodeId = (nodeType?: ModelEditorNodeType) => {
  if (!nodeType) {
    return nanoid(5);
  }
  return `${nodeType}_${nanoid(5)}`;
};

export const renameModelNameInSchema = (
  schema: ModelEditor,
  modelName: string,
  fallbackCHDB: string,
  cluster: string,
) => {
  const newSchema = schema.map((node) => {
    if (node.type === ModelEditorNodeType.domain) {
      return node;
    }

    const tableName = tableNameGenerator(modelName, node.type, node.id);
    return { ...node, data: { ...node.data, tableName } };
  });

  return {
    schema: newSchema,
    spark_schema: compileSparkInstruction(newSchema, modelName, fallbackCHDB, cluster),
  };
};

export const getFullTableName = (tableName: string = '', fallbackCHDB: string) => {
  const names = tableName.split('.');
  if (names[1]) {
    return tableName;
  }

  return `${fallbackCHDB}.${names[0]}`;
};

export const getCHTableNameAndDB = (tableName: string, fallbackCHDB: string) => {
  const schema = tableName.split('.');

  if (schema[1]) {
    return { tableId: schema[1], ch_db: schema[0] };
  }

  return { tableId: schema[0], ch_db: fallbackCHDB };
};

export const invalidateNodeById = (nodeId: string, nodes: Node[], edges: Edge[], skipTargetNode?: boolean) => {
  const updatedNodes = [...nodes];
  const checkedNodes = new Set();

  const invalidateNodeRecursively = (id: string) => {
    checkedNodes.add(id);
    const nodeIndex = skipTargetNode && id === nodeId ? -1 : updatedNodes.findIndex((node) => node.id === id);
    if (nodeIndex !== -1) {
      const node = updatedNodes[nodeIndex];
      updatedNodes[nodeIndex] = {
        ...node,
        data: { ...node.data, isCheckingNeeded: true },
      };
    }

    // in case if we will have several edges from one node
    const edgesOfNode = edges.filter((edge) => edge.source === id);
    edgesOfNode.forEach((el) => {
      const targetId = el.target;
      if (!checkedNodes.has(targetId)) {
        invalidateNodeRecursively(targetId);
      }
    });
  };
  invalidateNodeRecursively(nodeId);
  return updatedNodes;
};

export const invalidateNodeByEdgeId = (edgeId: string, nodes: Node[], edges: Edge[]) => {
  const findEdge = edges.find((edge) => edge.id === edgeId);
  if (!findEdge) {
    return nodes;
  }
  return invalidateNodeById(findEdge.target, nodes, edges);
};

export const sanitizeModelEditorData = (data: ModelEditor): ModelEditor =>
  data.map((node) => {
    const { isCheckingNeeded = false, isValid = true, exists = true, modelName = '', ...clearData } = { ...node.data };
    return {
      ...node,
      data: {
        ...clearData,
      },
    };
  });
