import { Alert, DraggableModal, Form, FormItem, FormLayout, Select, Space } from '@ui';
import { ModelEditorNodeUnPivot } from '@modules/modelEditor/ModelEditorTypes';
import { tableInfoMetaToOptions } from '@modules/modelEditor/modals/utils';
import { useSourceTableInfoAnalyzer } from '@modules/modelEditor/duck/modelEditorSourceTableInfoAnalyzer';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useMemo } from 'react';
import { useSaveStage } from './modelEditorModalsHooks';

const ModelEditorModalsUnPivotSettingsContent = ({
  data,
  onClose,
  t,
}: ModelEditorModalsUnPivotSettingsContentProps) => {
  const [form] = Form.useForm();
  const { onSubmit } = useSaveStage(data.nodeId, onClose);
  const { loading, sourceColumns } = useSourceTableInfoAnalyzer(data.nodeId);

  const fieldOptions = useMemo(() => {
    const columnsNames = tableInfoMetaToOptions(sourceColumns[0]);

    return {
      columnsNames,
      variables: columnsNames,
    };
  }, [sourceColumns]);

  const nodeErrors = [];

  if (!loading) {
    if (sourceColumns[0] === undefined) {
      nodeErrors.push(t('unpivot.errors.missingSource'));
    }
  }

  const initValues = data.initData || {};

  return (
    <FormLayout
      form={form}
      layout="horizontal"
      onCancel={onClose}
      onSubmit={onSubmit}
      okText={t('save')}
      initialValues={initValues}
      submitIsDisabled={!!nodeErrors.length || loading}
    >
      <FormItem
        name="unchangedColumns"
        label={t('unpivot.unchangedColumns')}
        rules={[
          {
            required: true,
            validator: (rule, value: string[] = [], callback) => {
              if (!value.length) {
                return Promise.reject(new Error(t('unpivot.errors.missingUnchangedColumns')));
              }
              if (!loading && value.filter((v) => !fieldOptions.columnsNames.some((item) => item.value === v)).length) {
                return Promise.reject(new Error(t('unpivot.errors.wrongColumns')));
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Select
          mode="multiple"
          placeholder={t('unpivot.unchangedColumnsPlaceholder')}
          options={fieldOptions.columnsNames}
          loading={loading}
        />
      </FormItem>
      <FormItem
        name="unpivotColumns"
        label={t('unpivot.unpivotColumns')}
        extra={t('unpivot.unpivotColumnsHint')}
        rules={[
          {
            required: true,
            validator: (rule, value: string[] = [], callback) => {
              if (!value.length) {
                return Promise.reject(new Error(t('unpivot.errors.missingUnpivotColumns')));
              }
              if (!loading && value.filter((v) => !fieldOptions.columnsNames.some((item) => item.value === v)).length) {
                return Promise.reject(new Error(t('unpivot.errors.wrongColumns')));
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Select
          mode="multiple"
          placeholder={t('unpivot.unpivotColumnsPlaceholder')}
          options={fieldOptions.columnsNames}
          loading={loading}
        />
      </FormItem>
      <FormItem
        full
        label={t('unpivot.unpivotNames')}
        extra={t('unpivot.unpivotNamesHint')}
        name="unpivotNames"
        rules={[
          {
            required: true,
            validator: (rule, value: string[] = [], callback) => {
              if (value.length === 0) {
                return Promise.reject(new Error(t('unpivot.errors.missingUnpivotNames')));
              }
              if (value.length === 1) {
                return Promise.reject(new Error(t('unpivot.errors.missingUnpivotNamesValue')));
              }
              if (value.length > 2) {
                return Promise.reject(new Error(t('unpivot.errors.missingUnpivotNamesExtra')));
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Select mode="tags" placeholder={t('unpivot.unpivotNamesPlaceholder')} loading={loading} />
      </FormItem>
      {!!nodeErrors.length && (
        <FormItem wrapperCol={{ span: 24 }}>
          <Alert message={<Space direction="vertical" children={nodeErrors} />} type="error" />
        </FormItem>
      )}
    </FormLayout>
  );
};

export const ModelEditorModalsUnPivotSettings = ({ open, data, onClose }: ModelEditorModalsUnPivotSettingsProps) => {
  const { t } = useTranslation(['model']);

  return (
    <DraggableModal open={open} onCancel={onClose} title={t('unpivot.title')} width={700} footer={null} destroyOnClose>
      {open && <ModelEditorModalsUnPivotSettingsContent data={data} onClose={onClose} t={t} />}
    </DraggableModal>
  );
};

interface ModelEditorModalsUnPivotSettingsContentProps
  extends Pick<ModelEditorModalsUnPivotSettingsProps, 'data' | 'onClose'> {
  t: TFunction;
}

export interface ModelEditorModalsUnPivotSettingsProps {
  open: boolean;
  data: { nodeId: string; initData?: ModelEditorNodeUnPivot };
  onClose: () => void;
}
