// import { removeAppLoader } from 'app/duck/appUtils';
import { Button, Modal, Result, Space } from '@ui';
import { configuration } from '@config/constants';
import { appActions } from '@app/duck/appSlice';
import { selectAppIsForbidden, selectAppIsUnauthorized } from '@app/duck/appSelectors';
import { appAxios } from '@config/AppConfig';
import { useSetAuthReferrer } from '@modules/auth/duck/authHooks';
import { UserApiRoutes } from '@modules/user/duck/userApi';
import { useTranslation } from 'react-i18next';
import { ResultProps } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef } from 'react';
import { ReactComponent as UnauthorizedIcon } from './unauthorized-icon.svg';

export const AppPermissionControlHandler = () => {
  const autoLoginRef = useRef(true);
  const { setAuthReferrerToSearchParams } = useSetAuthReferrer();
  const dispatch = useDispatch();

  useEffect(() => {
    const interceptorId = appAxios.interceptors.response.use(
      (response) => {
        if (autoLoginRef.current && response.config.url?.startsWith(UserApiRoutes.currentUser)) {
          autoLoginRef.current = false;
        }
        return response;
      },
      (error) => {
        if (error.response?.status === 401) {
          setAuthReferrerToSearchParams();
          // Hack to do auto redirect to login-sso when this is a first unauthorize request after refresh
          if (autoLoginRef.current === true) {
            window.location.replace(`${configuration.loginURL}${window.location.search}`);
          } else {
            dispatch(appActions.setUnauthorized(true));
          }
        } else if (error.response?.status === 403) {
          setAuthReferrerToSearchParams();
          dispatch(appActions.setForbidden(true));
        }

        return Promise.reject(error);
      },
    );

    return () => {
      appAxios.interceptors.response.eject(interceptorId);
    };
  }, [dispatch, setAuthReferrerToSearchParams]);

  return <AppPermissionControl />;
};

export const AppPermissionControl = () => {
  const isUnauthorized = useSelector(selectAppIsUnauthorized);
  const isForbidden = useSelector(selectAppIsForbidden);
  const { t } = useTranslation(['shared']);

  let props: ResultProps = {
    icon: <UnauthorizedIcon />,
    title: t('permission.sessionExpired'),
    subTitle: t('permission.sessionExpiredDescription'),
    extra: (
      <Space>
        <Button
          type="primary"
          children={t('signIn')}
          onClick={() => window.location.replace(`${configuration.loginURL}${window.location.search}`)}
        />
        <Button
          type="primary"
          children={t('changeUser')}
          onClick={() => window.location.replace(`${configuration.logoutURL}${window.location.search}`)}
        />
      </Space>
    ),
  };

  if (isForbidden) {
    props = {
      status: '403',
      title: t('permission.accessDenied'),
      subTitle: (
        <span
          dangerouslySetInnerHTML={{
            __html: t('permission.accessDeniedDescription'),
          }}
        />
      ),
      extra: <Button type="primary" children={t('returnToHome')} onClick={() => window.location.replace('/')} />,
    };
  }

  return (
    <Modal open={isForbidden || isUnauthorized} closable={false} style={{ top: 20 }} footer={false} width={600}>
      <Result {...props} />
    </Modal>
  );
};
