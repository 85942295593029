import { ViewerModalsPushModalPayload, ViewerModalsState } from '@modules/viewer/modals';
import { ISnapshot } from '@modules/snapshot/SnapshotTypes';
import { appActions } from '@app/duck/appSlice';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { Key } from 'react';

const initialState: ViewerStore = {
  modals: { selectedModal: null },
  groups: {},
  expanded: {},
  leftSide: {},
  snapshot: null,
};

export const viewerSlice = createSlice({
  name: 'viewer',
  initialState,
  reducers: {
    pushModal: (state, action: PayloadAction<ViewerModalsPushModalPayload>) => {
      const { type, data } = action.payload;
      state.modals = { selectedModal: type, data };
    },
    dropModal: (state, action: PayloadAction<void>) => {
      state.modals = initialState.modals;
    },
    setGroup: (state, action: PayloadAction<ViewerSetGroupPayload>) => {
      const { studyId, group, data } = action.payload;

      state.groups = {
        ...state.groups,
        [studyId]: {
          ...(state.groups[studyId] || {}),
          [group]: data,
        },
      };
    },
    setLeftSideData: (state, action: PayloadAction<Partial<ILeftSideData>>) => {
      const { tableKey, tableName } = action.payload;
      state.leftSide = {
        tableKey,
        tableName,
      };
    },
    updateExpanded: (state, action: PayloadAction<ViewerUpdateExpandedPayload>) => {
      const { studyId, keys } = action.payload;
      if (!isEqual(state.expanded[studyId], keys)) {
        state.expanded = {
          ...state.expanded,
          [studyId]: keys,
        };
      }
    },
    setSnapshot: (state, action: PayloadAction<ISnapshot & { value?: string }>) => {
      state.snapshot = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(appActions.setContext, (state, action) => {
        state.leftSide = initialState.leftSide;
        state.snapshot = initialState.snapshot;
        state.modals = initialState.modals;
      })
      .addDefaultCase((state, action) => {});
  },
});

export const viewerActions = viewerSlice.actions;

export const viewerReducer = viewerSlice.reducer;

export type ViewerGroupType = 'external' | 'internal' | 'rccGroups' | 'crossStudyRT' | 'studyRT';

export interface ViewerStore {
  modals: ViewerModalsState;
  groups: Record<string, Record<ViewerGroupType, ViewerGroup[]>>;
  expanded: Record<number | string, Key[]>;
  leftSide: Partial<ILeftSideData>;
  snapshot: (Pick<ISnapshot, 'description' | 'createdAt' | 'tablesDetails' | 'protocolId'> & { value?: string }) | null;
}

export interface ViewerGroup<IDType = number | string> {
  id: IDType;
  name: string;
  tables?: ViewerGroup<string>[];
}

interface ViewerSetGroupPayload {
  studyId: number;
  group: ViewerGroupType;
  data: ViewerGroup[];
}

interface ViewerUpdateExpandedPayload {
  studyId: number;
  keys: Key[];
}

interface ILeftSideData {
  tableKey: string | null;
  tableName: string | null;
}
