import { DraggableModal, Form, FormItem, FormLayout, Select, Space, Typography } from '@ui';
import { ModelEditorNodeJoin, ModelEditorNodeJoinType } from '@modules/modelEditor/ModelEditorTypes';
import { listToOptions } from '@shared/utils/Form';
import { useSourceTableInfoAnalyzer } from '@modules/modelEditor/duck/modelEditorSourceTableInfoAnalyzer';
import { findNodeById, findSourceNodesByNodeId } from '@modules/modelEditor/components/builder/Utils';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Row, Col } from 'antd';
import { useSaveStage } from './modelEditorModalsHooks';

const joinOptions = listToOptions(Object.values(ModelEditorNodeJoinType), true);

const ModelEditorModalsJoinSettingsContent = ({ data, onClose, t }: ModelEditorModalsJoinSettingsContentProps) => {
  const [form] = Form.useForm();
  const { onSubmit } = useSaveStage(data.nodeId, onClose);
  const { loading, sourceColumns, edges, nodes } = useSourceTableInfoAnalyzer(data.nodeId);
  const sourceFields = sourceColumns[0]?.map((item) => item.name) || [];
  const commonFields = sourceColumns[1]?.map((item) => item.name).filter((item) => sourceFields.includes(item)) || [];

  const fields = listToOptions(commonFields);

  const sourceNodes = findSourceNodesByNodeId(data.nodeId, edges);

  const sourceLeft = findNodeById(nodes, sourceNodes[0]);
  const sourceRight = findNodeById(nodes, sourceNodes[1]);

  const initValues = data.initData || {};

  return (
    <FormLayout
      form={form}
      layout="vertical"
      onCancel={onClose}
      onSubmit={onSubmit}
      okText={t('save')}
      initialValues={initValues}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      submitIsDisabled={loading}
    >
      <Row gutter={8}>
        <Col span={6}>
          <Form.Item name="type" label={t('join.type')} rules={[{ required: true }]}>
            <Select options={joinOptions} placeholder={t('join.select')} />
          </Form.Item>
        </Col>
        <Col span={6} />
        <Col span={12}>
          <Space direction="vertical">
            <Typography.Text>
              {t('join.leftSource')}: <strong>{sourceLeft?.data?.tableName}</strong>
            </Typography.Text>
            <Typography.Text>
              {t('join.rightSource')}: <strong>{sourceRight?.data?.tableName}</strong>
            </Typography.Text>
          </Space>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={24}>
          <Form.Item
            name="relations"
            label={t('join.relations')}
            rules={[
              {
                required: true,
                validator: (rule, value: string[] = [], callback) => {
                  if (!loading && value.filter((v) => !fields.some((item) => item.value === v)).length) {
                    return Promise.reject(new Error(t('join.errors.wrongColumns')));
                  }
                  if (!value.length) {
                    return Promise.reject(new Error(t('join.errors.missingRelations')));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Select mode="multiple" options={fields} placeholder={t('join.select')} loading={loading} />
          </Form.Item>
          {commonFields.length === 0 && !loading && (
            <FormItem>
              <Typography.Text type="danger">{t('join.errors.noCommonFields')}</Typography.Text>
            </FormItem>
          )}
        </Col>
      </Row>
    </FormLayout>
  );
};

export const ModelEditorModalsJoinSettings = ({ open, data, onClose }: ModelEditorModalsJoinSettingsProps) => {
  const { t } = useTranslation(['model']);

  return (
    <DraggableModal open={open} onCancel={onClose} title={t('join.title')} footer={null} destroyOnClose>
      {open && <ModelEditorModalsJoinSettingsContent data={data} onClose={onClose} t={t} />}
    </DraggableModal>
  );
};

interface ModelEditorModalsJoinSettingsContentProps
  extends Pick<ModelEditorModalsJoinSettingsProps, 'data' | 'onClose'> {
  t: TFunction;
}

export interface ModelEditorModalsJoinSettingsProps {
  open: boolean;
  data: { nodeId: string; initData?: ModelEditorNodeJoin };
  onClose: () => void;
}
