import { capitalizeFirstLetter } from '@shared/utils/Form';
import { uniqBy } from 'lodash';

export const tableListToOptions = (arr?: Array<{ name: string; id: number | string }>) =>
  uniqBy(
    arr?.map((item) => ({
      text: item.name,
      value: item.id,
    })) || [],
    'value',
  );

export const tableNamesToOptions = (arr?: Array<{ [key: string]: any }>, field: string = 'name') =>
  [...new Set((arr || []).map((item) => item[field || '']))].map((value) => ({ text: value, value }));

export const getTableListOption = (obj: Record<string, string>, capitalizeLabel: boolean = false) =>
  Object.values(obj).map((value) => ({
    value,
    text: capitalizeLabel ? capitalizeFirstLetter(value) : value,
  }));
