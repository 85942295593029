import { appApi } from '@config/appApi';
import { MODEL_TAG_DESCRIPTION } from '@modules/model/duck/modelConstants';
import {
  ModelListQueryParams,
  ModelListResponse,
  ModelLogsType,
  ModelQuery,
  ModelResponse,
  RunSqlQueryParams,
  RunSqlQueryResponse,
} from '@modules/model/ModelTypes';
import { StoreInvalidations } from '@modules/stores/duck/storeApi';
import { ViewerInvalidations } from '@modules/viewer/duck/viewerApi';
import { AuditInvalidations } from '@modules/audit/duck/auditApi';
import { TagDescription } from '@reduxjs/toolkit/query/react';

export const ModelApiRoutes = {
  list: `api/models`,
  run: (modelId: number) => `api/models/${modelId}/run`,
  model: (modelId: number) => `api/models/${modelId}`,
  logs: (modelId: number) => `api/models/${modelId}/logs`,
  runSql: 'api/data/sql/preview',
};

export const ModelInvalidations: {
  ID: (modelId: number) => TagDescription<MODEL_TAG_DESCRIPTION.ID>;
  LIST: TagDescription<MODEL_TAG_DESCRIPTION.LIST>;
  LOGS: (modelId: number) => TagDescription<MODEL_TAG_DESCRIPTION.LOGS>;
} = {
  ID: (modelId: number) => ({ type: MODEL_TAG_DESCRIPTION.ID, modelId }),
  LIST: { type: MODEL_TAG_DESCRIPTION.LIST, id: 'LIST' },
  LOGS: (modelId: number) => ({ type: MODEL_TAG_DESCRIPTION.LOGS, modelId }),
};

export const ModelApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    model: builder.query<ModelResponse, number>({
      providesTags: (request, error, modelId) => [ModelInvalidations.ID(modelId)],
      query: (modelId) => ({
        url: ModelApiRoutes.model(modelId),
      }),
    }),
    modelListPaginated: builder.query<ModelListResponse, ModelListQueryParams | void>({
      providesTags: [ModelInvalidations.LIST],
      query: (params) => ({
        params,
        url: ModelApiRoutes.list,
      }),
    }),
    modelList: builder.query<ModelListResponse['items'], ModelListQueryParams | void>({
      providesTags: [ModelInvalidations.LIST],
      query: (params) => ({
        params,
        url: ModelApiRoutes.list,
      }),
    }),
    modelLogs: builder.query<ModelLogsType, number>({
      providesTags: (request, error, modelId) => [ModelInvalidations.LOGS(modelId)],
      query: (modelId) => ({ url: ModelApiRoutes.logs(modelId) }),
    }),
    deleteModel: builder.mutation<void, number>({
      invalidatesTags: (request, error, modelId) => [
        ModelInvalidations.LIST,
        ModelInvalidations.ID(modelId),
        StoreInvalidations.LIST,
        AuditInvalidations.LIST,
        ViewerInvalidations.TABLES_EXIST,
        ViewerInvalidations.TABLES_IN_SQL_EXIST,
      ],
      query: (modelId) => ({
        method: 'DELETE',
        url: ModelApiRoutes.model(modelId),
      }),
    }),
    runModel: builder.mutation<void, number>({
      invalidatesTags: (request, error, modelId) => [
        StoreInvalidations.LIST,
        ViewerInvalidations.TABLES_EXIST,
        ViewerInvalidations.TABLES_IN_SQL_EXIST,
        ModelInvalidations.LOGS(modelId),
      ],
      query: (modelId) => ({
        method: 'POST',
        url: ModelApiRoutes.run(modelId),
      }),
    }),
    saveModel: builder.mutation<void, ModelQuery>({
      invalidatesTags: (request, error, data) =>
        data.id
          ? [
              ModelInvalidations.LIST,
              ModelInvalidations.ID(data.id),
              StoreInvalidations.LIST,
              data.name ? ViewerInvalidations.TABLE_INFO(data.name!) : ViewerInvalidations.ALL_TABLE_INFO,
              AuditInvalidations.LIST,
            ]
          : [ModelInvalidations.LIST, StoreInvalidations.LIST, AuditInvalidations.LIST],
      query: (data) => ({
        data,
        method: data.id ? 'PUT' : 'POST',
        url: data.id ? ModelApiRoutes.model(data.id) : ModelApiRoutes.list,
      }),
    }),
    runSql: builder.query<RunSqlQueryResponse, RunSqlQueryParams>({
      keepUnusedDataFor: 0,
      query: ({ sql_statement, size }) => ({
        data: { sql_statement },
        params: { size },
        method: 'POST',
        url: ModelApiRoutes.runSql,
      }),
    }),
  }),
});

export const {
  useModelListPaginatedQuery,
  useModelLogsQuery,
  useDeleteModelMutation,
  useSaveModelMutation,
  useModelQuery,
  useRunModelMutation,
  useLazyRunSqlQuery,
  useLazyModelListQuery,
  useRunSqlQuery,
} = ModelApi;
