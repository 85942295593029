import { AnyAction, CombinedState, Reducer } from 'redux';
import { libraryAuditReducer } from './audit/duck/libraryAuditSlice';
import { libraryCdrReducer } from './cdr/duck/libraryCdrSlice';
import { librarySqlLabReducer } from './sqlLab/duck/librarySqlLabSlice';
import { libraryNotebookReducer } from './notebook/duck/libraryNotebookSlice';
import { libraryModelReducer } from './model/duck/libraryModelSlice';
import { libraryReducer } from './root/duck/librarySlice';
import { analysisPackageReducer } from './analysisPackage/duck/analysisPackageSlice';
import { analysisObjectModelReducer } from './analysisObjects/model/duck/analysisObjectModelSlice';
import { analysisObjectCDRReducer } from './analysisObjects/cdr/duck/analysisObjectCDRSlice';

export const libraryReducers = {
  library: libraryReducer,
  libraryModel: libraryModelReducer,
  libraryCdr: libraryCdrReducer,
  librarySqlLab: librarySqlLabReducer,
  libraryNotebook: libraryNotebookReducer,
  libraryAudit: libraryAuditReducer,
  analysisPackage: analysisPackageReducer,
  analysisObjectModel: analysisObjectModelReducer,
  analysisObjectCDR: analysisObjectCDRReducer,
};
export type LibraryRootStage = ReturnType<
  Reducer<
    CombinedState<{
      library: ReturnType<typeof libraryReducer>;
      libraryModel: ReturnType<typeof libraryModelReducer>;
      libraryCdr: ReturnType<typeof libraryCdrReducer>;
      librarySqlLab: ReturnType<typeof librarySqlLabReducer>;
      libraryNotebook: ReturnType<typeof libraryNotebookReducer>;
      libraryAudit: ReturnType<typeof libraryAuditReducer>;
      analysisPackage: ReturnType<typeof analysisPackageReducer>;
      analysisObjectModel: ReturnType<typeof analysisObjectModelReducer>;
      analysisObjectCDR: ReturnType<typeof analysisObjectCDRReducer>;
    }>,
    AnyAction
  >
>;
