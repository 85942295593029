import { Checkbox, DatePicker, FormItem, Radio, Select, Space, TimePicker } from '@ui';
import { RangeNumberPicker } from '@components/form';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  PERIODICALLY_EVERY_OPTIONS,
  PERIODICALLY_HOUR_OPTIONS,
  PERIODICALLY_MONTHS,
  PERIODICALLY_WEEKDAYS,
} from './schedulerRunFormConstants';

import { SchedulerRunFormValues } from './schedulerRunFormTypes';
import { changePartionallyFields, getDaysInMonths } from './schedulerRunFormUtils';

interface SchedulerRunFormProps {
  isDisabledProp: boolean;
}

interface ICustomInputProps<Values = any> {
  value?: Values;
  disabled?: boolean;
  onChange?: (value: any) => void;
  t: TFunction;
}

const initPeriodicallyFields = changePartionallyFields({} as any, {
  name: 'every',
  value: 0,
});

const PeriodicallyFields = ({
  value,
  disabled,
  onChange,
  t,
}: ICustomInputProps<SchedulerRunFormValues['runPeriodically']>) => {
  const triggerChange = (field: { name: string; value: any }) => {
    onChange?.(changePartionallyFields(value, field));
  };

  return (
    <div style={{ marginBottom: '-24px' }}>
      <FormItem label={t('scheduler.every')}>
        <div style={{ display: 'flex', gap: '8px' }}>
          {value?.every === PERIODICALLY_EVERY_OPTIONS.hour.value && (
            <Select
              value={value?.hour}
              options={PERIODICALLY_HOUR_OPTIONS}
              disabled={disabled}
              onChange={(v) => triggerChange({ name: 'hour', value: v })}
              className="schedulerJobsEditor_hourField"
            />
          )}
          <Select
            value={value?.every}
            disabled={disabled}
            options={Object.values(PERIODICALLY_EVERY_OPTIONS)}
            onChange={(v) => triggerChange({ name: 'every', value: v })}
          />
        </div>
      </FormItem>
      {value?.every === PERIODICALLY_EVERY_OPTIONS.year.value && (
        <FormItem label="In:">
          <Select
            placeholder={t('scheduler.everyMonth')}
            mode="multiple"
            value={value?.inMonth}
            options={PERIODICALLY_MONTHS}
            disabled={disabled}
            onChange={(v) => triggerChange({ name: 'inMonth', value: v })}
            allowClear
          />
        </FormItem>
      )}
      {[PERIODICALLY_EVERY_OPTIONS.month.value, PERIODICALLY_EVERY_OPTIONS.year.value].includes(value!.every) && (
        <FormItem label="On:">
          <RangeNumberPicker
            placeholder={t('scheduler.everyDayOfTheMonth')}
            range={getDaysInMonths(value?.inMonth || [])}
            value={value?.onDays}
            disabled={disabled}
            onChange={(v) => triggerChange({ name: 'onDays', value: v })}
          />
        </FormItem>
      )}
      <FormItem>
        <Checkbox.Group
          value={value?.weekdays}
          disabled={disabled}
          onChange={(v) => triggerChange({ name: 'weekdays', value: v })}
        >
          <Space size={[4, 8]} wrap>
            {PERIODICALLY_WEEKDAYS.map((d) => (
              <div style={{ minWidth: '150px' }} key={d.value}>
                <Checkbox value={d.value.toString()} disabled={disabled}>
                  {d.label}
                </Checkbox>
              </div>
            ))}
          </Space>
        </Checkbox.Group>
      </FormItem>
      {value?.every === PERIODICALLY_EVERY_OPTIONS.hour.value && (
        <div className="schedulerJobsEditor_rangeTimeBox">
          <FormItem label={t('scheduler.from')}>
            <TimePicker
              format="h:mm A"
              value={value?.fromTime ? dayjs(value?.fromTime) : undefined}
              disabled={disabled}
              onChange={(v) => triggerChange({ name: 'fromTime', value: v?.valueOf() })}
              style={{ width: '100%' }}
            />
          </FormItem>
          <FormItem label={t('scheduler.to')}>
            <TimePicker
              format="h:mm A"
              value={value?.toTime ? dayjs(value?.toTime) : undefined}
              disabled={disabled}
              onChange={(v) => triggerChange({ name: 'toTime', value: v?.valueOf() })}
              style={{ width: '100%' }}
            />
          </FormItem>
        </div>
      )}
      {value?.every !== PERIODICALLY_EVERY_OPTIONS.hour.value && (
        <FormItem label={t('scheduler.atTime')}>
          <TimePicker
            format="h:mm A"
            value={value?.atTime ? dayjs(value?.atTime) : undefined}
            disabled={disabled}
            onChange={(v) => triggerChange({ name: 'atTime', value: v?.valueOf() })}
          />
        </FormItem>
      )}
    </div>
  );
};

export const SchedulerRunForm = ({}: SchedulerRunFormProps) => {
  const { t } = useTranslation('datastore');

  return (
    <div>
      <FormItem name={['job', 'run_once']}>
        <Radio.Group>
          <Radio value="once">{t('scheduler.once')}</Radio>
          <Radio value="periodically">{t('scheduler.periodically')}</Radio>
        </Radio.Group>
      </FormItem>
      <FormItem dependencies={[['job', 'run_once']]} asInfo>
        {(form) =>
          form.getFieldValue(['job', 'run_once']) === 'once' ? (
            <FormItem
              name={['job', 'runOnceAt']}
              noStyle
              normalize={(value: any) => value?.valueOf()}
              getValueProps={(value) => ({
                value: value ? dayjs(value) : undefined,
              })}
              rules={[{ required: true, message: t('fieldRequired') }]}
            >
              <DatePicker
                style={{ width: '100%' }}
                showTime={{
                  format: 'h:mm A',
                }}
                showSecond={false}
                showNow={false}
                format="MMM D, YYYY h:mm A"
              />
            </FormItem>
          ) : (
            <FormItem name={['job', 'runPeriodically']} initialValue={initPeriodicallyFields} asInfo>
              <PeriodicallyFields t={t} />
            </FormItem>
          )
        }
      </FormItem>
    </div>
  );
};
