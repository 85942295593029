import { RootState } from '@store';

export const selectStudyStore = (state: RootState) => state.study;

export const selectStudyAppReady = (state: RootState) => state.study.ready;

export const selectStudyAppPreloaded = (state: RootState) => state.study.preloaded;

export const selectStudyFallbackCHDB = (state: RootState) => state.study.fallbackCHDB;

export const selectStudyModals = (state: RootState) => state.study.modals;

export const selectStudyActiveUserRole = (state: RootState) => state.study.activeUserRole;
