import { DataTable, Loader, QueryError } from '@components';
import { ViewerModalsController } from '@modules/viewer/modals';
import { Button, Pagination, Space, Tag, Typography } from '@ui';
import { CSSObject } from '@emotion/react';
import { ForwardedRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ViewerDataTableFilter } from './ViewerDataTableFilter';
import { useDataViewerTable } from './useDataViewerTable';
import { ViewerDataTableRef } from './ViewerDataTableTypes';

export const ViewerDataTable = forwardRef<ViewerDataTableRef, ViewerDataTableProps>(
  (props: ViewerDataTableProps, ref: ForwardedRef<ViewerDataTableRef>) => {
    const { t } = useTranslation();

    const {
      tableId,
      appliedSnapshot,
      queryData,
      pagination,
      dataIsNotFilled,
      dataTableSource,
      appliedFilters,
      exportTable,
      onShowSizeChange,
      onTableChange,
      setAppliedFilters,
    } = useDataViewerTable(props, ref);

    const isSnapshotted = appliedSnapshot.snapshotTableId && (
      <Tag css={cssTag} color="geekblue">
        {t('snapshotted').toUpperCase()}
      </Tag>
    );
    const isFiltered = queryData.data?.options.filtered && (
      <Tag css={cssTag} color="purple">
        {t('filtered').toUpperCase()}
      </Tag>
    );

    const title = (
      <Space align="center" full>
        {props.tableName}
        {isSnapshotted}
        {isFiltered}
      </Space>
    );

    return (
      <>
        {props.showHeader && (
          <>
            <Space css={cssHeader} size="middle" direction="horizontal" align="center" full>
              <Typography.Title level={3}>{title}</Typography.Title>
              <Button children={t('export')} onClick={exportTable} disabled={dataIsNotFilled || queryData.isLoading} />
            </Space>

            <ViewerDataTableFilter
              data={queryData.data}
              appliedFilters={appliedFilters}
              setAppliedFilters={setAppliedFilters}
              tableId={tableId}
            />
          </>
        )}
        <div css={cssBody}>
          {queryData.isLoading && <Loader mode="absolute" />}
          {dataIsNotFilled ? (
            <div css={cssEmptyBox}>
              <Typography.Title type="secondary" children="Data is not filled yet" />
            </div>
          ) : (
            <>
              {queryData.error && <QueryError error={queryData.error!} title={t('viewer.dataError')} />}
              {queryData.data && props.showPagination && (
                <Pagination
                  css={cssPaginationLayout}
                  showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                  onChange={onTableChange}
                  onShowSizeChange={onShowSizeChange}
                  disabled={queryData.isLoading}
                  showSizeChanger
                  {...pagination}
                />
              )}
              {queryData.data && (
                <>
                  {props.showRowsCount && (
                    <Typography.Text type="secondary">
                      First {dataTableSource.rows.length} rows returned
                    </Typography.Text>
                  )}
                  <DataTable {...dataTableSource} />
                </>
              )}
            </>
          )}
        </div>
        <ViewerModalsController />
      </>
    );
  },
);

interface ViewerDataTableProps {
  tableId: string;
  tableName: string;
  showRowsCount?: boolean;
  showHeader?: boolean;
  initialPage?: { current: number; pageSize: number; pageSizeOptions: number[] };
  showPagination?: boolean;
  showTracedButtons?: boolean;
}

const cssBody = (): CSSObject => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  position: 'relative',
  flexDirection: 'column',
  overflowX: 'auto',
  overflowY: 'auto',
});

const cssHeader = (): CSSObject => ({
  margin: '16px',
  '&& .ant-typography': { marginBottom: 0 },
});

const cssPaginationLayout = (): CSSObject => ({
  marginBottom: '8px',
  marginLeft: '16px',
});

const cssEmptyBox = (): CSSObject => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const cssTag = (): CSSObject => ({
  marginRight: 0,
});
