import { CSSObject, Theme } from '@emotion/react';
import DataGrid, { DataGridProps } from 'react-data-grid';
import 'react-data-grid/lib/styles.css';

export const renderCellTypedValue = (value: any) => {
  switch (typeof value) {
    case 'boolean':
      return <span css={cssBoolean}>{value.toString()}</span>;
    case 'number':
      return <span css={cssNumber}>{value}</span>;
    default:
      return <span>{value}</span>;
  }
};

export function DataTable<DataType extends Record<string, any>>({
  columns,
  rows,
  rowHeight,
}: DataTableProps<DataType>) {
  return (
    <DataGrid
      columns={columns}
      rows={rows}
      css={cssBody}
      rowHeight={rowHeight}
      defaultColumnOptions={{ resizable: true, width: 'min-content', maxWidth: 300 }}
      className="rdg-light"
    />
  );
}

export interface DataTableProps<DataType> extends DataGridProps<DataType> {}

const cssBody = (): CSSObject => ({
  width: '100%',
  height: '100%',
});

const cssNumber = (theme: Theme) => ({
  color: theme.cyan9,
});

const cssBoolean = (theme: Theme) => ({
  color: theme.blue9,
});
