import { ModelEditorSchemaEditor } from '@modules/modelEditor/components';
import { Modal, FormLayout, Form, FormItem, Alert, Space } from '@ui';
import { ModelEditorNodeTransformation } from '@modules/modelEditor/ModelEditorTypes';
import { modelEditorActions } from '@modules/modelEditor/duck/modelEditorSlice';
import { selectModelEditorSchemaEditor } from '@modules/modelEditor/duck/modelEditorSelectors';
import { exportSchemaData } from '@modules/modelEditor/components/schemaEditor/ModelEditorSchemaEditorUtils';
import { useSourceTableInfoAnalyzer } from '@modules/modelEditor/duck/modelEditorSourceTableInfoAnalyzer';
import { useSelector } from 'react-redux';
import { CSSObject } from '@emotion/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useSaveStage } from './modelEditorModalsHooks';

const ModelEditorModalsSchemaSettingsContent = ({ data, t, onClose }: ModelEditorModalsSchemaSettingsContentProps) => {
  const [form] = Form.useForm();
  const [nodeErrors, setNodeErrors] = useState<string[]>([]);
  const transformation = useSelector(selectModelEditorSchemaEditor)!;
  const { loading, sourceColumns } = useSourceTableInfoAnalyzer(data.nodeId);
  const { onSubmit, dispatch } = useSaveStage(data.nodeId, onClose);

  const onSave = async () => {
    setNodeErrors([]);
    const validColumns = transformation
      .filter(({ deleted }) => !deleted)
      .map((item) => ({
        name: item.newName || item.name || '',
        position: item.index,
        expression: item.expression,
        custom: item.custom,
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
    const errors: string[] = [];

    validColumns.forEach((current, index) => {
      if (!current.name) {
        errors.push(t('schema.errors.missingColumnName', { position: current.position + 1 }));
      } else if (current.custom && !current.expression) {
        errors.push(t('schema.errors.missingExpression', { position: current.position + 1 }));
      } else if (index !== 0 && validColumns[index - 1].name === current.name) {
        errors.push(t('schema.errors.duplicatedColumn', { position: current.position + 1, name: current.name }));
      }
    });

    if (errors.length) {
      setNodeErrors(errors);
    } else {
      onSubmit({ transformation: exportSchemaData(transformation) }, () =>
        dispatch(modelEditorActions.destroySchemaEditor()),
      );
    }
  };

  return (
    <FormLayout form={form} onCancel={onClose} onSubmit={onSave}>
      <FormItem shouldUpdate wrapperCol={{ span: 24 }}>
        {() => (
          <ModelEditorSchemaEditor
            tableInfo={sourceColumns[0]}
            initData={data.initData.transformation}
            isLoading={loading}
          />
        )}
      </FormItem>
      {!!nodeErrors.length && (
        <FormItem wrapperCol={{ span: 24 }}>
          <Alert message={<Space direction="vertical" children={nodeErrors} />} type="error" />
        </FormItem>
      )}
    </FormLayout>
  );
};

export const ModelEditorModalsSchemaSettings = ({ open, data, onClose }: ModelEditorModalsSchemaSettingsProps) => {
  const { t } = useTranslation(['model']);

  return (
    <Modal
      css={cssModal}
      open={open}
      onCancel={onClose}
      title={t('schema.title')}
      destroyOnClose
      width={1000}
      footer={null}
    >
      {open && <ModelEditorModalsSchemaSettingsContent t={t} data={data!} onClose={onClose} />}
    </Modal>
  );
};

const cssModal = (): CSSObject => ({
  top: 20,
  maxHeight: `calc(100vh - 40px)`,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  '& .ant-modal-content': {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0,
  },
  '& .ant-modal-body': {
    overflowY: 'auto',
  },
});

interface ModelEditorModalsSchemaSettingsContentProps {
  t: TFunction;
  data: NonNullable<ModelEditorModalsSchemaSettingsProps['data']>;
  onClose: ModelEditorModalsSchemaSettingsProps['onClose'];
}

export interface ModelEditorModalsSchemaSettingsProps {
  open: boolean;
  data?: { nodeId: string; initData: ModelEditorNodeTransformation };
  onClose: () => void;
}
