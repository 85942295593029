import { Modal } from '@ui';
import { ICascadeObject } from '@shared/components/CascadeObjects';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonProps } from 'antd';

const initStage: IConfirmModalStage = {
  isOpen: false,
};

export const useConfirmModal = () => {
  const [confirmState, setConfirmState] = useState<IConfirmModalStage>(initStage);

  const openConfirmation = (data: Omit<IConfirmModalStage, 'isOpen'>) => {
    setConfirmState((prevState) => ({ ...prevState, ...data, isOpen: true }));
  };

  const closeConfirmation = () => {
    setConfirmState(initStage);
  };

  return {
    confirmState,
    openConfirmation,
    closeConfirmation,
  };
};

export const ConfirmModal = ({
  title,
  confirmState,
  okText,
  cancelText,
  children,
  submitFunc,
  closeConfirmation,
  okButtonProps,
}: IConfirmModalProps) => {
  const { t } = useTranslation(['shared']);
  const [loading, setLoading] = useState(false);

  const onOk = async () => {
    setLoading(true);
    await submitFunc(confirmState.data);
    setLoading(false);
    closeConfirmation();
  };

  return (
    <Modal
      title={title}
      open={confirmState.isOpen}
      confirmLoading={loading}
      onOk={onOk}
      okButtonProps={okButtonProps}
      onCancel={closeConfirmation}
      okText={okText || t('confirm')}
      cancelText={cancelText || t('cancel')}
      children={
        <>
          {confirmState.content}
          {children}
        </>
      }
    />
  );
};

interface IConfirmModalProps extends ReturnType<typeof useConfirmModal> {
  title: string;
  okText?: string;
  cancelText?: string;
  children?: ReactNode;
  submitFunc: (data?: any) => void;
  okButtonProps?: ButtonProps;
}

interface IConfirmModalStage {
  isOpen: boolean;
  data?: any;
  content?: ReactNode;
  cascadeObjects?: ICascadeObject;
}
