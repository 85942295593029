import { useSaveSnapshotMutation } from '@modules/snapshot/duck/snapshotApi';
import { PageTemplateSimple } from '@components';
import { Button, Form, FormLayout, FormItem, Input, Space, notification } from '@ui';
import { QueryErrorType } from '@shared/utils/Error';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { SnapshotTableSelect } from '../components';
import { ISnapshotSaveQuery } from '../SnapshotTypes';

export const SnapshotCreatePage = () => {
  const [form] = Form.useForm<ISnapshotSaveQuery>();
  const navigate = useNavigate();
  const [saveSnapshot, saveSnapshotQuery] = useSaveSnapshotMutation();
  const { t } = useTranslation(['snapshot']);

  const onCancel = () => navigate(-1);

  const onSubmit = async (values: ISnapshotSaveQuery) => {
    const tables: string[] = (form.getFieldValue('tables') || [])
      .map((table: string) => table.replace(/.+:.+\./, ''))
      .filter((v: string) => v);

    if (!tables.length) {
      notification.warning({ message: 'At least one table should be selected' });
      return;
    }

    try {
      notification.info({
        message: t('createPage.infoAboutProcess'),
      });

      await saveSnapshot({ ...values, tables }).unwrap();
      notification.success({
        message: t('createPage.successMessageCreate', { name: values.description }),
      });
      onCancel();
    } catch (e) {
      if ((e as QueryErrorType)?.data?.userMsg) {
        notification.error({ message: (e as QueryErrorType)?.data?.userMsg });
      }
    }
  };

  const onSelectTable = useCallback((values: React.Key[]) => form.setFieldValue('tables', values), [form]);

  return (
    <PageTemplateSimple
      hideTitleSkeleton
      title={{
        children: t('createPage.title'),
        extra: (
          <Space>
            <Button loading={saveSnapshotQuery.isLoading} type="primary" children={t('create')} onClick={form.submit} />
            <Button children={t('back')} onClick={onCancel} />
          </Space>
        ),
      }}
    >
      <FormLayout
        form={form}
        onCancel={onCancel}
        onSubmit={onSubmit}
        okText={t('save')}
        hideCancelBtn
        hideOkBtn
        disabled={saveSnapshotQuery.isLoading}
      >
        <FormItem name="description" label={t('createPage.name')} rules={[{ required: true, max: 255 }]}>
          <Input placeholder={t('createPage.namePlaceholder')} />
        </FormItem>
        <FormItem name="sub_study" label={t('createPage.subStudy')} rules={[{ max: 255 }]}>
          <Input disabled placeholder={t('createPage.subStudyPlaceholder')} />
        </FormItem>
        <FormItem shouldUpdate wrapperCol={{ span: 24 }}>
          {() => (
            <SnapshotTableSelect
              disabled={saveSnapshotQuery.isLoading}
              selectedTables={form.getFieldValue('tables')}
              onSelectTable={onSelectTable}
            />
          )}
        </FormItem>
      </FormLayout>
    </PageTemplateSimple>
  );
};
