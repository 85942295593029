import { selectAuthUser } from '@modules/auth/duck/AuthSelector';
import { Avatar, Descriptions, Dropdown, Modal, notification } from '@components/ui';
import { getDomain } from '@app/duck/utils';
import { QueryErrorType } from '@shared/utils/Error';
import { configuration } from '@config/constants';
import { capitalizeFirstLetter } from '@shared/utils/Form';
import { UserOutlined } from '@ant-design/icons';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSObject, Theme } from '@emotion/react';
import { useSelector } from 'react-redux';

export const AppUserButton = () => {
  const { t } = useTranslation(['shared', 'app', 'appHeader']);

  const [showProfile, setShowProfile] = useState(false);
  const user = useSelector(selectAuthUser);

  const userEnvs = user?.environments || {};
  const userCurrentEnv = user?.env_name || '';
  const envOptions = Object.entries(userEnvs).map(([k, v]) => ({ key: v!.domain, label: k }));

  const onConfirmChangingEnv = async (data: any) => {
    try {
      window.location.replace(getDomain(data));
    } catch (error) {
      notification.error({ message: (error as QueryErrorType)?.data?.userMsg });
    }
  };

  const handleChangeEnv = (value: Record<string, any>) => {
    const selectedEnv = value.domEvent.target.textContent;
    if (selectedEnv !== userCurrentEnv) {
      Modal.confirm({
        title: t('appHeader.env.confirmationTitle'),
        content: (
          <span>
            {t('appHeader.env.confirmationText', { currentEnv: userCurrentEnv })}
            <strong>{selectedEnv}</strong>?
          </span>
        ),
        onOk: () => {
          onConfirmChangingEnv(value.key);
        },
      });
    }
  };

  const userMenu = useMemo(() => {
    const logoutLink = localStorage.getItem('referrer')
      ? `${configuration.logoutURL}?referrer=${localStorage.getItem('referrer')}`
      : configuration.logoutURL;

    return [
      // {
      //   key: 'profile',
      //   label: (
      //     <Typography.Text
      //       onClick={() => {
      //         setShowProfile(true);
      //       }}
      //       children={t('profile')}
      //     />
      //   ),
      // },
      {
        key: 'switchEnvironment',
        label: t('appHeader.env.switchEnvironment'),
        children: envOptions.map((el: any) => ({
          ...el,
          onClick: handleChangeEnv,
          style: {
            color: el.label === userCurrentEnv ? '#1677ff' : 'initial',
            backgroundColor: el.label === userCurrentEnv ? '#e6f4ff' : 'initial',
          },
        })),
      },
      {
        key: 'logout',
        label: <a href={logoutLink} children={t('signOut')} />,
      },
    ];
  }, [t]);

  const username = `${capitalizeFirstLetter(user?.first_name)} ${capitalizeFirstLetter(user?.last_name)}`;

  return (
    <>
      <Dropdown
        placement="bottomRight"
        menu={{ items: userMenu }}
        trigger={['click']}
        overlayStyle={{
          minWidth: '80px',
        }}
        autoAdjustOverflow={false}
      >
        <div css={cssContent}>
          <Avatar css={cssAvatar} size="small" icon={<UserOutlined />} />
          {username}
        </div>
      </Dropdown>
      <Modal open={showProfile} width={600} footer={false} onCancel={() => setShowProfile(false)}>
        <Descriptions title={t('app:userProfile:title')} column={1}>
          {/* <Descriptions.Item label={t('shared:system:username')}>{user.username}</Descriptions.Item>
          <Descriptions.Item label={t('shared:system:email')}>{user.email}</Descriptions.Item>
          <Descriptions.Item label={t('app:userProfile:firstName')}>{user.firstName}</Descriptions.Item>
          <Descriptions.Item label={t('app:userProfile:lastName')}>{user.lastName}</Descriptions.Item>
          <Descriptions.Item label={t('app:userProfile:tenant')}>{user.tenant}</Descriptions.Item> */}
        </Descriptions>
      </Modal>
    </>
  );
};

const cssContent = (theme: Theme): CSSObject => ({
  display: 'flex',
  gap: 8,
  alignItems: 'center',
  cursor: 'pointer',
  height: '1em',
});

const cssAvatar = (theme: Theme) => ({
  backgroundColor: theme.colorPrimary,
});
