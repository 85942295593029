import { ChemicalIcon, DnaIcon } from '@components/icons';
import { getFormattedDnaPlatformUrl } from '@routes/utils';
import routes from '@routes';
import { BookFilled, CameraFilled, DeploymentUnitOutlined, ImportOutlined, SettingOutlined } from '@ant-design/icons';
import React, { CSSProperties, ReactNode } from 'react';

const dnaPlatformUrl = getFormattedDnaPlatformUrl();

export const appMenu: MenuType = [
  {
    path: dnaPlatformUrl,
    key: 'DnA-Platform',
    title: 'DnA Platform',
    icon: <DnaIcon />,
  },
  {
    path: routes.app.root.path,
    key: routes.app.root.id,
    title: 'STUDIES',
    icon: <ChemicalIcon />,
  },
  {
    path: routes.crossStudy.view.resolver(),
    key: routes.crossStudy.root.id,
    title: 'SETUPS',
    icon: <SettingOutlined />,
    style: {
      borderLeft: `4px solid #4b85a8`,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  {
    path: routes.crossStudy.dataViewer.resolver(),
    key: routes.crossStudy.dataViewer.id,
    title: 'Data Viewer',
    icon: <BookFilled />,
    style: {
      borderLeft: '4px solid #4b85a8',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  {
    path: routes.crossStudy.jobs.root.resolver(),
    key: routes.crossStudy.jobs.root.id,
    title: 'Import',
    icon: <ImportOutlined />,
    style: {
      borderLeft: '4px solid #4b85a8',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
];

if (process.env.APP_MODE === 'GL') {
  const { appStudyLibraryMenu } = require('./library/libraryMenu');
  appMenu.splice(2, 0, ...appStudyLibraryMenu());
}

export const studyMenu = (studyId: number): MenuType => [
  {
    path: routes.study.view.resolver({ studyId }),
    key: routes.study.view.id,
    keys: studyInternalMenu(studyId).map((data) => data.key),
    title: 'Study',
    icon: <ChemicalIcon />,
  },
  {
    path: routes.study.models.root.resolver({ studyId }),
    key: routes.study.models.root.id,
    title: 'Data Models',
    icon: <DeploymentUnitOutlined />,
  },
  {
    path: routes.study.dataViewer.resolver({ studyId }),
    key: routes.study.dataViewer.id,
    title: 'Data Viewer',
    icon: <BookFilled />,
  },
  {
    path: routes.study.jobs.root.resolver({ studyId }),
    key: routes.study.jobs.root.id,
    title: 'Import',
    icon: <ImportOutlined />,
  },
  {
    path: routes.study.snapshots.root.resolver({ studyId }),
    key: routes.study.snapshots.root.id,
    title: 'Snapshot',
    icon: <CameraFilled />,
  },
  // {
  //   path: routes.study.models.root.path,
  //   key: '4',
  //   title: 'Export',
  // },
];

export const studyInternalMenu = (studyId: number): MenuType => [
  {
    path: routes.study.view.resolver({ studyId }),
    key: routes.study.view.id,
    title: 'Details',
  },
  // {
  //   path: routes.study.permissions.resolver({ studyId }),
  //   key: routes.study.permissions.id,
  //   title: 'Permissions',
  // },
  // {
  //   path: routes.study.instruments.resolver({ studyId }),
  //   key: routes.study.instruments.id,
  //   title: 'Instruments',
  // },
  // {
  //   path: routes.study.eventDefinitions.resolver({ studyId }),
  //   key: routes.study.eventDefinitions.id,
  //   title: 'Event-Definitions',
  // },
  // {
  //   path: routes.study.nonCrf.resolver({ studyId }),
  //   key: routes.study.nonCrf.id,
  //   title: 'Non-CRF',
  // },
  {
    path: routes.study.sources.root.resolver({ studyId }),
    key: routes.study.sources.root.id,
    title: 'Data Sources',
  },
  {
    path: routes.study.stores.root.resolver({ studyId }),
    key: routes.study.stores.root.id,
    title: 'Data Stores',
  },
  {
    path: routes.study.auditLogs.resolver({ studyId }),
    key: routes.study.auditLogs.id,
    title: 'Audit Log',
  },
];

export const crossStudyInternalMenu = (studyId: number): MenuType => [
  {
    path: routes.crossStudy.view.resolver({ studyId }),
    key: routes.crossStudy.view.id,
    title: 'Details',
  },
  {
    path: routes.crossStudy.sources.root.resolver({ studyId }),
    key: routes.crossStudy.sources.root.id,
    title: 'Data Sources',
  },
  {
    path: routes.crossStudy.stores.root.resolver({ studyId }),
    key: routes.crossStudy.stores.root.id,
    title: 'Data Stores',
  },
  {
    path: routes.crossStudy.auditLogs.resolver({ studyId }),
    key: routes.crossStudy.auditLogs.id,
    title: 'Audit Log',
  },
];

export type MenuType = Array<{
  path: string;
  key: string;
  title: string;
  icon?: ReactNode;
  keys?: string[];
  style?: CSSProperties;
}>;
