import { appApi } from '@config/appApi';
import { Domain, Job, JobHistory } from '@modules/job/JobTypes';
import { JOB_TAG_DESCRIPTION } from '@modules/job/duck/jobConstants';
import { appAxios } from '@config/AppConfig';
import { StoreInvalidations } from '@modules/stores/duck/storeApi';
import { handleQueryError } from '@shared/utils/Error';
import { ViewerInvalidations } from '@modules/viewer/duck/viewerApi';
import { sleep } from '@shared/utils/common';
import { TagDescription } from '@reduxjs/toolkit/query/react';

export const JobApiRoutes = {
  list: `api/jobs`,
  jobDetails: (jobId: number) => `api/jobs/details/${jobId}`,
  jobHistory: (jobId: number) => `api/jobs/history/${jobId}`,
  jobDelete: (tableName: string) => `api/data/${tableName}`,
  process: 'api/process',
  processResult: (id: string) => `api/process/${id}`,
  discover: 'api/discover',
  discoveryResult: (id: string) => `api/discover/${id}`,
};

export const FileApiRoutes = {
  upload: (action: 'start' | 'complete' | 'cancel' | 'part') => `api/upload/${action}`,
};

export const JobInvalidations: {
  LIST: TagDescription<JOB_TAG_DESCRIPTION.LIST>;
  HISTORY: (jobId: number) => TagDescription<JOB_TAG_DESCRIPTION.HISTORY>;
  DOMAIN_LIST: (jobId: number) => TagDescription<JOB_TAG_DESCRIPTION.DOMAIN_LIST>;
  UPLOAD: TagDescription<JOB_TAG_DESCRIPTION.UPLOAD>;
  UPLOAD_RESULT: (id: string) => TagDescription<JOB_TAG_DESCRIPTION.UPLOAD_RESULT>;
} = {
  LIST: { type: JOB_TAG_DESCRIPTION.LIST, id: 'LIST' },
  HISTORY: (jobId: number) => ({ type: JOB_TAG_DESCRIPTION.HISTORY, jobId }),
  DOMAIN_LIST: (jobId: number) => ({ type: JOB_TAG_DESCRIPTION.DOMAIN_LIST, jobId }),
  UPLOAD: { type: JOB_TAG_DESCRIPTION.UPLOAD, id: 'UPLOAD' },
  UPLOAD_RESULT: (id: string) => ({ type: JOB_TAG_DESCRIPTION.UPLOAD_RESULT, id }),
};

export const JobApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    jobList: builder.query<JobListResponse['items'], JobListQueryParams | void>({
      providesTags: [JobInvalidations.LIST],
      query: (params) => ({ params, url: JobApiRoutes.list }),
    }),
    jobListPaginated: builder.query<JobListResponse, JobListQueryParams | void>({
      providesTags: [JobInvalidations.LIST],
      query: (params) => ({ params, url: JobApiRoutes.list }),
    }),
    jobDomains: builder.query<JobDomainsResponse, number>({
      providesTags: (result, error, jobId) => [JobInvalidations.DOMAIN_LIST(jobId)],
      query: (jobId) => ({ url: JobApiRoutes.jobDetails(jobId) }),
    }),
    jobHistory: builder.query<JobHistory[], number>({
      providesTags: (result, error, jobId) => [JobInvalidations.HISTORY(jobId)],
      query: (jobId) => ({ url: JobApiRoutes.jobHistory(jobId) }),
    }),
    discoveryJob: builder.mutation<DiscoveryJobResponse[], DiscoveryJobParams>({
      queryFn: async ({ callback, ...params }, api) => {
        await sleep(1000); // allow multiupload to combine file parts
        return appAxios
          .post(JobApiRoutes.discover, params, { signal: api.signal })
          .then(async ({ data }: ProcessJobResponse) => {
            while (true) {
              const response: ProcessResultResponse = await appAxios.get(JobApiRoutes.discoveryResult(data.id), {
                signal: api.signal,
              });
              if (response.data.result) {
                const result = JSON.parse(response.data.result);
                if (result.error) {
                  console.error(result.error);
                  return Promise.reject(new Error(result.error));
                }
                callback(100);
                await sleep(500);
                return { data: result };
              }
              callback();
              await sleep(5000);
            }
          })
          .catch((error) => {
            return { error: handleQueryError(error) };
          });
      },
    }),
    processJob: builder.mutation<string, ProcessJobParams>({
      invalidatesTags: [
        JobInvalidations.LIST,
        StoreInvalidations.LIST,
        ViewerInvalidations.TABLES_EXIST,
        ViewerInvalidations.TABLES_IN_SQL_EXIST,
      ],
      queryFn: async ({ callback, ...params }, api) => {
        return appAxios
          .post(JobApiRoutes.process, params, { signal: api.signal })
          .then(async ({ data }: ProcessJobResponse) => {
            while (true) {
              const response: ProcessResultResponse = await appAxios.get(JobApiRoutes.processResult(data.id), {
                signal: api.signal,
              });
              if (response.data.result) {
                const result = JSON.parse(response.data.result);
                if (result.job_id) {
                  callback(100);
                  await sleep(500);
                  return { data: result.job_id };
                }
                if (result.error) {
                  console.error(result.error);
                  return Promise.reject(new Error(result.error));
                }
              }
              callback();
              await sleep(5000);
            }
          })
          .catch((error) => {
            return { error: handleQueryError(error) };
          });
      },
    }),
    deleteJob: builder.mutation<void, DeleteJobParams>({
      invalidatesTags: (result, error, { jobId }) => [
        JobInvalidations.LIST,
        StoreInvalidations.LIST,
        JobInvalidations.DOMAIN_LIST(jobId),
        ViewerInvalidations.TABLES_EXIST,
        ViewerInvalidations.TABLES_IN_SQL_EXIST,
      ],
      query: ({ storeId, tableName, database }) => ({
        params: {
          store_id: storeId,
          ch_db: database,
        },
        method: 'DELETE',
        url: JobApiRoutes.jobDelete(tableName),
      }),
    }),
    uploadFile: builder.mutation<UploadFileResponse, UploadFileParams>({
      invalidatesTags: (request, error, data) => [ViewerInvalidations.ALL_TABLE_INFO],
      queryFn: async ({ file, callback }, api) => {
        let uploadedFilename = file.name;
        callback(0, 100);
        return appAxios
          .post(FileApiRoutes.upload('start'), { filename: uploadedFilename }, { signal: api.signal })
          .then(async ({ data }) => {
            uploadedFilename = data.filename;

            // Chunks must be minimum 5Mb
            const chunkSize = 1024 * 1024 * 5;
            const parts = Math.ceil(file.size / chunkSize);

            for (let start = 0, partNum = 1; start < file.size; start += chunkSize, partNum++) {
              const chunk = file.slice(start, start + chunkSize);

              const formData: FormData = new FormData();
              formData.append('file', chunk);
              formData.append('part', partNum.toString());

              await sleep(1000); // allow backend to update metainfo in the session
              const success = await appAxios
                .post(FileApiRoutes.upload('part'), formData, {
                  headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' },
                  signal: api.signal,
                  timeout: 0,
                })
                .then(() => true)
                .catch(() => false);

              callback(partNum, parts);

              if (!success) {
                return Promise.reject(new Error('Upload of the file failed'));
              }
            }
          })
          .then(() => sleep(1000)) // allow backend to update metainfo in the session
          .then(() => appAxios.post(FileApiRoutes.upload('complete'), null, { signal: api.signal }))
          .then(() => ({ data: { success: true, filename: uploadedFilename } }))
          .catch(async (error) => {
            await appAxios.post(FileApiRoutes.upload('cancel')).catch(() => {});
            return { error: handleQueryError(error) };
          });
      },
    }),
  }),
});

export const {
  useJobListQuery,
  useJobListPaginatedQuery,
  useJobDomainsQuery,
  useJobHistoryQuery,
  useProcessJobMutation,
  useDiscoveryJobMutation,
  useUploadFileMutation,
  useDeleteJobMutation,
} = JobApi;

export interface JobListResponse {
  currentPage: number;
  totalItems: number;
  totalPages: number;
  items: Array<Job>;
}

export type JobDomainsResponse = Array<Domain>;

interface JobListQueryParams {
  page?: number;
  order?: string;
  sort_by?: string;
}

export type UploadFileParams = {
  file: File;
  callback: (part: number, parts: number) => void;
};

export type UploadFileResponse = {
  success: boolean;
  filename?: string;
};

export type DiscoveryJobResponse = {
  name: string;
  total_columns: number;
  total_rows: number;
  structure: Record<string, string>;
};

export type DiscoveryJobParams = {
  store_id: number;
  separator?: string;
  filename: string;
  skip_blank: boolean;
  skip_rows: number;
  ignore_errors: boolean;
  callback: (value?: number) => void;
};

type ProcessJobMapping = {
  sourceColumn?: string;
  targetColumn: string;
  type: string;
  length?: number;
  nullable: boolean;
  primaryKey: boolean;
};

type ProcessJobData = {
  tableName: string;
  referenceTable?: string;
  total_columns: number;
  total_rows: number;
  mapping: ProcessJobMapping[];
};

export type ProcessJobParams = {
  store_id: number;
  filename: string;
  ignore_errors: boolean;
  data: ProcessJobData[];
  callback: (value?: number) => void;
};

export interface DeleteJobParams {
  tableName: string;
  storeId: number;
  jobId: number;
  database: string;
}

export interface ProcessJobResponse {
  data: {
    id: string;
  };
}

interface ProcessResultResponse {
  data: {
    result: string;
    finished: boolean;
  };
}
