import {
  CustomSqlStage,
  DomainNode,
  GroupByStage,
  JoinStage,
  PivotStage,
  ResultStage,
  TransformStage,
  UnionStage,
  UnPivotStage,
} from '@modules/modelEditor/components/builder/stages';
import { ControlsDataStage } from '@modules/modelEditor/components/builder/ControlsDataStage';
import {
  DEFAULT_STAGE_HEIGHT,
  DEFAULT_STAGE_SCALE_X,
  DEFAULT_STAGE_SCALE_Y,
  DEFAULT_STAGE_WIDTH,
  INITIAL_ZOOM,
} from '@modules/modelEditor/components/builder/constants';
import { ModelEditor, ModelEditorEdgeType, ModelEditorNodeType } from '@modules/modelEditor/ModelEditorTypes';
import { Toolbar } from '@modules/modelEditor/components/builder/toolbar';
import { StageConfig } from '@modules/modelEditor/components/builder/StageConfig';
import { ButtonEdge } from '@modules/modelEditor/components/builder/components';
import {
  selectConfirmationOpened,
  selectModelEditorEdges,
  selectModelEditorNodes,
} from '@modules/modelEditor/duck/modelEditorSelectors';
import { modelEditorActions } from '@modules/modelEditor/duck/modelEditorSlice';
import { generateNodeId, getFullTableName } from '@modules/modelEditor/duck/modelEditorUtils';
import { selectStudyFallbackCHDB } from '@modules/study/duck/studySelectors';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import dagre from '@dagrejs/dagre';
import ReactFlow, {
  Background,
  BackgroundVariant,
  Connection,
  EdgeChange,
  Node,
  NodeChange,
  Edge,
  Position,
  ReactFlowInstance,
  useReactFlow,
} from 'reactflow';
import { CSSObject, Theme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { parseModelEditorData } from './Utils';
import 'reactflow/dist/style.css';

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));
dagreGraph.setDefaultNodeLabel(() => ({}));

const getLayoutedNodes = (nodes: Node[], edges: Edge[]) => {
  const previousNodes = dagreGraph.nodes();
  previousNodes.forEach((el) => dagreGraph.removeNode(el));

  dagreGraph.setGraph({ rankdir: 'LR' });

  nodes.forEach((node: Node) => {
    dagreGraph.setNode(node.id, { width: DEFAULT_STAGE_WIDTH, height: DEFAULT_STAGE_HEIGHT });
  });

  edges.forEach((edge: Edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  return nodes.map((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    return {
      ...node,
      targetPosition: Position.Left,
      sourcePosition: Position.Right,
      position: { x: nodeWithPosition.x - DEFAULT_STAGE_WIDTH / 2, y: nodeWithPosition.y - DEFAULT_STAGE_HEIGHT / 2 },
    };
  });
};

export const ModelEditorSandbox = ({ initData, modelName }: ModelEditorProps) => {
  const fallbackCHDB = useSelector(selectStudyFallbackCHDB);
  const { t } = useTranslation('model');
  const dispatch = useDispatch();
  const { fitView } = useReactFlow();
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance | null>(null);
  const reactFlowWrapper = useRef<HTMLDivElement | null>(null);
  const nodes = useSelector(selectModelEditorNodes);
  const edges = useSelector(selectModelEditorEdges);
  const isConfirmationOpened = useSelector(selectConfirmationOpened);
  const [interactive, setInteractive] = useState(true);

  useEffect(() => {
    if (initData) {
      dispatch(modelEditorActions.init({ ...parseModelEditorData(initData), modelName }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, initData, modelName]);

  const actions = useMemo(
    () => ({
      onNodesChange: (nodes: NodeChange[]) => dispatch(modelEditorActions.onNodesChange(nodes)),
      onEdgesChange: (edges: EdgeChange[]) => dispatch(modelEditorActions.onEdgesChange(edges)),
      addNodes: (node: Node | Node[]) => dispatch(modelEditorActions.addNodes(node)),
      setNodes: (nodes: Node[]) => dispatch(modelEditorActions.setNodes(nodes)),
      onConnect: (connection: Connection) => dispatch(modelEditorActions.onConnect(connection)),
      onDeleteNode: (nodeId: string) => dispatch(modelEditorActions.onDeleteNode(nodeId)),
      onDeleteNodes: () => dispatch(modelEditorActions.onDeleteNodes()),
      onHoverEdge: (edge: Edge | null) => dispatch(modelEditorActions.onHoverEdge(edge)),
    }),
    [dispatch],
  );

  const nodeTypes = useMemo(
    () => ({
      [ModelEditorNodeType.join]: JoinStage,
      [ModelEditorNodeType.transform]: TransformStage,
      [ModelEditorNodeType.union]: UnionStage,
      [ModelEditorNodeType.pivot]: PivotStage,
      [ModelEditorNodeType.unpivot]: UnPivotStage,
      [ModelEditorNodeType.groupBy]: GroupByStage,
      [ModelEditorNodeType.sql]: CustomSqlStage,
      [ModelEditorNodeType.domain]: DomainNode,
      [ModelEditorNodeType.result]: ResultStage,
    }),
    [],
  );

  const edgeTypes = useMemo(
    () => ({
      [ModelEditorEdgeType.button]: ButtonEdge,
    }),
    [],
  );

  const onDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      if (reactFlowWrapper.current === null) {
        return;
      }
      const type = event.dataTransfer.getData('application/reactflow');
      const name = event.dataTransfer.getData('application/reactflow/name');
      const tableName = event.dataTransfer.getData('application/reactflow/tableName');
      const { x, y } = JSON.parse(event.dataTransfer.getData('application/reactflow/mouse_click_coordinates'));

      const stageConfig = StageConfig[type];
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();

      // check if the dropped element is valid
      if (typeof type === 'undefined' || !type) {
        return;
      }

      const { center, width, height, scaleX: scaleXConfig, scaleY: scaleYConfig } = stageConfig;

      if (reactFlowInstance !== null) {
        const zoom = reactFlowInstance.getZoom();

        const posX = center ? ((width || DEFAULT_STAGE_WIDTH) * zoom) / 2 : x * zoom;
        const posY = center ? ((height || DEFAULT_STAGE_HEIGHT) * zoom) / 2 : y * zoom;
        const scaleX = scaleXConfig || DEFAULT_STAGE_SCALE_X;
        const scaleY = scaleYConfig || DEFAULT_STAGE_SCALE_Y;

        const position = reactFlowInstance.project({
          x: event.clientX - reactFlowBounds.left - posX * scaleX,
          y: event.clientY - reactFlowBounds.top - posY * scaleY,
        });

        const newNode = {
          id: generateNodeId(type as ModelEditorNodeType),
          type,
          position,
          data: {
            tableName: type === ModelEditorNodeType.domain ? getFullTableName(tableName, fallbackCHDB) : undefined,
            isSelectable: true,
            toolbarPosition: Position.Top,
            name: name || tableName || t(`${type}.stageLabel`),
            ...stageConfig,
          },
        };

        actions.addNodes(newNode);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reactFlowInstance, actions],
  );

  const onLayout = useCallback(() => {
    actions.setNodes(getLayoutedNodes(nodes, edges));
    window.requestAnimationFrame(() => fitView({ duration: 300 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodes, edges]);

  const onEdgeMouseEnter = useCallback((event: React.MouseEvent, edge: Edge) => actions.onHoverEdge(edge), [actions]);

  const onEdgeMouseLeave = useCallback(() => actions.onHoverEdge(null), [actions]);

  return (
    <div css={cssContainer}>
      <Toolbar />
      <div css={cssLayout} ref={reactFlowWrapper}>
        <ReactFlow
          css={cssFlow}
          nodes={nodes}
          edges={edges}
          onNodesChange={actions.onNodesChange}
          onEdgesChange={actions.onEdgesChange}
          onConnect={actions.onConnect}
          onInit={setReactFlowInstance}
          onDrop={onDrop}
          onDragOver={onDragOver}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          defaultViewport={{ x: 0, y: 0, zoom: INITIAL_ZOOM }}
          nodesConnectable={interactive && !isConfirmationOpened}
          nodesDraggable={interactive && !isConfirmationOpened}
          elementsSelectable={interactive}
          panOnDrag={!isConfirmationOpened}
          zoomOnScroll={!isConfirmationOpened}
          deleteKeyCode={null}
          onEdgeMouseEnter={onEdgeMouseEnter}
          onEdgeMouseLeave={onEdgeMouseLeave}
          fitView
        >
          <ControlsDataStage
            interactive={interactive}
            setInteractive={setInteractive}
            onLayout={onLayout}
            actions={actions}
          />
          <Background gap={12} size={1} variant={BackgroundVariant.Lines} />
        </ReactFlow>
      </div>
    </div>
  );
};

interface ModelEditorProps {
  initData?: ModelEditor;
  modelName: string;
}

const cssContainer = (): CSSObject => ({ flex: 1, minHeight: 0, display: 'flex', flexDirection: 'column' });

const cssLayout = (theme: Theme): CSSObject => ({
  margin: '8px 0',
  width: '100%',
  height: '90%',
  minHeight: '500px',
  border: `1px solid ${theme.colorBorder}`,
  '&& .react-flow__node:focus-visible': {
    outline: 'none',
  },
  display: 'flex',
});

const cssFlow = (): CSSObject => ({
  height: 'auto',
  '& .react-flow__panel.react-flow__attribution': {
    display: 'none',
  },
});
