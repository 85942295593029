const CLICKHOUSE_DB = 'data_stage';

export const getTableNameWithSchema = (tableId: string | null) => {
  // tableId can be like as Clinical:CH_001, default.rules, 123:database.table
  if (tableId) {
    const id = removeItemId(tableId);
    const [schema, tableName] = id.split('.');
    return {
      name: tableName || schema,
      schema: tableName && schema ? schema : CLICKHOUSE_DB,
    };
  }
  return { name: '', schema: '' };
};

export const isContainSchema = (tableId: string) => tableId.split('.').length > 1;

export const removeItemId = (tableId: string) => tableId.split(':').at(-1) ?? tableId;

export const applySchemaToTable = (tableName: string, ch_db: string) => `${ch_db}.${tableName}`;
