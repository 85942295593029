import { Badge, Popconfirm, Tooltip } from '@ui';
import { selectModelEditorErrors } from '@modules/modelEditor/duck/modelEditorSelectors';
import {
  Connection,
  Controls,
  EdgeChange,
  Node,
  NodeChange,
  useReactFlow,
  ControlButton,
  useStore,
  useStoreApi,
  ReactFlowState,
  Edge,
} from 'reactflow';
import {
  DeleteOutlined,
  ExpandOutlined,
  LockFilled,
  MinusOutlined,
  PartitionOutlined,
  PlusOutlined,
  UnlockFilled,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { CSSObject } from '@emotion/react';
import { useSelector } from 'react-redux';

export const ControlsDataStage = ({ interactive, setInteractive, onLayout, actions }: ControlsDataStageProps) => {
  const { t } = useTranslation('model');
  const { fitView, zoomIn, zoomOut } = useReactFlow();
  const store = useStoreApi();

  const currentZoom = useStore((s: ReactFlowState) => s.transform[2]);
  const { minZoom, maxZoom } = store.getState();
  const errors = useSelector(selectModelEditorErrors)
    .filter((error) => !error.node?.id)
    .map((error) => t(error.message));
  const uniqueErrors = Array.from(new Set(errors));

  return (
    <Controls position="top-right" showZoom={false} showFitView={false} showInteractive={false}>
      <ControlButton
        css={cssControlBtn}
        onClick={() => zoomIn()}
        title={t('builder.controls.zoomIn')}
        disabled={maxZoom === currentZoom}
      >
        <PlusOutlined />
      </ControlButton>
      <ControlButton
        css={cssControlBtn}
        onClick={() => zoomOut()}
        title={t('builder.controls.zoomOut')}
        disabled={minZoom === currentZoom}
      >
        <MinusOutlined />
      </ControlButton>
      <ControlButton css={cssControlBtn} onClick={() => fitView()} title={t('builder.controls.fitView')}>
        <ExpandOutlined />
      </ControlButton>
      <ControlButton
        css={cssControlBtn}
        onClick={() => setInteractive((prev) => !prev)}
        title={t('builder.controls.interactivity')}
      >
        {interactive ? <UnlockFilled /> : <LockFilled />}
      </ControlButton>
      <ControlButton css={cssControlBtn} onClick={() => onLayout()} title={t('builder.controls.autolayout')}>
        <PartitionOutlined />
      </ControlButton>
      <Popconfirm
        title={t('builder.confirmation.deleteAll.title')}
        description={t('builder.confirmation.deleteAll.description')}
        okText={t('yes')}
        cancelText={t('no')}
        onConfirm={() => actions.onDeleteNodes()}
      >
        <ControlButton css={cssControlBtn} title={t('builder.controls.deleteAll')}>
          <DeleteOutlined />
        </ControlButton>
      </Popconfirm>
      {errors.length > 0 && (
        <ControlButton css={cssControlBtn}>
          <Tooltip
            color="red"
            placement="left"
            title={
              <ul>
                {uniqueErrors.map((error) => (
                  <li key={error}>{error}</li>
                ))}
              </ul>
            }
          >
            <Badge count={uniqueErrors.length} size="small" title="" />
          </Tooltip>
        </ControlButton>
      )}
    </Controls>
  );
};

interface Actions {
  onNodesChange: (nodes: NodeChange[]) => void;
  onEdgesChange: (edges: EdgeChange[]) => void;
  addNodes: (node: Node | Node[]) => void;
  setNodes: (nodes: Node[]) => void;
  onConnect: (connection: Connection) => void;
  onDeleteNode: (nodeId: string) => void;
  onDeleteNodes: () => void;
  onHoverEdge: (edge: Edge | null) => void;
}

interface ControlsDataStageProps {
  interactive: boolean;
  setInteractive: (prevState: boolean | ((prev: boolean) => boolean)) => void;
  onLayout: () => void;
  actions: Actions;
}

const cssControlBtn = (): CSSObject => ({
  '& > span': {
    width: '100%',
    height: '100%',
  },
  '& > span > svg': {
    minWidth: '100%',
    minHeight: '100%',
  },
});
