import { SNAPSHOT_TAG_DESCRIPTION } from '@modules/snapshot/duck/snapshotConstants';
import { appApi } from '@config/appApi';
import { ISnapshot, ISnapshotSaveQuery } from '@modules/snapshot/SnapshotTypes';
import { TagDescription } from '@reduxjs/toolkit/query/react';

export const SnapshotApiRoutes = {
  list: '/api/snapshot',
  v2List: '/api/v2/snapshot',
  sourceTable: '/api/snapshot/source-tables',
};

export const SnapshotInvalidations: {
  LIST: TagDescription<SNAPSHOT_TAG_DESCRIPTION.LIST>;
  SOURCE_TABLE: TagDescription<SNAPSHOT_TAG_DESCRIPTION.SOURCE_TABLE>;
  ID: (id: number) => TagDescription<SNAPSHOT_TAG_DESCRIPTION.ID>;
} = {
  LIST: { type: SNAPSHOT_TAG_DESCRIPTION.LIST, id: 'LIST' },
  SOURCE_TABLE: { type: SNAPSHOT_TAG_DESCRIPTION.SOURCE_TABLE, id: 'SOURCE_TABLE' },
  ID: (id: number) => ({ type: SNAPSHOT_TAG_DESCRIPTION.ID, id }),
};

export const SnapshotApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    snapshotList: builder.query<ISnapshot[], void>({
      providesTags: [SnapshotInvalidations.LIST],
      query: () => ({
        url: SnapshotApiRoutes.v2List,
      }),
    }),
    saveSnapshot: builder.mutation<void, ISnapshotSaveQuery>({
      invalidatesTags: [SnapshotInvalidations.LIST],
      query: (data) => ({
        data,
        method: 'POST',
        url: SnapshotApiRoutes.v2List,
        timeout: 0,
      }),
    }),
    snapshotSourceTableList: builder.query<Record<string, string[]>, void>({
      providesTags: [SnapshotInvalidations.SOURCE_TABLE],
      query: () => ({
        url: SnapshotApiRoutes.sourceTable,
      }),
    }),
  }),
});

export const { useSnapshotListQuery, useSaveSnapshotMutation, useSnapshotSourceTableListQuery } = SnapshotApi;
