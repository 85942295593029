import { selectModelEditorSchemaEditor } from '@modules/modelEditor/duck/modelEditorSelectors';
import { modelEditorActions } from '@modules/modelEditor/duck/modelEditorSlice';
import { ModelEditorSchemaEditorItem } from '@modules/modelEditor/ModelEditorTypes';
import { ViewerCHTableInfoData } from '@modules/viewer/ViewerTypes';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { FocusEvent, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { parseInputName } from './ModelEditorSchemaEditorUtils';

export const useModelEditorSchemaEditor = (
  tableInfo?: ViewerCHTableInfoData[],
  initData?: ModelEditorSchemaEditorItem[],
) => {
  const dispatch = useDispatch();
  const schemaEditorData = useSelector(selectModelEditorSchemaEditor);

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      dispatch(modelEditorActions.moveSchemaEditorRow({ dragIndex, hoverIndex }));
    },
    [dispatch],
  );

  useEffect(() => {
    if (!initData) {
      dispatch(modelEditorActions.initSchemaEditor((tableInfo || []).map((item, index) => ({ ...item, index }))));
    } else {
      const appliedInitData = (
        tableInfo?.map((item) => {
          const initItem = initData.find((initItem) => initItem.name === item.name);

          if (initItem) {
            return initItem;
          }
          return item;
        }) || []
      ).concat(initData.filter((item) => item.custom));

      dispatch(modelEditorActions.initSchemaEditor(appliedInitData.map((item, index) => ({ ...item, index }))));
    }

    return () => {
      dispatch(modelEditorActions.destroySchemaEditor());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableInfo]);

  const actions = useMemo(() => {
    return {
      onChangeItemWithCheckbox: (e: CheckboxChangeEvent) => {
        const { index, name } = parseInputName(e.target.name);
        if (index !== undefined && name !== undefined) {
          dispatch(modelEditorActions.changeSchemaEditorItem({ index, field: name, value: e.target.checked }));
        }
      },
      onChangeItemWithInputText: (e?: FocusEvent<HTMLInputElement, Element>) => {
        const { index, name } = parseInputName(e?.target?.name);
        if (index !== undefined && name !== undefined) {
          dispatch(modelEditorActions.changeSchemaEditorItem({ index, field: name, value: e?.target.value.trim() }));
        }
      },
      onChangeItemWithExpression: (fieldName: string, value?: string) => {
        const { index, name } = parseInputName(fieldName);
        if (index !== undefined && name !== undefined) {
          dispatch(modelEditorActions.changeSchemaEditorItem({ index, field: name, value }));
        }
      },
      onChangeItemWithSelect: (fieldName: string, value: any) => {
        const { index, name } = parseInputName(fieldName);
        if (index !== undefined && name !== undefined) {
          dispatch(modelEditorActions.changeSchemaEditorItem({ index, field: name, value }));
        }
      },
      onChangeDeletedAllCheckbox: (e: CheckboxChangeEvent) => {
        dispatch(modelEditorActions.switchSchemaEditorDeletedAll(e.target.checked));
      },
      onAddNewItem: () => {
        dispatch(modelEditorActions.insertSchemaEditorRow());
      },
    };
  }, [dispatch]);

  const numberOfDeletedRows =
    schemaEditorData?.reduce((acc, item) => {
      if (item.deleted) {
        acc++;
      }
      return acc;
    }, 0) || 0;
  const deletedAllValue =
    numberOfDeletedRows === 0 ? false : numberOfDeletedRows === schemaEditorData?.length ? true : null;

  const indexes = schemaEditorData?.reduce((acc, item) => ({ ...acc, [item.name]: item.index }), {}) || {};

  return {
    schemaEditorData,
    actions,
    deletedAllValue,
    indexes,
    moveRow,
  };
};
