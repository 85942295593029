import { Button, Card, Checkbox, Form, FormItem, FormLayout, Input, InputNumber, Select, Space, Upload } from '@ui';
import { CSVFile } from '@components/icons';
import {
  JobModalsUploadJobContentProps,
  JobUploadFormValues,
  ProcessParamsProps,
  Progress,
  TRequest,
} from '@modules/job/modals/components';
import { useDiscoveryJobMutation, useProcessJobMutation, useUploadFileMutation } from '@modules/job/duck/jobApi';
import { useManualUploadStores } from '@modules/stores/duck/storeHooks';
import { useLazyCrossStudyRTListQuery, useLazyStudyRTListQuery } from '@modules/viewer/duck/viewerApi';
import { SYSTEM_STORE_NAMES } from '@modules/stores/duck/storeConstants';
import { isCrossStudy } from '@shared/utils/common';
import { Col, notification, Row, UploadProps } from 'antd';
import { UploadFile } from 'antd/lib/upload';
import { useEffect, useState } from 'react';
import { CSSObject } from '@emotion/react';
import { UploadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

// const timeFormatOptions = [
//   { label: 'Basic', value: 'basic' },
//   { label: 'Best effort', value: 'best_effort' },
// ];
// const timeFormatTooltip = (
//   <dl>
//     <dt>Basic</dt>
//     <dd>YYYY-MM-DD HH:MM:SS or YYYY-MM-DD format</dd>
//     <dt>Best efforts</dt>
//     <dd> YYYY-MM-DD HH:MM:SS format and all ISO 8601 date and time formats</dd>
//   </dl>
// );

export const SelectStep = ({
  data,
  onClose,
  onCancel,
  t,
  discoveryJob,
  uploadFile,
  setProgress,
  setCurrent,
  setProcessParams,
  increaseProgress,
  setProgressUpload,
  isSystemStore,
  setIsSystemStore,
  processJob,
  studyId,
  registerRequest,
}: StepOneProps) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const { loading, manualUploadStores } = useManualUploadStores(data?.data_store_id);
  const [lazyStudyRTListQuery] = useLazyStudyRTListQuery();
  const [lazyCrossStudyRTListQuery] = useLazyCrossStudyRTListQuery();

  useEffect(() => {
    if (!loading && !manualUploadStores.length) {
      form.setFields([{ name: 'store_id', errors: [t('shared.errors.emptyManualUploadStoreList')] }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manualUploadStores, loading]);

  const initValues = {
    skipBlankRows: true,
    skipHashOfRows: 0,
    separator: ',',
    // timeFormat: timeFormatOptions[0].value,
  };

  const layout = {
    labelCol: { span: 14 },
    wrapperCol: { span: 10 },
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const updateUploadProgress = (part: number, parts: number) =>
    setProgressUpload((prev: any) => ({ ...prev, progress: Math.floor((100 * part) / parts) }));

  const onSubmitDiscover = async (values: JobUploadFormValues) => {
    const selectedStore = manualUploadStores.find((item) => item.id === values['store_id']);
    if (selectedStore?.system && selectedStore.name === SYSTEM_STORE_NAMES.REFERENCE_TABLE)
      try {
        const response = isCrossStudy(studyId) ? await lazyCrossStudyRTListQuery() : await lazyStudyRTListQuery();

        if (response.data && !response.data.length) {
          notification.warning({
            message: t('uploadRT.errors.warningNoRT'),
          });
          return;
        }
      } catch (e) {
        console.error(e);
      }

    setCurrent(1);
    try {
      const uploadRequest = uploadFile({
        file: values.file[0].originFileObj as File,
        callback: updateUploadProgress,
      });
      registerRequest(uploadRequest);
      const result = await uploadRequest.unwrap();

      setProcessParams({
        filename: result.filename ?? '',
        store_id: values.store_id,
        ignore_errors: values.ignoreErrors,
      });

      const data = {
        store_id: values.store_id,
        separator: values.separator,
        filename: result.filename ?? '',
        skip_blank: values.skipBlankRows,
        skip_rows: values.skipHashOfRows,
        ignore_errors: values.ignoreErrors,
        // time_format: values.timeFormat,
        callback: increaseProgress,
      };
      setProgress({ progress: 0, step: 1 });
      const discoveryRequest = discoveryJob(data);
      registerRequest(discoveryRequest);
      const discoveryResult = await discoveryRequest.unwrap();
      setCurrent(2);
      setProgress(null);
      setProgressUpload(null);
    } catch (e: any) {
      if (e?.name === 'AbortError') {
        console.warn('Request aborted');
      } else {
        console.error(e);
      }
    }
  };

  const handleStoreChange = (val: any) => {
    const findStore = manualUploadStores.find((item) => item.id === val);
    setIsSystemStore && setIsSystemStore(findStore?.system || false);
  };

  return (
    <FormLayout
      {...layout}
      form={form}
      onCancel={onCancel}
      onSubmit={onSubmitDiscover}
      okText={t('upload')}
      initialValues={initValues}
      customActionStyle={{ position: 'absolute', left: '-32px', bottom: '32px' }}
    >
      {/*<Row gutter={24}>*/}
      {/*  <Col span={12}>*/}
      {/*    <FormItem name="skipBlankRows" label={t('uploadModal.skipBlankRows')} valuePropName="checked">*/}
      {/*      <Checkbox />*/}
      {/*    </FormItem>*/}
      {/*  </Col>*/}
      {/*  <Col span={12}>*/}
      {/*    <FormItem name="skipHashOfRows" label={t('uploadModal.skipHashOfRows')}>*/}
      {/*      <InputNumber defaultValue={0} min={0} />*/}
      {/*    </FormItem>*/}
      {/*  </Col>*/}
      {/*</Row>*/}
      <Row gutter={24}>
        <Col span={14}>
          <FormItem name="skipHashOfRows" label={t('uploadModal.skipHashOfRows')}>
            <InputNumber defaultValue={0} min={0} />
          </FormItem>
        </Col>
        <Col span={10}>
          <FormItem name="ignoreErrors" label={t('uploadModal.ignoreErrors')} valuePropName="checked">
            <Checkbox />
          </FormItem>
        </Col>
        {/*<Col span={12}>*/}
        {/*  <FormItem name="importToStaging" label={t('uploadModal.importToStaging')} valuePropName="checked">*/}
        {/*    <Checkbox />*/}
        {/*  </FormItem>*/}
        {/*</Col>*/}
      </Row>
      <Row gutter={24}>
        {/*<Col span={14}>
          <FormItem
            name="timeFormat"
            label={
              <>
                {t('uploadModal.timeFormat')}
                <Tooltip placement="left" title={timeFormatTooltip}>
                  <QuestionCircleOutlined css={cssTooltipIcon} size={32} />
                </Tooltip>
              </>
            }
          >
            <Select options={timeFormatOptions} placeholder={t('select')} />
          </FormItem>
        </Col> */}
        <Col span={14}>
          <FormItem name="separator" label={t('uploadModal.separator')} rules={[{ required: true }]}>
            <Input />
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormItem
            name="store_id"
            label={t('uploadModal.targetDataStore')}
            valuePropName="checked"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            rules={[{ required: true }]}
          >
            <Select
              options={manualUploadStores}
              placeholder="Select"
              loading={loading}
              fieldNames={{ label: 'name', value: 'id' }}
              onChange={handleStoreChange}
            />
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormItem
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            name="file"
            label={t('uploadModal.btnLabel')}
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[{ required: true }]}
          >
            <Upload
              accept=".csv, .xlsx, .txt"
              name="files"
              beforeUpload={() => false}
              listType="text"
              fileList={fileList}
              onChange={onChange}
              maxCount={1}
              itemRender={(_, file) => (
                <Card size="small" key={file.uid}>
                  <Space css={cssFileLayout} block>
                    <CSVFile />
                    <div css={cssFileNameLabel} title={file.name}>
                      {file.name}
                    </div>
                  </Space>
                </Card>
              )}
            >
              <Button icon={<UploadOutlined />} css={cssUploadBtn}>
                {t('uploadModal.btnName')}
              </Button>
            </Upload>
          </FormItem>
        </Col>
      </Row>
    </FormLayout>
  );
};

const cssFileLayout = (): CSSObject => ({
  '&& div:last-child': {
    overflow: 'hidden',
  },
});

const cssFileNameLabel = (): CSSObject => ({
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

const cssUploadBtn = () => ({
  marginBottom: '12px',
});

interface StepOneProps extends Omit<JobModalsUploadJobContentProps, 'cancelled'> {
  discoveryJob: ReturnType<typeof useDiscoveryJobMutation>[0];
  uploadFile: ReturnType<typeof useUploadFileMutation>[0];
  processJob: ReturnType<typeof useProcessJobMutation>[0];
  setProgress: (val: Progress) => void;
  setCurrent: (val: number) => void;
  setProcessParams: (val: ProcessParamsProps) => void;
  increaseProgress: (val?: number | undefined) => void;
  setProgressUpload: (val: Progress) => void;
  isSystemStore?: boolean;
  setIsSystemStore?: (val: boolean) => void;
  studyId: number;
  registerRequest: (request: TRequest) => void;
}
