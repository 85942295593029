import { Button, ProgressCircleWithTitle, Space } from '@ui';
import { CSSObject } from '@emotion/react';
import { TFunction } from 'i18next';

export const UploadingStep = ({
  t,
  progress,
  uploadFileResult,
  discoveryJobResult,
  processJobResult,
  onCancel,
  progressUpload,
}: StepTwoProps) => {
  const isError = uploadFileResult?.isError || discoveryJobResult?.isError || processJobResult?.isError;
  const uploadResultMessage = [uploadFileResult, discoveryJobResult, processJobResult]
    .map(
      (result) =>
        result?.isError && result.error && ('data' in result.error ? result.error.data.userMsg : result.error?.message),
    )
    .filter(Boolean)
    .join(';');

  return (
    <div css={cssContainer}>
      <div css={cssContent}>
        {uploadFileResult && discoveryJobResult && (
          <Space style={{ justifyContent: 'space-evenly' }} full align="center">
            <ProgressCircleWithTitle
              title={t('Upload file')}
              status={uploadFileResult?.isError && 'exception'}
              percent={progressUpload?.progress}
            />
            <ProgressCircleWithTitle
              title={
                progress?.step > 0
                  ? t('uploadModal.discovering', { num: progress?.step })
                  : t('uploadModal.discoveringWithoutChunk')
              }
              percent={progress?.progress}
              status={discoveryJobResult?.isError && 'exception'}
            />
          </Space>
        )}
        {processJobResult && (
          <ProgressCircleWithTitle
            title={
              progress?.step > 0
                ? t('uploadModal.processing', { num: progress?.step })
                : t('uploadModal.processingWithoutChunk')
            }
            status={processJobResult?.isError && 'exception'}
            percent={progress?.progress}
          />
        )}
        {isError && <div css={cssTestMsg}>{uploadResultMessage}</div>}
      </div>

      <Button css={cssActionBtn} onClick={onCancel}>
        Cancel
      </Button>
    </div>
  );
};

interface StepTwoProps {
  t: TFunction;
  progress: Record<string, any> | null;
  uploadFileResult?: Record<string, any>;
  discoveryJobResult?: Record<string, any>;
  processJobResult?: Record<string, any>;
  onCancel: () => void;
  progressUpload?: Record<string, any> | null;
}

const cssTestMsg = (): CSSObject => ({
  color: 'red',
  textAlign: 'center',
  padding: 16,
});

const cssContainer = (): CSSObject => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
});

const cssContent = (): CSSObject => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '0 24px',
});

const cssActionBtn = (): CSSObject => ({
  alignSelf: 'flex-end',
  marginTop: 'auto',
});
