import { Component, JSXElementConstructor, ReactNode } from 'react';
import { ThemeProvider, Global, css } from '@emotion/react';
import { ConfigProvider, theme } from 'antd';

const { useToken } = theme;

const customColors = {
  colorBgLayoutSecondary: '#324c5c',
  colorTextInverse: 'rgba(255, 255, 255, 0.88)',
  'primary-1': '#dae5e8',
  'primary-2': '#ced8db',
  'primary-3': '#c0cacf',
  'primary-4': '#95b4c2',
  'primary-5': '#6e9cb5',
  'primary-6': '#4b85a8',
  'primary-7': '#346282',
  'primary-8': '#20415c',
  'primary-9': '#102336',
  'primary-10': '#040a0f',
};

const withAntdConfigProvider = (EmotionComponent: JSXElementConstructor<{ children: ReactNode }>) => {
  return class extends Component<{ children: ReactNode }> {
    render() {
      return (
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#4b85a8',
              colorLink: '#005595',
              colorLinkHover: '#4b85a8',
            },
          }}
        >
          <EmotionComponent {...this.props} />
          <Global
            styles={css`
              .root {
                overflow: hidden;
              }
            `}
          />
        </ConfigProvider>
      );
    }
  };
};

export const EmotionAntdThemeProvider = withAntdConfigProvider(({ children }: { children: ReactNode }) => {
  const { token } = useToken();

  return <ThemeProvider theme={{ ...token, ...customColors }}>{children}</ThemeProvider>;
});
