import { selectViewerSnapshot } from '@modules/viewer/duck/viewerSelectors';
import { useSnapshotListQuery } from '@modules/snapshot/duck/snapshotApi';
import { viewerActions } from '@modules/viewer/duck/viewerSlice';
import { Button, Input, Select } from '@ui';
import { dateToString } from '@shared/utils/Date';
import { LATEST_DATA_SNAPSHOT } from '@modules/snapshot/duck/snapshotConstants';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const DataViewerSnapshotSelector = () => {
  const { t } = useTranslation(['snapshot']);
  const dispatch = useDispatch();
  const snapshot = useSelector(selectViewerSnapshot);
  const snapshotListQuery = useSnapshotListQuery();

  useEffect(() => {
    if (snapshotListQuery.data?.length && !snapshot) {
      dispatch(viewerActions.setSnapshot({ ...LATEST_DATA_SNAPSHOT, value: '' }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snapshotListQuery.data]);

  const snapshotOptionList = useMemo(
    () =>
      [{ label: LATEST_DATA_SNAPSHOT.description, value: '' } as { label: string; value?: string }].concat(
        ...(snapshotListQuery.data || []).map((el) => ({
          label: `${dateToString(el.createdAt)} (${el.description})`,
          value: el.id,
        })),
      ),
    [snapshotListQuery.data],
  );

  const onChangeSnapshot = (value: string) => {
    const selectedSnapshot = snapshotListQuery.data!.find((el) => el.id === value) || LATEST_DATA_SNAPSHOT;
    dispatch(
      viewerActions.setSnapshot({
        ...selectedSnapshot,
        value: selectedSnapshot.id,
      }),
    );
  };

  if (!snapshotListQuery.data || !snapshotListQuery.data.length) {
    return null;
  }

  return (
    <Input.Group compact style={{ order: 20 }}>
      <Button type="default" disabled style={{ cursor: 'auto' }}>
        {t('snapshotSelectLabel')}
      </Button>
      <Select
        dropdownMatchSelectWidth={false}
        value={snapshot?.value}
        options={snapshotOptionList}
        defaultActiveFirstOption
        onChange={onChangeSnapshot}
      />
    </Input.Group>
  );
};
