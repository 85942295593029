import { RootState } from '@store';

export const selectViewerModals = (state: RootState) => state.viewer.modals;

export const selectViewerGroups = (studyId: string | number) => (state: RootState) =>
  (state.viewer.groups || {})[studyId] || {};

export const selectViewerLeftSideData = (state: RootState) => state.viewer.leftSide;

export const selectExpandedGroups = (studyId: string | number) => (state: RootState) =>
  (state.viewer.expanded || {})[studyId] || [];

export const selectViewerSnapshot = (state: RootState) => state.viewer.snapshot;
