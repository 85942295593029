import { Button, DraggableModal, Spin, Typography } from '@ui';
import { Model, ModelLogsType } from '@modules/model/ModelTypes';
import { useModelLogsQuery } from '@modules/model/duck/modelApi';
import { Loader, PageSkeleton, QueryError } from '@components';
import { QueryErrorType } from '@shared/utils/Error';
import { SerializedError } from '@reduxjs/toolkit';
import { LoadingOutlined, SyncOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { CSSObject } from '@emotion/react';
import { useEffect } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';

const ModelModalsLogContent = ({ model, data, isFetching, isLoading, error, t }: ModelModalsLogContentProps) => {
  return (
    <div>
      {isLoading && <PageSkeleton />}
      {isFetching && !isLoading && data?.finished_at && <Loader mode="absolute" />}
      {error && <QueryError error={error} title={t('logErrorTitle')} />}
      {!isLoading && !error && (
        <div>
          {data?.log ? (
            <>
              <div>
                <strong>Started:</strong>
                <span>{data?.started_at ? new Date(data?.started_at).toLocaleString() : ''}</span>
              </div>
              <div>
                <strong>Finished:</strong>
                <span>{data?.finished_at ? new Date(data?.finished_at).toLocaleString() : ''}</span>
              </div>

              <Scrollbars css={cssLogsLayout} autoHide={false} autoHeight autoHeightMin={500}>
                <pre css={cssLogs}>{data?.log}</pre>
              </Scrollbars>
            </>
          ) : (
            <div css={cssNotFound}>
              <Typography.Title
                type="secondary"
                children={data?.message_id ? t('errors.logsNotStartedYet') : t('errors.logsNotFound')}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export const ModelModalsLog = ({ open, data, onClose }: ModelModalsLogProps) => {
  const { t } = useTranslation(['model']);
  const logQuery = useModelLogsQuery(data.id!);
  const isFetching = logQuery.isFetching || logQuery.isLoading;

  useEffect(() => {
    if (!logQuery?.data?.finished_at && logQuery?.data?.message_id) {
      const id = setTimeout(() => logQuery.refetch(), 10000);
      return () => clearTimeout(id);
    }
  }, [logQuery, logQuery?.data?.finished_at]);

  const footerActions = [
    <Button key="close" onClick={onClose}>
      {t('close')}
    </Button>,
    // <Button
    //   key="download"
    //   disabled={isFetching}
    //   onClick={() => {
    //     console.log('Download');
    //   }}
    // >
    //   {t('download')}
    // </Button>,
    <Button key="refresh" disabled={isFetching} onClick={logQuery.refetch} icon={<SyncOutlined />}>
      {t('refresh')}
    </Button>,
  ];

  return (
    <DraggableModal
      width="80%"
      open={open}
      onCancel={onClose}
      title={
        <div css={cssTitle}>
          {data.name} {t('logTitle')}
          {!logQuery?.data?.finished_at && logQuery?.data?.message_id && (
            <Spin css={cssSpinner} indicator={<LoadingOutlined />} size="large" />
          )}
        </div>
      }
      footer={footerActions}
      destroyOnClose
    >
      {open && (
        <ModelModalsLogContent
          model={data}
          data={logQuery.data}
          isFetching={logQuery.isFetching}
          isLoading={logQuery.isLoading}
          error={logQuery.error}
          t={t}
        />
      )}
    </DraggableModal>
  );
};

export interface ModelModalsLogProps {
  open: boolean;
  data: Partial<Model>;
  onClose: () => void;
}

export interface ModelModalsLogContentProps {
  isFetching: boolean;
  isLoading: boolean;
  error?: QueryErrorType | SerializedError;
  data?: ModelLogsType;
  model?: ModelModalsLogProps['data'];
  t: TFunction;
}

const cssTitle = (): CSSObject => ({
  marginBottom: '1rem',
  lineHeight: '2.5rem',
});

const cssLogsLayout = (): CSSObject => ({
  height: '500px',
  marginTop: '20px',
  // fontSize: '12px',
  // overflow: 'visible',
});

const cssLogs = (): CSSObject => ({
  // height: '500px',
  // marginTop: '20px',
  fontSize: '12px',
  overflow: 'visible',
});

const cssNotFound = (): CSSObject => ({
  height: '500px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const cssSpinner = (): CSSObject => ({
  paddingLeft: '0.25rem',
});
