import { DATASTAGE_PREFIX } from '@routes/utils';

const DOMAIN_PREFIX = `//${DATASTAGE_PREFIX}`;

export const getDomain = (data: string) => {
  if (!data.includes(DOMAIN_PREFIX) && !data.includes('//localhost')) {
    return data.replace('//', DOMAIN_PREFIX);
  }
  return data;
};
