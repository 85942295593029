import { Button, DraggableModal, Select, Space, Tooltip, Typography } from '@ui';
import { ColumnType, ModelEditorNodeSql } from '@modules/modelEditor/ModelEditorTypes';
import { useLazyRunSqlQuery } from '@modules/model/duck/modelApi';
import { useTableInfoQuery, useTablesQuery } from '@modules/viewer/duck/viewerApi';
import { selectViewerGroups } from '@modules/viewer/duck/viewerSelectors';
import {
  isContainForbiddenWords,
  isContainSelect,
  isContainSpecialCharOrSpace,
} from '@modules/modelEditor/modals/utils';
import { useStudyListQuery } from '@modules/study/duck/studyApi';
import { DataTable, DataTableProps, Loader, renderCellTypedValue } from '@components';
import { useSaveStage } from '@modules/modelEditor/modals/components/modelEditorModalsHooks';
import { SqlBuilder } from '@modules/modelEditor/components/sqlBuilder';
import { Tabs } from '@components/ui/tabs';
import { ExpressionVarsList } from '@modules/modelEditor/components/expressionBuilder/ExpressionVarsList';
import { selectGlobalStudy } from '@app/duck/appSelectors';
import { getFlatGroups } from '@modules/viewer/duck/viewerUtils';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { CSSObject } from '@emotion/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import AceEditor from 'react-ace';
import { InfoCircleOutlined } from '@ant-design/icons';
import { RenderCellProps } from 'react-data-grid';
import { Divider } from 'antd';

const PREVIEW_SIZE = 10;

const initData = {
  sqlStatement: '',
  columns: [],
};

const ModelEditorModalsCustomSqlContent = ({ data, onClose, t }: ModelEditorModalsCustomSqlContentProps) => {
  const globalStudy = useSelector(selectGlobalStudy);
  const refEditor = React.createRef<AceEditor>();
  const viewerGroups = useSelector(selectViewerGroups(globalStudy!.id));
  const defaultStudy = { label: globalStudy?.name!, value: globalStudy?.id! };
  const [selectedStudy, setSelectedStudy] = useState(defaultStudy);
  const studiesQuery = useStudyListQuery();
  const tablesQuery = useTablesQuery({ study_id: selectedStudy.value! }, { skip: !selectedStudy.value });
  const [runSql, runSqlQuery] = useLazyRunSqlQuery();
  const { onSubmit } = useSaveStage(data?.nodeId, onClose);

  const [values, setValues] = useState<SqlProps>(initData);
  const [selectedTable, setSelectedTable] = useState<TablesSourceProps['rawData'] | null>(null);
  const [isDisabledSave, setIsDisabledSave] = useState(true);
  const [customErrorMsg, setCustomErrorMsg] = useState('');
  const [latestSuccessfulQuery, setLatestSuccessfulQuery] = useState<string>('');

  const { currentData: previewResult, isFetching, isError, error } = runSqlQuery;
  const [db = '', tableId = ''] = selectedTable?.value.split('.') || '';
  const tableInfo = useTableInfoQuery({ tableName: tableId, fallbackCHDB: db }, { skip: !selectedTable });
  const isSelectedStudyGlobalStudy = selectedStudy.value === globalStudy?.id!;

  const getErrorMsq = (result: any) => {
    if (result.isError && result.error && 'data' in result.error) {
      const { status, data } = result.error;

      if (status === 403) {
        return t('sql.errors.forbiddenError');
      } else if (status === 500) {
        return t('sql.errors.serverError');
      } else {
        return data.userMsg;
      }
    }
    return null;
  };

  const queryTableFieldsErrorMessage = getErrorMsq(tableInfo);
  const queryRunSqlErrorMessage = getErrorMsq(runSqlQuery);

  useEffect(() => {
    if (data?.initData?.sql_statement) {
      setValues((prev) => ({
        ...prev,
        sqlStatement: data?.initData?.sql_statement || '',
        columns: data?.initData?.sql_schema || [],
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.initData?.sql_statement]);

  useEffect(() => {
    refEditor.current?.editor.focus();
  }, [refEditor]);

  const sourceStudies = useMemo(
    () =>
      studiesQuery.data
        ? studiesQuery.data.map((study) => ({
            label: study.name,
            value: study.id,
          }))
        : [],
    [studiesQuery.data],
  );

  const sourceTablesGlobalStudy = useMemo(
    () =>
      getFlatGroups(viewerGroups)
        .flatMap(
          (el) =>
            el.tables?.map(({ id, name }) => ({
              name: name,
              description: el.name,
              rawData: { value: id, id: `${el.name}.${id}` },
            })) || [],
        )
        .filter((el) => el),
    [viewerGroups],
  );

  const sourceTables = useMemo(
    () =>
      tablesQuery.currentData
        ? tablesQuery.currentData
            .flatMap((el) =>
              el.tables?.map((item) => ({
                name: item.split('.')[1],
                description: el.name,
                rawData: { value: item, id: `${el.name}.${item}` },
              })),
            )
            .filter((el) => el)
        : [],
    [tablesQuery.currentData],
  );

  const sourceTableFields = useMemo(
    () =>
      tableInfo.currentData
        ? tableInfo.currentData?.map((item) => ({
            name: item.name,
            description: item.type,
            rawData: { value: item.name },
          }))
        : [],
    [tableInfo.currentData],
  );

  const dataViewerTableSource = useMemo((): DataTableProps<any> => {
    const data = previewResult || { meta: [], data: [] };
    const columns =
      (data.meta &&
        data.meta?.map((item) => ({
          key: item.name,
          name: item.name,
          renderCell: ({ row }: RenderCellProps<any>) => renderCellTypedValue(row[item.name]),
        }))) ||
      [];

    const rows = data.data || [];

    return {
      columns,
      rows,
    };
  }, [previewResult]);

  const onChange = (val: string) => {
    setValues((prev) => ({ ...prev, sqlStatement: val, columns: [] }));
    if (latestSuccessfulQuery === val) {
      setIsDisabledSave(false);
    }
    setIsDisabledSave(true);
    setCustomErrorMsg('');
  };

  const clearStatement = (val: string) => val.replace(/;+$/g, '');

  const onRun = async () => {
    try {
      if (isContainForbiddenWords(values.sqlStatement)) {
        setCustomErrorMsg(t('sql.errors.containForbiddenWordsError'));
        return;
      }
      if (!isContainSelect(values.sqlStatement)) {
        setCustomErrorMsg(t('sql.errors.containSelectError'));
        return;
      }
      const { meta } = await runSql({
        sql_statement: clearStatement(values.sqlStatement),
        size: PREVIEW_SIZE,
      }).unwrap();
      if (meta !== undefined && meta.length > 0) {
        setValues((prev) => ({
          ...prev,
          columns: meta,
          sqlStatement: clearStatement(prev.sqlStatement),
          isValid: true,
        }));
        setIsDisabledSave(false);
      }
      setLatestSuccessfulQuery(clearStatement(values.sqlStatement));
      setCustomErrorMsg('');
    } catch (e) {
      console.log('Error when run preview', e);
      setIsDisabledSave(true);
    }
  };

  const onCancel = () => {
    onClose();
  };

  const onSave = () => {
    const { sqlStatement = '', columns = [], isValid } = values;
    const updatedValues = { sql_statement: sqlStatement.trim(), sql_schema: columns, isValid };
    onSubmit(updatedValues);
  };

  const onClickTableHandler = (data: TablesSourceProps['rawData']) => setSelectedTable(data);

  const onClickStudyHandler = (value: { label: string; value: number }) => {
    setSelectedStudy(value);
    setSelectedTable(null);
  };

  const wrapValue = (value: string) => (isContainSpecialCharOrSpace(value) ? `"${value}"` : value);

  const addDataToEditor = (data: TablesSourceProps['rawData']) => {
    refEditor.current?.editor.insert(wrapValue(data?.value));
  };

  const onDragStart = (data: TablesSourceProps['rawData'], event: React.DragEvent) => {
    event.dataTransfer.setData('application/aceEditor/value', wrapValue(data.value));
  };

  const onDrop = useCallback(
    (event: any) => {
      event.preventDefault();
      const value = event.dataTransfer.getData('application/aceEditor/value');
      if (typeof value === 'undefined' || !value) {
        return;
      }
      const editor = refEditor.current?.editor;
      if (editor) {
        editor.session.insert(editor.getCursorPosition(), value);
      }
    },
    [refEditor],
  );

  const onDragOver = useCallback(
    (event: any) => {
      event.preventDefault();
      const aceEditor = refEditor.current?.editor;
      if (aceEditor) {
        const { row, column } = aceEditor.renderer.pixelToScreenCoordinates(event.clientX, event.clientY);
        const cursorPosition = aceEditor.session.screenToDocumentPosition(row, column);
        aceEditor.moveCursorTo(cursorPosition.row, cursorPosition.column);
      }
    },
    [refEditor],
  );

  const filterOption = (input: string, option?: { label: string; value: number }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const renderLeftPanelWithSourceData = () => (
    <>
      <Select
        labelInValue
        style={{ width: '100%' }}
        options={sourceStudies}
        loading={studiesQuery.isLoading}
        onChange={onClickStudyHandler}
        value={selectedStudy}
        filterOption={filterOption}
        showSearch
      />
      <Divider css={cssDivider} />
      <ExpressionVarsList
        loading={!isSelectedStudyGlobalStudy && tablesQuery.isFetching}
        dataSource={!isSelectedStudyGlobalStudy ? sourceTables : sourceTablesGlobalStudy}
        onClick={onClickTableHandler}
        onDoubleClick={(data) => {
          setSelectedTable(data);
          addDataToEditor(data);
        }}
        searchPlaceholder={t('sql.search.tables')}
        t={t}
        selectedItem={selectedTable}
        onDragStart={onDragStart}
        spaceSize="small"
      />
      <Divider css={cssDivider} />
      <ExpressionVarsList
        dataSource={sourceTableFields}
        onDoubleClick={addDataToEditor}
        searchPlaceholder={t('sql.search.fields')}
        t={t}
        loading={tableInfo.isFetching}
        onDragStart={onDragStart}
        spaceSize="small"
      />
    </>
  );

  const renderPreview = () => (
    <Tabs
      css={cssTabs}
      defaultActiveKey="1"
      size="small"
      items={[
        {
          label: t('sql.result'),
          key: 'preview',
          children: (
            <>
              {isFetching && <Loader mode="absolute" />}
              {isError && <Typography.Text type="danger">{queryRunSqlErrorMessage}</Typography.Text>}
              {!previewResult && !isError && !isFetching && (
                <div css={cssEmptyBox}>
                  <Typography.Title type="secondary" level={4} children="Run a query to display results" />
                </div>
              )}
              {previewResult && (
                <>
                  <DataTable {...dataViewerTableSource} rowHeight={25} />
                  <Typography.Text type="secondary">
                    {t('sql.noticePreview', {
                      rowsCount: previewResult.data.length,
                      limit: PREVIEW_SIZE,
                    })}
                  </Typography.Text>
                </>
              )}
            </>
          ),
        },
      ]}
    />
  );

  const renderFooter = () => (
    <>
      <Button children={t('cancel')} onClick={onCancel} />
      {isDisabledSave ? (
        <Tooltip title={t('sql.tooltipSaveBtn')}>
          <Button type="primary" children={t('save')} onClick={onSave} disabled={isDisabledSave} />
        </Tooltip>
      ) : (
        <Button type="primary" children={t('save')} onClick={onSave} disabled={isDisabledSave} />
      )}
    </>
  );

  return (
    <>
      <div css={cssContainerBody}>
        <div css={cssContainerTables}>
          {renderLeftPanelWithSourceData()}
          {tableInfo.isError && (
            <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              <Typography.Text type="danger" title={queryTableFieldsErrorMessage}>
                {queryTableFieldsErrorMessage}
              </Typography.Text>
            </div>
          )}
        </div>
        <div css={cssContainerSql}>
          <SqlBuilder
            ref={refEditor}
            defaultValue={data?.initData?.sql_statement ?? ''}
            addDataToEditor={addDataToEditor}
            onChange={onChange}
            onDrop={onDrop}
            onDragOver={onDragOver}
            t={t}
          />
          <Space css={cssContainerBtn}>
            <Button type="primary" children={t('sql.run')} onClick={onRun} disabled={!values.sqlStatement} />
            <Typography.Text type="danger">{customErrorMsg}</Typography.Text>
          </Space>
          {renderPreview()}
        </div>
      </div>
      <Space full justify="end">
        {renderFooter()}
      </Space>
    </>
  );
};

export const ModelEditorModalsCustomSqlSettings = ({ open, data, onClose }: ModelEditorModalsCustomSqlProps) => {
  const { t } = useTranslation(['model']);

  const renderHeader = () => (
    <div css={cssTitle}>
      <div>{t('sql.title')}</div>
      <Tooltip placement="rightTop" title={t('sql.tooltipTitle')}>
        <InfoCircleOutlined width={8} height={8} />
      </Tooltip>
    </div>
  );

  return (
    <DraggableModal
      width={'80%'}
      style={{ minWidth: '400px' }}
      open={open}
      onCancel={onClose}
      title={renderHeader()}
      footer={null}
      destroyOnClose
    >
      {open && <ModelEditorModalsCustomSqlContent data={data} onClose={onClose} t={t} />}
    </DraggableModal>
  );
};

const cssContainerBody = (): CSSObject => ({
  display: 'flex',
  gap: '16px',
  marginTop: '16px',
});

const cssTitle = (): CSSObject => ({
  display: 'flex',
  gap: 8,
});

const cssContainerSql = (): CSSObject => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
});

const cssEmptyBox = (): CSSObject => ({
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  position: 'absolute',
  textAlign: 'center',
});

const cssContainerBtn = (): CSSObject => ({
  padding: '8px',
});

const cssTabs = (): CSSObject => ({
  color: 'red',
  flex: 1,
  '&& .ant-tabs-content-holder': {
    justifyContent: 'center',
    display: 'flex',
  },
});

const cssContainerTables = (): CSSObject => ({
  width: '300px',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

const cssDivider = (): CSSObject => ({
  margin: '2px',
});

interface ModelEditorModalsCustomSqlContentProps extends Pick<ModelEditorModalsCustomSqlProps, 'data' | 'onClose'> {
  t: TFunction;
}

interface TablesSourceProps {
  name: string;
  description?: string;
  rawData: { value: string; id?: string };
}

export interface ModelEditorModalsCustomSqlProps {
  open: boolean;
  data: {
    nodeId: string;
    initData?: ModelEditorNodeSql;
  };
  onClose: () => void;
}

interface SqlProps {
  sqlStatement: string;
  columns: ColumnType[];
  isValid?: boolean;
}
