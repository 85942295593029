import {
  ModelEditor,
  ModelEditorEdgeType,
  ModelEditorNodeDomain,
  ModelEditorNodeType,
} from '@modules/modelEditor/ModelEditorTypes';
import { useLazyTablesExistQuery } from '@modules/viewer/duck/viewerApi';
import type { Edge, Node } from 'reactflow';
import { MarkerType } from 'reactflow';
import React from 'react';
import { StageConfig } from './StageConfig';

export const parseModelEditorData = (
  data: ModelEditor,
): { nodes: Node<any, string | undefined>[]; edges: Edge<any>[] } => {
  const nodes: ReturnType<typeof parseModelEditorData>['nodes'] = [];
  const edges: ReturnType<typeof parseModelEditorData>['edges'] = [];

  data.forEach((item) => {
    nodes.push({
      id: item.id,
      type: item.type,
      position: item.position,
      data: {
        ...item.data,
        ...StageConfig[item.type],
      },
    });
    if (Array.isArray(item.children)) {
      item.children.forEach((child) => {
        edges.push({
          id: `${item.id}-${child}`,
          target: item.id,
          source: child,
          markerEnd: { type: MarkerType.ArrowClosed },
          type: ModelEditorEdgeType.button,
        });
      });
    }
  });

  return { nodes, edges };
};

export const findSourceNodesByNodeId = (nodeId: string, edges: Edge[]) => {
  const sourceEdges = edges.filter((edge) => edge.target === nodeId);

  return sourceEdges.map((edge) => edge.source);
};

export const findTableNameById = (nodes: Node[], item: string) =>
  nodes.find((node) => node.id === item)?.data?.tableName;

export const findNodeById = (nodes: Node[], nodeId: string) => nodes.find((node) => node.id === nodeId);

export const getOnMouseClickCoordinates = (event: React.DragEvent) => {
  const targetElement = event.target as HTMLElement;
  const x = event.clientX - targetElement.getBoundingClientRect().left;
  const y = event.clientY - targetElement.getBoundingClientRect().top;
  return { x, y };
};

export const checkSpecialCharacters = (data?: string[]) => data?.some((item) => item?.match(/[^A-Za-z_0-9]/g));
